import { toast } from 'react-toastify';

function editProductValidator(obj, dimensions, deliveryOptions) {

    const showToast = (message, type) => {
        toast.dismiss(); // Clear all existing toasts
        if (type === 'error') {
            toast.error(message);
            return false
        } else if (type === 'success') {
            toast.success(message);
        }
    };


    const validateData = (data) => {
        let isValid = true;
    
        for (let index = 0; index < data?.length; index++) {
            const item = data[index];
            for (const [key, value] of Object.entries(item)) {
                console.log(key, "keykey");
                if (!value && key !== "is_available_for_sale" && key !== "prices" && key !== "stock") {
                    showToast(`Value for key '${key}' at row ${index + 1} should not be empty.`, 'error');
                    isValid = false;
                    return isValid;
                }
    
                if (key === "upc") {
                    if (!value || value.length < 11 || value.length > 14) {
                        showToast(`UPC length should be between 11 to 14 digits at row ${index + 1}.`, 'error');
                        return false;
                    }
                }
    
                if ((key === "stocks" || key === "prices") && Array.isArray(value) && value.length === 0) {
                    showToast(`'${key}' at row ${index + 1} should not be empty.`, 'error');
                    return false;;
                }
                
            }
        }
        return isValid;
    };


    function validatesupplyData(data) {
        for (let i = 0; i < data?.length; i++) {
            let obj = data[i];
            if (obj.rest_quantity === undefined || obj.rest_quantity === null || obj.sold_quantity === undefined || obj.sold_quantity === null) {
                return false; // rest_quantity or sold_quantity is empty
            }
            if (!obj.prices || obj.prices.length === 0) {
                return false; // prices array is empty
            }
            for (let j = 0; j < obj.prices.length; j++) {
                let price = obj.prices[j];
                if (Object.values(price).some(val => val === undefined || val === null || val === '')) {
                    return false; // One of the price object keys is empty
                }
            }
            if (obj?.prices?.find(val => val?.price_type == "quantity_base")) {
                let data = obj?.prices?.find(val => val?.price_type == "quantity_base")

                console.log(data, "ddddddddddddddddddddd");
                for (let j = 0; j < data.bundle_price.length; j++) {
                    let price = data?.bundle_price[j];
                    console.log(price, "ddddddddddssssssssssssss");
                    if (Object.values(price).some(val => val === undefined || val === null || val === '')) {
                        return false; // One of the price object keys is empty
                    }
                }
            }
        }
        return true; // All validations passed
    }

    if (!obj?.image) {
        toast.error("Please select product images")
        return false
    }
    if (!obj?.name) {
        toast.error("Please enter product name")
        return false
    }
    if (!obj?.service_id) {
        toast.error("Please select service")
        return false
    }
    if (!obj?.category_id) {
        toast.error("Please select catgeory")
        return false
    }
    if (!obj?.sub_category_id) {
        toast.error("Please select sub-category")
        return false
    }
    if (!obj?.brand_id) {
        toast.error("Please select brand")
        return false
    }
    if (!obj?.description) {
        toast.error("Please enter product description")
        return false
    }
    // if (!obj?.price) {
    //     toast.error("Please enter product price")
    //     return false
    // }
    if (!obj?.type) {
        toast.error("Please enter product type")
        return false
    }
    if (!obj?.barcode) {
        toast.error("Please enter barcode")
        return false
    }
    if ((obj?.sku && obj?.sku?.length < 11) || (obj?.sku && obj?.sku?.length > 14)) {
        toast.error("Please enter valid sku")
        return false
    }
    if ((obj?.barcode && obj?.barcode?.length < 11) || (obj?.barcode && obj?.barcode?.length > 14)) {
        toast.error("Please enter valid barcode")
        return false
    }
    if (deliveryOptions?.includes("shipping")&&!dimensions?.weight) {
        toast.error("Please enter product weight")
        return false
    }
    if (deliveryOptions?.includes("shipping")&&!dimensions?.length) {
        toast.error("Please enter product length")
        return false
    }
    if (deliveryOptions?.includes("shipping")&&!dimensions?.breadth) {
        toast.error("Please enter product bredth")
        return false
    }
    if (deliveryOptions?.includes("shipping")&&!dimensions?.height) {
        toast.error("Please enter product height")
        return false
    }
    if (deliveryOptions?.length <= 0) {
        toast.error("Please select atleast one delivery option")
        return false
    }

    if (!validateData(obj?.product_variants) && obj?.product_options?.length > 0) {
        // toast.error("Please enter varinat info correctly")
        return false
    }
    if (!validatesupplyData(obj?.supplies) && obj?.supplies?.length > 0) {
        toast.error("Please enter supplies data correctly")
        return false
    }

    return true
}
export { editProductValidator };