import React, { useRef, useState } from 'react'
import CustomModal from '../../../components/shared/CustomModal'
import ConfigCategory from './configCategory';
import ConfigFaq from './configFaq';
import { inquiry1, question1 } from '../../../../utilities/images';
import AddnewfaqModal from './addnewfaqModal';
import AddnewcategoryModal from './addnewcategoryModal';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const Faq = () => {
  const faqRef = useRef(null);
  const faqCategoryRef = useRef(null);
  const [activeTab, setActiveTab] = useState("configFaq");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const handleDetails = (flag) => {
    if (flag == "OperatingState") {
      setModalDetail({ show: true, flag: "OperatingState" });
      setKey(Math.random());
    }
    if (flag == "TaxPayer") {
      setModalDetail({ show: true, flag: "TaxPayer" });
      setKey(Math.random());
    }
  }

  return (
    <>
      <DashboardNavbar title="FAQs" backShow={false} />
      <div className='configFaq'>
        {/* <div className='topHeader_'>
          <div className="topStickyHeader_">
            <div className="row align-items-center">
               <div className="col-md-6">
                <h2 className="mainHeading">
                  <span>System Config / <span className="innerSubtext">FAQ</span> </span>
                </h2>
              </div> 
              <div className="col-md-6 text-end">
                <div className="dropdown weekDropdown">
                  <button className="dropdown-toggle backgroundFillbtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className='plusIcon_' ><i className="fa-solid fa-plus"></i> </span>Add New
                  </button>
                  <ul className="dropdown-menu weekDropdownMenus">
                    <li onClick={() => {
                      setModalDetail({ show: true, flag: 'AddnewfaqModal' });
                      setKey(Math.random());
                    }}><a className="dropdown-item mb-2" > <img src={inquiry1} className="me-1" alt='faqInquiry' /> Add New FAQ</a></li>
                    <li onClick={() => {
                      setModalDetail({ show: true, flag: 'AddnewcategoryModal' });
                      setKey(Math.random());
                    }}><a className="dropdown-item" > <img src={question1} className="me-1" alt='faqInquiry' /> Add New FAQ Category </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className='row'>
          <div className='col-md-6'>
            <button className={`valuesTimeswallet ${activeTab === 'configFaq' ? 'active' : ""}`}
              onClick={() => setActiveTab('configFaq')}
            > Faq
            </button>
            <button className={`valuesTimeswallet ${activeTab === 'configCategory' ? 'active' : ""}`}
              onClick={() => setActiveTab('configCategory')}>
              Categories
            </button>
          </div>
          <div className='col-md-6 text-end'>
            <div className='faqQues'>
              <button onClick={() => {
                setModalDetail({ show: true, flag: 'AddnewfaqModal' });
                setKey(Math.random());
              }} className='discardBtn' type="button" ><img src={inquiry1} alt='' className='faqQuery me-1' />New FAQ</button>
              <button onClick={() => {
                setModalDetail({ show: true, flag: 'AddnewcategoryModal' });
                setKey(Math.random());
              }} className='saveBtn'><img src={question1} alt='' className='faqCate me-1' /> New FAQ Category</button>
            </div>
          </div>
        </div>
        <div className='plansAreasContainer'>

          {activeTab == 'configFaq'
            ?
            <ConfigFaq ref={faqRef} />
            :
            activeTab == 'configCategory'
              ?
              <ConfigCategory ref={faqCategoryRef} />
              : ""
          }
        </div>

      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "AddnewfaqModal" ? "addnewfaqModal" : modalDetail.flag === "AddnewcategoryModal" ? "addnewcategoryModal" : ""}
        size="lg"
        child={modalDetail.flag === 'AddnewfaqModal' ? <AddnewfaqModal faqRef={faqRef} close={() => handleOnCloseModal()} />
          :
          modalDetail.flag === 'AddnewcategoryModal' ? <AddnewcategoryModal faqCategoryRef={faqCategoryRef} close={() => handleOnCloseModal()} /> : ""
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "AddnewfaqModal" ?
                <h2 className="modalHeading">
                  Add New FAQ
                </h2>
                :
                modalDetail.flag === "AddnewcategoryModal" ?
                  <h2 className="modalHeading">
                    Add New Category
                  </h2>
                  : ""
              }
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Faq