import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_PRODUCT_URL, REACT_APP_WALLET_URL } from "../../config";
const queryString = require("query-string");

export function loginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/phone/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function exchangeTokenApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/exchange-token`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: data,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function sendPhoneOtpApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/send-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function verifyPhoneOtpApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/verify-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function sendEmailOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/send-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function verifyEmailOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/verify-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function resendOtpApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/resend-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function forgetPassword(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/forgot-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function verifyResetPassword(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/reset-password/verify`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}
export function resetPassword(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/reset-password/phone`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}
export function getServicesApi(data) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/services/b2b` + "?" + query, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        // toast.error(error?.response?.data?.msg)
      })
  );
}
export function sigupApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}
export function verifyOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/verify-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function resendOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/resend-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}
export function getAddressApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/seller_addresses?seller_id=${data.sellerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function getPrivilegesApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/privileges`, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function getRoleApi(data) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/roles` + "?" + query, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function getPosUsers(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user` +
          "?" +
          query,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function getSinglePosUser(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/${data?.id}`, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
          Authorization: token,
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function getSingleInvitationPosUser(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/invitations/${data?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function deleteAddressApi(token, data) {
  let id = data?.id;
  delete data?.id;
  return new Promise((resolve, reject) =>
    axios
      .delete(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/seller_addresses/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function createPosUserApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function createRoleApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/roles`, data, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
          Authorization: token,
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function updateRoleApi(data, token) {
  let id = data?.id;
  delete data?.id;
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_USER_SERVICES}api/v1/roles/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          "app-name": "merchant",
          Authorization: token,
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function deleteRoleApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/roles/${data?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function getUserByIdApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/${data?.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function checkPosUserApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user/check-phone`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function updatPosUserApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function updateMerchantApi(data, token) {
  let id = data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        // toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  );
}

export function updateCustomerApi(data, token) {
  let id = data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        // toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  );
}

export function updateInvitedCustomerApi(data, token) {
  let id = data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/invitations/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  );
}

export function deletePosUserApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user/${data?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function verifyQrCodeApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/2fa/verify`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function setPasswordApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/merchant/set-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}


export function sendOtpPhoneApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function validatePhoneApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/verify-phone`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function sendOtpEmailApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/send-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}

export function validateEmailApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICES}api/v1/users/verify-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
      })
  );
}



//Update user email and phone number

export const updateUserEmailRequest = createAction("UPDATE_EMAIL_REQUEST");
export const updateUserEmailSuccess = createAction("UPDATE_EMAIL_SUCCESS");
export const updateUserEmailFailure = createAction("UPDATE_EMAIL_FAILURE");

export const verifyUpdateUserEmailRequest = createAction(
  "VERIFY_UPDATE_EMAIL_REQUEST"
);
export const verifyUpdateEmailSuccess = createAction(
  "VERIFY_UPDATE_EMAIL_SUCCESS"
);
export const verifyUpdateEmailFailure = createAction(
  "VERIFY_UPDATE_EMAIL_FAILURE"
);

export const updateUserPhoneRequest = createAction("UPDATE_PHONE_REQUEST");
export const updateUserPhoneSuccess = createAction("UPDATE_PHONE_SUCCESS");
export const updateUserPhoneFailure = createAction("UPDATE_PHONE_FAILURE");

export const verifyUpdateUserPhoneRequest = createAction(
  "VERIFY_UPDATE_PHONE_REQUEST"
);
export const verifyUpdatePhoneSuccess = createAction(
  "VERIFY_UPDATE_PHONE_SUCCESS"
);
export const verifyUpdatePhoneFailure = createAction(
  "VERIFY_UPDATE_PHONE_FAILURE"
);

// Async action creator using Redux Thunk
export const updateUserEmailApi = (data, token) => async (dispatch) => {
  dispatch(updateUserEmailRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICES}api/v1/users/update-email`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      }
    );
    dispatch(updateUserEmailSuccess(response));
    toast.success(response?.data?.msg);
    return response;
  } catch (error) {
    dispatch(updateUserEmailFailure(error));
    toast.error(
      error?.response?.data?.payload
        ? error?.response?.data?.payload[0]
        : error?.response?.data?.msg
    );
    return error;
  }
};
export const verifyUpdateUserEmailApi = (data, token) => async (dispatch) => {
  dispatch(verifyUpdateUserEmailRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICES}api/v1/users/update-email/verify`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      }
    );
    dispatch(verifyUpdateEmailSuccess(response));
    toast.success(response?.data?.msg);
    return response;
  } catch (error) {
    dispatch(verifyUpdateEmailFailure(error));
    toast.error(
      error?.response?.data?.payload
        ? error?.response?.data?.payload[0]
        : error?.response?.data?.msg
    );
    return error;
  }
};

export const updateUserPhoneApi = (data, token) => async (dispatch) => {
  dispatch(updateUserPhoneRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICES}api/v1/users/update-phone`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      }
    );
    dispatch(updateUserPhoneSuccess(response));
    toast.success(response?.data?.msg);
    return response;
  } catch (error) {
    dispatch(updateUserPhoneFailure(error));
    toast.error(
      error?.response?.data?.payload
        ? error?.response?.data?.payload[0]
        : error?.response?.data?.msg
    );
    return error;
  }
};
export const verifyUpdateUserPhonelApi = (data, token) => async (dispatch) => {
  dispatch(verifyUpdateUserPhoneRequest());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICES}api/v1/users/update-phone/verify`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      }
    );
    dispatch(verifyUpdatePhoneSuccess(response));
    toast.success(response?.data?.msg);
    return response;
  } catch (error) {
    dispatch(verifyUpdatePhoneFailure(error));
    toast.error(
      error?.response?.data?.payload
        ? error?.response?.data?.payload[0]
        : error?.response?.data?.msg
    );
    return error;
  }
};
