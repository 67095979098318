import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  defaultUser,
  galleryImg,
  galleryUpload,
  infoImg,
  manage,
  user,
} from "../../../utilities/images";
// import ProductDetails from './serviceDetails'
import EditServiseImageAdd from "./serviceImageAdd";
import EditServiceHeader from "./EditServiceHeader";
import ProductPrice from "./productPrice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, getPosUsersAsync, selectAddressList, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { editProductApiAsync } from "../dashboard/dashboardSlice";
import Multiselect from "multiselect-react-dropdown";
import CustomModal from "../../components/shared/CustomModal";
import AddStaffModal from "./AddStaffModal";
import ServiceDetails from "./serviceDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ProductImageAdd from "../editProducts/productImageAdd";
import EditVarientsModal from "../myProducts/EditVarientsModal";
import EditServiceVarient from "./editvarientModal";

const EditService = (props) => {
  const history = useHistory();
  const location = useLocation();
  const selectedServiceDetails = location?.state;
  const [serviceId, setServiceId] = useState(selectedServiceDetails?.id);

  const selectValuesMinMaxOptions = Array.from({ length: 20 }, (_, index) => index + 1);
  const selectValuesHoursOptions = Array.from({ length: 12 }, (_, index) => `${index} hr`);
  const selectValuesMinutesOptions = Array.from({ length: 60 }, (_, index) => `${index} min`);
  const [maximumValue, setMaximumValue] = useState(selectedServiceDetails?.supplies[0]?.max_capacity ? selectedServiceDetails?.supplies[0]?.max_capacity : "")
  const [minimumValue, setMinimumValue] = useState(selectedServiceDetails?.supplies[0]?.min_capacity ? selectedServiceDetails?.supplies[0]?.min_capacity : "")
  const [hoursValue, setHoursValue] = useState(`${Math.floor(selectedServiceDetails?.supplies[0]?.buffer_time_before / 60)} hr`)
  const [minutesValue, setMinutesValue] = useState(`${selectedServiceDetails?.supplies[0]?.buffer_time_before % 60} min`)
  const [hoursValue1, setHoursValue1] = useState(`${Math.floor(selectedServiceDetails?.supplies[0]?.buffer_time_after / 60)} hr`)
  const [minutesValue1, setMinutesValue1] = useState(`${selectedServiceDetails?.supplies[0]?.buffer_time_after % 60} min`)
  const [approxTime, setApproxTime] = useState(selectedServiceDetails?.supplies[0]?.approx_service_time)
  const [costPrice, setcostPrice] = useState(selectedServiceDetails?.supplies[0]?.supply_variants[0]?.cost_price)

  let [price, setPrice] = useState([])
  const [formData, setFormData] = useState([]);
  const [sellingChange, setSellingChange] = useState(Math.random())
  const [marginChange, setMarginChange] = useState(Math.random())
  const [posChange, setPosChange] = useState(Math.random())
  const [b2cChange, setB2cChange] = useState(Math.random())
  const [indexChange, setIndexChange] = useState(0)
  const addressList = useSelector(selectAddressList)
  // Extracting numerical values from the strings
  const BufferTimeBeforeMinutes = parseInt(minutesValue.split(' ')[0]);
  const BufferTimeBeforeHours = parseInt(hoursValue.split(' ')[0]);
  const BufferTimeAfterMinutes = parseInt(minutesValue1.split(' ')[0]);
  const BufferTimeAfterHours = parseInt(hoursValue1.split(' ')[0]);
  const [serviceDetails, setServiceDetails] = useState({
    serviceName: selectedServiceDetails?.name,
    masterCategoryId: selectedServiceDetails?.service_id,
    category_id: selectedServiceDetails?.category_id,
    sub_category_id: selectedServiceDetails?.sub_category_id,
  });
  const [posList, setPosList] = useState([]);
  // const [costPrice, setcostPrice] = useState(selectedServiceDetails?.price ? selectedServiceDetails?.price : null)
  const [serviceDescription, setServiceDescription] = useState(
    selectedServiceDetails?.description
  );
  const [imageDetails, setImageDetails] = useState({
    image: selectedServiceDetails?.image,
    images: selectedServiceDetails?.product_images?.map((v) => v?.url),
  });

  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [oldSelectedPosId, setOldSelectedPosId] = useState([]);

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const getIdsHandle = (data) => {
    // console.log(oldSelectedPosId, data, "poss iddddsssssssss")
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setServiceDescription(e);
    } else {
      setServiceDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  // const handleChange = (event, index, appName, fieldName) => {
  //   const { value } = event.target;
  //   setFormData(prevFormData => {
  //     const newFormData = [...prevFormData];
  //     const priceToUpdateIndex = newFormData[index].prices.findIndex(price => price.app_name === appName);
  //     if (priceToUpdateIndex !== -1) {
  //       newFormData[index].prices[priceToUpdateIndex][fieldName] = Number(value);
  //     }
  //     if (priceToUpdateIndex !== -1 && fieldName == "selling_price" && costPrice) {
  //       newFormData[index].prices[priceToUpdateIndex]["margin_percentage"] = (((Number(value) - Number(costPrice)) / Number(costPrice)) * 100)?.toFixed(2);
  //     }
  //     if (priceToUpdateIndex !== -1 && fieldName == "margin_percentage" && costPrice) {
  //       newFormData[index].prices[priceToUpdateIndex]["selling_price"] = (parseFloat(costPrice) + (parseFloat(costPrice) * (parseFloat(value) / 100)))?.toFixed(2);
  //     }
  //     return newFormData;
  //   });
  // };
  

  const handleChange = (event, index, appName, fieldName) => {
    const { value } = event.target;
    setFormData((prevFormData) => {
        const newFormData = [...prevFormData];
        const priceToUpdateIndex = newFormData[index].prices.findIndex(
            (price) => price.app_name === appName
        );

        if (priceToUpdateIndex !== -1) {
            if (fieldName === "selling_price") {
                newFormData[index].prices[priceToUpdateIndex][fieldName] = value === "" ? "" : Number(value);

                // Calculate margin_percentage if selling price is not empty
                if (value !== "" && costPrice) {
                    newFormData[index].prices[priceToUpdateIndex]["margin_percentage"] = (
                        ((Number(value) - Number(costPrice)) / Number(costPrice)) *
                        100
                    ).toFixed(2);
                } else {
                    // If selling price is empty, set margin_percentage to empty as well
                    newFormData[index].prices[priceToUpdateIndex]["margin_percentage"] = "";
                }
            } else if (fieldName === "margin_percentage") {
                newFormData[index].prices[priceToUpdateIndex][fieldName] = value === "" ? "" : Number(value);

                // Calculate selling_price if margin_percentage is not empty
                if (value !== "" && costPrice) {
                    newFormData[index].prices[priceToUpdateIndex]["selling_price"] = (
                        parseFloat(costPrice) +
                        parseFloat(costPrice) * (parseFloat(value) / 100)
                    ).toFixed(2);
                } else {
                    // If margin_percentage is empty, set selling_price to empty as well
                    newFormData[index].prices[priceToUpdateIndex]["selling_price"] = "";
                }
            }
        }
        return newFormData;
    });
};
  
  const handleCheckboxChange = (event, index, fieldName, appName) => {
    setFormData(prevFormData => {
      const newFormData = [...prevFormData];
      newFormData[index][fieldName] = event;

      const priceToUpdateIndex = newFormData[index].prices.findIndex(price => price.app_name === appName);

      if(event){
        newFormData[index].prices = [
          ...newFormData[index]?.prices,
          {
            "app_name": appName,
            "price_type": "fixed",
            "selling_price": "",
            "margin_percentage": ""
          }
        ]
      }
      else{
        newFormData[index].prices = newFormData[index].prices.filter(price => price.app_name != appName)
      }

      // {
      //   "app_name": item1?.app_name,
      //   "price_type": "fixed",
      //   "selling_price": item1?.selling_price,
      //   "margin_percentage": item1?.margin_percentage
      // }

      return newFormData;
    });
  };

  function checkDataValidity(data) {
    for (let i = 0; i < data.length; i++) {
      const { prices } = data[i];
      if (prices.length === 0) {
        toast.error("Please select at least one service availability");
        return false;
      }
      for (let j = 0; j < prices.length; j++) {
        const { selling_price, margin_percentage } = prices[j];
        if (selling_price === "" || margin_percentage === "") {
          toast.error("Please enter selling price and profit margin value correctly");
          return false;
        }
      }
    }
    return true;
  }


  const handleSave = () => {
    if (!serviceDetails?.serviceName) {
      toast.error("Please enter service name");
      return;
    } else if (!serviceDetails?.masterCategoryId) {
      toast.error("Please select master category");
      return;
    } else if (
      serviceDetails?.masterCategoryId == "2" &&
      !serviceDetails?.category_id
    ) {
      toast.error("Please select category");
      return;
    } else if (
      serviceDetails?.masterCategoryId == "2" &&
      !serviceDetails?.sub_category_id
    ) {
      toast.error("Please select sub category");
      return;
    } else if (!serviceDescription) {
      toast.error("Please enter service description");
      return;
    }
    else if (!costPrice) {
      toast.error("Please enter cost price")
      return
    }
    else if (!selectedPosId) {
      toast.error("Please select POS User");
      return;
    }
    else if (!approxTime) {
      toast.error("Please enter approx service time")
      return
    }
    else if (!minimumValue) {
      toast.error("Please enter Minimum Capacity")
      return
    }
    else if (!maximumValue) {
      toast.error("Please enter maximum Capacity")
      return
    }
    else if (Number(minimumValue) > Number(maximumValue)) {
      toast.error("Minimum Capacity must be less Maximum capacity")
      return
    }
    else if(!checkDataValidity(formData)){
      return
    }

    const newPriceData = formData.map(item => {
      const { activePos, activeB2c, ...rest } = item;
      return rest;
    });
    let product_attribute = {
      "id": serviceId,
      "name": serviceDetails?.serviceName,
      "category_id": serviceDetails?.category_id
        ? serviceDetails?.category_id?.toString()
        : "",
      "sub_category_id": serviceDetails?.sub_category_id
        ? serviceDetails?.sub_category_id?.toString()
        : "",
      "service_id": serviceDetails?.masterCategoryId,
      "description": serviceDescription,
      "image": imageDetails?.image,
      "images": imageDetails?.images,
      "cost_price": costPrice,
      "approx_service_time": Number(approxTime),
      "min_capacity": parseInt(minimumValue),
      "max_capacity": parseInt(maximumValue),
      "buffer_time_before": BufferTimeBeforeMinutes + BufferTimeBeforeHours * 60,
      "buffer_time_after": BufferTimeAfterMinutes + BufferTimeAfterHours * 60,
      "pos_staff_ids": selectedPosId?.map((val) =>
        val?.pos_staff_details ? val?.pos_staff_details?.id : val?.id
      ),
      "supplies": newPriceData
    };

    console.log(product_attribute, formData, "responseeeeeeeeeeeeeeeeeeee");
    if (
      oldSelectedPosId.filter(
        (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
      )?.length > 0
    ) {
      product_attribute = {
        ...product_attribute,
        deleted_pos_staff_ids: oldSelectedPosId
          .filter(
            (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
          )
          ?.map((val) =>
            val?.pos_staff_details ? val?.pos_staff_details?.id : val?.id
          ),
      };
    }

    setSuccessLoader(true);
    let params = {
      postData: product_attribute,
      tokenData: auth?.payload?.token,
    };
    dispatch(editProductApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSuccessLoader(false);
        toast.success(obj?.msg);
        history.push("/service-list");
      })
      .catch((obj) => {
        setSuccessLoader(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token
    }
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
      })
      .catch((obj) => {
      })
  }

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // const modifiedData = obj.payload.pos_staff.map((item) => ({
        //     value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
        //     id: item.id
        // }));
        let staffIds = selectedServiceDetails?.pos_staff?.map(
          (item) => item?.pos_staff_details_id
        );
        setSelectedPosId(
          obj?.payload?.pos_staff?.filter((value) =>
            staffIds?.includes(value?.id)
          )
        );
        setOldSelectedPosId(
          obj?.payload?.pos_staff?.filter((value) =>
            staffIds?.includes(value?.id)
          )
        );
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let newResult = selectedServiceDetails?.supplies?.map((item, index) => {
      return {
        seller_address_id: item?.seller_address_id,
        activePos: item?.supply_variants[0]?.supply_variant_prices?.find(val => val?.app_name == "pos") ? true : false,
        activeB2c: item?.supply_variants[0]?.supply_variant_prices?.find(val => val?.app_name == "b2c") ? true : false,
        prices: item?.supply_variants[0]?.supply_variant_prices?.map((item1, index) => {
          return {
            "app_name": item1?.app_name,
            "price_type": "fixed",
            "selling_price": item1?.selling_price,
            "margin_percentage": item1?.margin_percentage
          }
        })
      };
    });
    setPrice(newResult)
    setFormData(newResult)
  }, [])

    // Effect to recalculate margins when cost price changes
    useEffect(() => {
      if (costPrice !== undefined && costPrice !== null && costPrice !== '') {
          setFormData(prevFormData => {
              const updatedFormData = prevFormData.map(item => {
                  item.prices.forEach(price => {
                      if (price.selling_price !== "") {
                          price.margin_percentage = (
                              ((Number(price.selling_price) - Number(costPrice)) / Number(costPrice)) *
                              100
                          ).toFixed(2);
                      }
                  });
                  return item;
              });
              return updatedFormData;
          });
      } else {
          // If costPrice is empty or undefined, remove margin_percentage
          setFormData(prevFormData => {
              const updatedFormData = prevFormData.map(item => {
                  item.prices.forEach(price => {
                      price.margin_percentage = "";
                  });
                  return item;
              });
              return updatedFormData;
          });
      }
  }, [costPrice, setFormData]);

  useEffect(() => {
    getAllAddress()
  }, [])

  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <EditServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid pt-3 pb-3">
          <div className="row justify-content-center">
            <div className="col-md-10 ">
              {/* Start Uploaded ProductImg Area */}
              {/* <EditServiseImageAdd imageData={(e) => handleImageDetails(e)} /> */}
              <ProductImageAdd
                imageData={(e) => handleImageDetails(e)}
                oldImages={imageDetails}
              />

              {/* End Uploaded ProductImg Area */}

              {/* Start ProductDetails Area */}
              <div className="mt-4">
                <div className="row">
                  {/* Start ProductDetails Area */}
                  <div className="col-md-8">
                    <ServiceDetails
                      data={selectedServiceDetails}
                      servicesDetails={(e, flag) =>
                        handleProductDetails(e, flag)
                      }
                    />
                    {/* Start CostPrice Area */}
                    <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                      <form className='costPrice'>
                        <div className='form-group'>
                          <label className="subheaderHeading mb-2">Cost Price
                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                          </label>
                          <div className='inputGroup'>
                            <input
                              min="1"
                              onKeyDown={blockInvalidChar}
                              className="customform-control"
                              placeholder="Cost Price"
                              name="text"
                              type="number"
                              value={costPrice}
                              onChange={(e) => setcostPrice(e.target.value)}
                            />
                            <span className='dollrsign_'>$</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                      <form className='costPrice'>
                        <div className='form-group'>
                          <label className="subheaderHeading mb-2">Approx Service Time
                          </label>
                          <div className='inputGroup'>
                            <input
                              min="1"
                              onKeyDown={blockInvalidChar}
                              className="customform-control"
                              placeholder="Service time in min"
                              name="serviceTime"
                              type="number"
                              value={approxTime}
                              onChange={(e) => setApproxTime(e.target.value)}
                            />
                          </div>
                        </div>
                      </form>
                      <div className='row'>
                        <label className="subheaderHeading mb-2 mt-4">Capacity
                        </label>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className="mb-2">Minimum
                            </label>
                            <div className='inputGroup'>
                              <div className='inputGroup'>
                                <select className="cateSelectdFilter" placeholder='minimum' value={minimumValue} onChange={(e) => setMinimumValue(e.target.value)}>
                                  <option value="">Min</option>
                                  {selectValuesMinMaxOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className="mb-2">Maximum
                            </label>
                            <div className='inputGroup'>
                              <select className="cateSelectdFilter" placeholder='maximum' value={maximumValue} onChange={(e) => setMaximumValue(e.target.value)} >
                                <option value="">Max</option>
                                {selectValuesMinMaxOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <label className="subheaderHeading mb-2 mt-4">Buffer Time Before
                        </label>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <div className='inputGroup'>
                              <div className='inputGroup'>
                                <select className="cateSelectdFilter" placeholder='hours' value={hoursValue} onChange={(e) => setHoursValue(e.target.value)}>
                                  {selectValuesHoursOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <div className='inputGroup'>
                              <select className="cateSelectdFilter" placeholder='minutes' value={minutesValue} onChange={(e) => setMinutesValue(e.target.value)}>
                                {selectValuesMinutesOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <label className="subheaderHeading mb-2 mt-4">Buffer Time After
                        </label>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <div className='inputGroup'>
                              <div className='inputGroup'>
                                <select className="cateSelectdFilter" placeholder='hours' value={hoursValue1} onChange={(e) => setHoursValue1(e.target.value)}>
                                  {selectValuesHoursOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <div className='inputGroup'>
                              <select className="cateSelectdFilter" placeholder='minutes' value={minutesValue1} onChange={(e) => setMinutesValue1(e.target.value)}>
                                {selectValuesMinutesOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>




              {/* End ProductDetails Area */}
              <div className="row mt-3 mb-3">
                <div className="col-md-8">
                  <div className="outerArea ">
                    <label className="labelTxt mb-3" htmlFor="amount">
                      <b>Pos User</b>
                    </label>
                    {selectedPosId?.length > 0 ? (
                      <>
                        {selectedPosId?.map((val) => {
                          let logo;
                          if (val?.user) {
                            logo = val?.user?.user_profiles?.profile_photo
                              ? val?.user?.user_profiles?.profile_photo
                              : defaultUser;
                          } else if (val?.pos_staff_details) {
                            logo = val.pos_staff_details?.user?.user_profiles
                              ?.profile_photo
                              ? val.pos_staff_details?.user?.user_profiles
                                ?.profile_photo
                              : defaultUser;
                          } else {
                            logo = defaultUser;
                          }

                          return (
                            <img
                              className="me-2"
                              src={logo}
                              alt="pos_img"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                              }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    <span class="editIcon_">
                      <i
                        class="fa-solid fa-plus"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalDetail({ show: true, flag: "addStaff" });
                          setKey(Math.random());
                        }}
                      ></i>
                    </span>
                  </div>
                </div>
                {/* <EditServiceVarient
                  type="service"
                   selectedServices={selectedServiceDetails}
                /> */}
              </div>


              <div className="row mt-3 mb-3">
                <div className="col-md-8">
                  <div className="outerArea ">
                    <label className="labelTxt mb-3" htmlFor="amount">
                      <b>Supply</b>
                    </label>
                    {price?.length > 0 ? (
                      <>
                        {formData?.map((supply, index) => (
                          <div key={supply?.seller_address_id}>
                            <h3>Address: {addressList?.payload?.find(data => data?.id == supply.seller_address_id)?.format_address}</h3>

                            <div className='outerArea mb-3'>
                              <h3 className='subheaderHeading mb-4'>Service availability</h3>
                              <ul className='productToggleMenus'>
                                <li className='productToggleItems'>
                                  <div className="form-check form-switch me-2 mb-0">
                                    <input
                                      type="checkbox"
                                      checked={supply?.activePos}
                                      onChange={(e) => handleCheckboxChange(!supply?.activePos, index, 'activePos', "pos")}
                                    />
                                  </div>
                                  <h3 className='labelTxt'><b>JOBR POS</b> <i>(Physical store)</i></h3>
                                </li>

                                <li className='productToggleItems'>
                                  <div className="form-check form-switch me-2 mb-0">
                                    <input type="checkbox"
                                      checked={supply?.activeB2c}
                                      onChange={(e) => handleCheckboxChange(!supply?.activeB2c, index, 'activeB2c', "b2c")}
                                    />
                                  </div>
                                  <h3 className='labelTxt'><b>JOBR B2C</b></h3>
                                </li>

                              </ul>
                            </div>

                            {supply?.activePos && <div className='outerArea mt-3'>
                              <h2 className='headingBlue mb-4'>
                                JOBR POS <span className='linkTxt'><i>(Physical store)</i></span>
                                <img src={infoImg} alt='InfoImg' className='infoIcon ms-2' />
                              </h2>
                              <form className='inventory row'>
                                <div className='form-group mb-3 col-md-6'>
                                  <label className="deatilsHead mb-2 fw-normal">Selling Price  for Customer
                                  </label>
                                  <div className='inputGroup'>
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customform-control"
                                      placeholder="125"
                                      name="selling_price"
                                      type="number"
                                      value={supply?.prices?.find(val => val?.app_name == "pos")?.selling_price}
                                      onChange={(e) => { handleChange(e, index, "pos", 'selling_price'); setSellingChange(Math.random()); setIndexChange(index); setPosChange(Math.random()) }}
                                    />
                                    <span className='dollrsign_'>$</span>
                                  </div>
                                </div>
                                <div className='form-group col-md-6'>
                                  <label className="deatilsHead mb-2 fw-normal">Profit Margin
                                  </label>
                                  <div className='inputGroup'>
                                    <input
                                      // disabled
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customform-control"
                                      placeholder="10"
                                      name="margin_percentage"
                                      type="number"
                                      value={supply?.prices?.find(val => val?.app_name == "pos")?.margin_percentage}
                                      onChange={(e) => { handleChange(e, index, "pos", 'margin_percentage'); setMarginChange(Math.random()); setIndexChange(index); setPosChange(Math.random()) }}
                                    />
                                    <span className='percentagesign_'>%</span>
                                  </div>
                                </div>
                              </form>
                            </div>}

                            {supply?.activeB2c && <div className='outerArea mt-3'>
                              <h2 className='headingBlue mb-4'>
                                JOBR B2C <span className='linkTxt'><i>(Physical store)</i></span>
                                <img src={infoImg} alt='InfoImg' className='infoIcon ms-2' />
                              </h2>
                              <form className='inventory row'>
                                <div className='form-group mb-3 col-md-6'>
                                  <label className="deatilsHead mb-2 fw-normal">Selling Price  for Customer
                                  </label>
                                  <div className='inputGroup'>
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customform-control"
                                      placeholder="125"
                                      name="selling_price"
                                      type="number"
                                      value={supply?.prices?.find(val => val?.app_name == "b2c")?.selling_price}
                                      onChange={(e) => { handleChange(e, index, "b2c", 'selling_price'); setSellingChange(Math.random()); setIndexChange(index); setB2cChange(Math.random()) }}
                                    />
                                    <span className='dollrsign_'>$</span>
                                  </div>
                                </div>
                                <div className='form-group col-md-6'>
                                  <label className="deatilsHead mb-2 fw-normal">Profit Margin
                                  </label>
                                  <div className='inputGroup'>
                                    <input
                                      // disabled
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customform-control"
                                      placeholder="10"
                                      name="margin_percentage"
                                      type="number"
                                      value={supply?.prices?.find(val => val?.app_name == "b2c")?.margin_percentage}
                                      onChange={(e) => { handleChange(e, index, "b2c", 'margin_percentage'); setMarginChange(Math.random()); setIndexChange(index); setB2cChange(Math.random()) }}
                                    />
                                    <span className='percentagesign_'>%</span>
                                  </div>
                                </div>
                              </form>
                            </div>}

                          </div>

                        ))}

                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "ActivePos"
            ? "activePos"
            : modalDetail.flag === "addStaff"
              ? "addStaff"
              : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "addStaff" ? (
            <AddStaffModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
            />
          ) : (
            <></>
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              <h2 className="modalHeading">Staffs</h2>
              <button className="closeButton">
                <i
                  className="las la-times"
                  onClick={() => handleOnCloseModal()}
                ></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default EditService;
