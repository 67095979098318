import React from 'react'
import { deleteAlert } from '../../../utilities/images'

const DeleteVariantModal = (props) => {
    return (
        <>
            <div className='deleteVarientSec'>
                <div className='deletVarientFlex'>
                    <img src={deleteAlert} alt='deleteAlert' className='img-fluid' />
                    <div className='deleteVarientInner'>
                        <h4 className='priceHead_'>Deleted Variations</h4>
                        <p className='personalinfoHead'>Are you sure you want to delete all selected ? This action cannot be undone.</p>
                    </div>
                </div>
                <div className='varientFooterBtn mt-4'>
                <button type='button' onClick={() => props?.close()} className='varientBtnBlue btnDiscard_ w-100'>Discard</button>
                <button type='submit' onClick={() => {props?.handleDeleteSelected(); props?.close()}} className='varientBtnBlue btnDelete w-100'>Delete</button>
                </div>
            </div>
        </>
    )
}

export default DeleteVariantModal