import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { galleryImg, galleryUpload, imgGallery1, infoImg, manage } from '../../../utilities/images'
import AddProductHeader from './EditProductHeader'
import FixedPrice from './fixedPrice'
import CustomPrice from './customPrice'
import AddOptionsModal from './addOptionsModal'
import CustomModal from '../../components/shared/CustomModal'
import AddAttributesModal from './addAttributesModal';
import ProductPrice from './productPrice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getAddressApiAsync, selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { imageUploadAsync } from '../message/messageSlice';
import { toast } from 'react-toastify';
import { formValidator } from './formValidator'
import { createProductApiAsync, editProductApiAsync, editProductSupplyApiAsync, getAttributesApiAsync } from '../dashboard/dashboardSlice'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CreateOptionModal from '../product/createOptionModal'
import { getProductListByIdAsync } from '../myProducts/productSlice'

const EditProductSupply = (props) => {
    const history = useHistory();
    const location = useLocation();
    const selectedProductDtails = location?.state;

    console.log(selectedProductDtails,"dddddddddddddddddddddddddd");
    const [productId, setProductId] = useState(selectedProductDtails?.product_id)
    const [supplyId, setSupplyId] = useState(selectedProductDtails?.id)
    const [addressId, setAddressId] = useState(selectedProductDtails?.seller_address_id)
    const [allAddress, setAllAddress] = useState([]);
    const [skipPosDetails, setSkipPosDetails] = useState(selectedProductDtails?.skip_detail_screen_in_pos)
    const [prices, setPrices] = useState([]);
    const [weightUnit, setWeightUnit] = useState(selectedProductDtails?.weight_unit ? selectedProductDtails?.weight_unit : "kg");
    const [dimensions, setDimensions] = useState({
        weight: selectedProductDtails?.weight,
        length: selectedProductDtails?.length,
        breadth: selectedProductDtails?.breadth,
        height: selectedProductDtails?.height
    });

    const [singleImage, setSingleImage] = useState();
    const [activePos, setActivePos] = useState(false);
    const [activeOnlineStore, setActiveOnlineStore] = useState(false);
    const [activeBtoc, setActiveBtoc] = useState(false);
    const [activeBtob, setActiveBtob] = useState(false);
    const [activeData, setActiveData] = useState("");
    const [inventoryOpening, setInventoryOpening] = useState(selectedProductDtails?.total_quantity);
    const [inventoryReorderPoint, setInventoryReorderPoint] = useState(selectedProductDtails?.quantity_reorder_point);
    const [ingredients, setIngredients] = useState([]);
    const [productAvailability, setProductAvailability] = useState(selectedProductDtails?.supply_prices);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    console.log(deliveryOptions, 'optionssssssssssssss');
    const [type] = useState("physical");
    const [optionsList, setOptionsList] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [selectedAttributesValue, setSelectedAttributesValue] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [successLoader, setSuccessLoader] = useState(false)
    const [checkedOptionList, setCheckedOptionList] = useState([]);
    const [checkedValue, setCheckedValue] = useState([]);
    const [allSelectChecked, setAllSelectChecked] = useState(false);
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [unitType, setUnitType] = useState(selectedProductDtails?.quantity_unit ? selectedProductDtails?.quantity_unit : "per_unit");
    const [costPrice, setcostPrice] = useState(selectedProductDtails?.cost_price ? selectedProductDtails?.cost_price : "")
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });


    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleUnitChange = (e) => {
        setUnitType(e.target.value)
    }

    const handleChangeJobrPos = () => {
        setActiveOnlineStore(!activeOnlineStore);
        if (activeBtoc) {
            setActiveBtoc(false);
        }
        if (activeBtob) {
            setActiveBtob(false);
        }
    }
    const handledimensions = (e) => {
        const { name, value } = e.target;
        let data = { ...dimensions, [name]: value }
        setDimensions(data);
    }

        const handleprices = (e) => {}
    // const handleprices = (e) => {

    //     if (e?.length > 0) {
    //         let filteredData = e?.filter(item => {
    //             if ((item.app_name === "b2b" && activeBtob) || (item.app_name === "pos" && activePos) || (item.app_name === "b2c" && activeBtoc)) {
    //                 return true;
    //             }
    //             return false;

    //         });

    //         setPrices(filteredData);
    //     }
    // }

    const handleAttributes = (e) => {
        setIngredients(e);
    }

    const handleOptions = (attributes, data, value) => {
        setOptionsList(attributes);
        setCombinations(data);
        setCheckedOptionList(
            new Array(attributes?.length)?.fill(false)
        )
        setSelectedAttributesValue(value)
    }

    const handlechangeShippingOrPickup = (optionValue) => {
        if (deliveryOptions.includes(optionValue)) {
            setDeliveryOptions(deliveryOptions.filter(option => option !== optionValue));
        } else {
            setDeliveryOptions([...deliveryOptions, optionValue]);
        }
    }

    // const handleSkipPOSDetails = (e) => {
    //     setSkipPosDetails(e.target.checked)
    // }

    const handleCheckbox = (position) => {
        const updatedCheckedState = checkedOptionList.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedOptionList(updatedCheckedState)
        let data = updatedCheckedState.map((val, index) => {
            return (
                val === true ? index : false
            )
        })
        let result = data?.filter(val => val !== false);
        setCheckedValue(result);
        if (result.length == checkedOptionList.length) {
            setAllSelectChecked(true);
        } else {
            setAllSelectChecked(false);
        }
    }

    const handleSelectAllChange = (e) => {
        if (e.target.checked == false) {
            setAllSelectChecked(false);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(false)
            )
            setCheckedValue([]);
        } else {
            setAllSelectChecked(true);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(true)
            )
            const updatedCheckedState = checkedOptionList.map((item, index) =>
                index
            );
            setCheckedValue(updatedCheckedState);
        }
    }
    function replaceImage(arr, indexes, url) {
        indexes.forEach(index => {
            if (arr[index]) {
                arr[index].image = url;
            }
        });
        // setCombinations(arr)
        setOptionsList(arr);
        return arr;
    }

    const handleModalChange = (flag) => {
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const uploadImage = (e) => {
        if (checkedValue.length <= 0) {
            toast.error("Please select checkbox")
        }
        else {
            const imageFile = e.target.files[0];
            let formData = new FormData();
            formData.append(`file`, imageFile);

            let params = {
                file: formData,
                token: auth?.payload?.token
            }
            setLoading(true)
            dispatch(imageUploadAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSingleImage(obj?.payload[0]?.filePath);
                    const newImage = obj?.payload[0]?.filePath;
                    replaceImage(optionsList, checkedValue, newImage)
                    setLoading(false)
                }
                )
                .catch((obj) => {
                    setLoading(false)
                });
        }

    }

        const handleSave = () => {}

    // const handleSave = () => {
    //     let product_attribute = {
    //         "id": productId,
    //         "supplyId": supplyId,
    //         "type": "physical",
    //         "skip_detail_screen_in_pos": skipPosDetails,
    //         "delivery_options": deliveryOptions,
    //         "quantity": inventoryOpening,
    //         "prices": prices,
    //         "cost_price": costPrice,
    //         "quantity_unit": unitType,
    //         "seller_address_id": Number(addressId)
    //     }
    //     if (ingredients?.length > 0) {
    //         product_attribute = { ...product_attribute, "ingredients": ingredients, }
    //     }
    //     if (deliveryOptions.length > 0) {
    //         product_attribute = {
    //             ...product_attribute,
    //             "weight": deliveryOptions?.includes("shipping") ? dimensions?.weight : 0,
    //             "length": deliveryOptions?.includes("shipping") ? dimensions?.length : 0,
    //             "height": deliveryOptions?.includes("shipping") ? dimensions?.height : 0,
    //             "breadth": deliveryOptions?.includes("shipping") ? dimensions?.breadth : 0,
    //         }
    //     }
    //     if (inventoryReorderPoint) {
    //         product_attribute = { ...product_attribute, "quantity_reorder_point": inventoryReorderPoint }
    //     }
    //     if (optionsList?.length > 0) {
    //         product_attribute = { ...product_attribute, "attributes": optionsList, }
    //     }
    //     if (deliveryOptions?.length <= 0) {
    //         toast.error("Please select delivery options")
    //         return false
    //     }
    //     if ((deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && !dimensions?.weight) || (deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && dimensions?.weight == 0)) {
    //         toast.error("Please enter product weight")
    //         return false
    //     }
    //     if ((deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && !dimensions?.length) || (deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && dimensions?.length == 0)) {
    //         toast.error("Please enter product length")
    //         return false
    //     }
    //     if ((deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && !dimensions?.breadth) || (deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && dimensions?.breadth == 0)) {
    //         toast.error("Please enter product bredth")
    //         return false
    //     }
    //     if ((deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && !dimensions?.height) || (deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") && dimensions?.height == 0)) {
    //         toast.error("Please enter product height")
    //         return false
    //     }
    //     if (!inventoryOpening) {
    //         toast.error("Please enter Inventory-Opening")
    //         return false
    //     }
    //     if (prices?.length == 0) {
    //         toast.error("Please add selling price and profit margin")
    //         return false
    //     }
    //     let params = {
    //         "postData": product_attribute,
    //         "tokenData": auth?.payload?.token
    //     }
    //     setSuccessLoader(true)
    //     dispatch(editProductSupplyApiAsync(params))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setSuccessLoader(false)
    //             toast.success(obj?.msg)
    //             history.push(`/productList`)
    //         }
    //         )
    //         .catch((obj) => {
    //             setSuccessLoader(false)
    //         });
    // }

    // const getAttributes = () => {
    //     let data = {
    //         postData: {
    //             // service_id: props?.id
    //         },
    //         tokenData: auth?.payload?.token,
    //     }
    //     setLoading(true)
    //     dispatch(getAttributesApiAsync(data))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setLoading(false);
    //             let newData = obj?.payload?.data?.filter(v => v?.name == JSON.parse(selectedProductDtails?.supply_variants[0]?.attribute_variant?.variants)[0]?.attribute_name)
    //             setSelectedAttributesValue(newData)
    //         }
    //         )
    //         .catch((obj) => {
    //             setLoading(false)
    //         })
    // }

    // useEffect(() => {
    //     handleprices(prices)
    // }, [activePos, activeBtob, activeBtoc])

    // useEffect(() => {
    //     let oldCombinations = selectedProductDtails?.supply_variants?.map(v => {
    //         return { [JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_value_name]: { 'id': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_value_id, 'attribute_id': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_id, 'attribute_name': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_name, 'name': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_value_name, }, 'price': v?.price, 'upc': v?.upc, 'weight': v?.weight, 'image': v?.image, 'stock': v?.stock, 'attribute_name': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_name, 'name': JSON.parse(v?.attribute_variant?.variants)[0]?.attribute_value_name }
    //     })
    //     // console.log(oldCombinations,"old combinations")
    //     // console.log(JSON.parse(selectedProductDtails.supply_variants[0]?.attribute_variant?.variants), "supply variamnts")

    //     setCombinations(oldCombinations)

    //     // Applying condition based on price_type
    //     let bundle_price = []
    //     const modifiedPrices = selectedProductDtails?.supply_prices.map((price) => {
    //         if (price.price_type === 'fixed') {
    //             const { id, min_qty, max_qty, ...modifiedFixedPrice } = price;
    //             return modifiedFixedPrice;
    //         }
    //         if (price.price_type === 'quantity_base') {
    //             const { min_qty, max_qty, selling_price, margin_percentage } = price;
    //             bundle_price.push({
    //                 min_qty,
    //                 max_qty,
    //                 selling_price,
    //                 margin_percentage
    //             })
    //         }
    //         return null
    //     });
    //     if (bundle_price.length > 0) {
    //         modifiedPrices.push({ app_name: "b2b", price_type: "quantity_base", bundle_price: bundle_price })
    //     }

    //     const finalPrices = modifiedPrices?.filter((item) => item !== null)


    //     localStorage.setItem("priceLatest", JSON.stringify(finalPrices))

    //     setPrices(finalPrices);
    //     // setIngredients(selectedProductDtails.supply_prices)

    //     let newOptionList = selectedProductDtails?.supply_variants?.map(v => {
    //         return {
    //             "attribute_values": JSON.parse(v?.attribute_variant?.variants),
    //             "image": v?.image,
    //             "price": v?.price,
    //             "stock": v?.stock,
    //             "upc": v?.upc,
    //             "weight": v?.weight
    //         }
    //     })
    //     setOptionsList(newOptionList)
    //     let newData = selectedProductDtails?.delivery_options?.split(",")?.map(v => v === "1" ? "delivery" : v === '2' ? "reservation" : v === '3' ? "pickup" : v === '4' ? "shipping" : "")
    //     setDeliveryOptions(newData)
    //     if (selectedProductDtails?.supply_prices?.find(item => item?.app_name === 'b2b')) {
    //         setActiveOnlineStore(true)
    //         setActiveData(selectedProductDtails?.supply_prices?.find(item => item?.app_name === 'b2b')?.price_type)
    //         setActiveBtob(true)
    //     }
    //     if (selectedProductDtails?.supply_prices?.find(item => item?.app_name === 'b2c')) {
    //         setActiveOnlineStore(true)
    //         setActiveBtoc(true)
    //     }
    //     if (selectedProductDtails?.supply_prices?.find(item => item?.app_name === 'pos')) {
    //         setActivePos(true)
    //     }
    //     getAttributes()
    // }, [])

    // const getAllAddress = () => {
    //     const params = {
    //         sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
    //         token: auth?.payload?.token
    //     }
    //     dispatch(getAddressApiAsync(params))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setAllAddress(obj?.payload)
    //         })
    //         .catch((obj) => {
    //         })
    // }
    // const getProductListById = () => {
    //     let params = {
    //         tokenData: auth?.payload?.token,
    //         postData: {
    //             id: selectedProductDtails?.product_id,
    //             seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
    //             app_name: "merchant",
    //             need_pos_users: true
    //         }
    //     }
    //     dispatch(getProductListByIdAsync(params))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setDimensions({
    //                 weight: obj?.payload?.product_detail?.weight,
    //                 length: obj?.payload?.product_detail?.length,
    //                 breadth: obj?.payload?.product_detail?.breadth,
    //                 height: obj?.payload?.product_detail?.height
    //             })
    //         })
    //         .catch((obj) => {
    //         })
    // }
    // useEffect(() => {
    //     getAllAddress()
    // }, [])
    // useEffect(() => {
    //     if (selectedProductDtails?.product_id) {
    //         getProductListById();
    //     }
    // }, []);
    return (
        <>
            <div className='addProductManually'>
                {/* Start FixedTopHeader */}
                {/* <AddProductHeader save={(e) => handleSave(e)} successLoader={successLoader} /> */}
                {/* End FixedTopHeader */}
                <div className="productRightWrapper">
                    <div className='container pt-3 pb-3'>
                        <div className='row'>
                            <div className='col-md-12 '>
                                {/* Start ProductDetails Area */}
                                <div className='mt-4'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='productDetailsContainer px-4 mb-3'>
                                                <form className='row costPrice'>
                                                    <div className='form-group  col-md-6 mb-4'>
                                                        <label className="subheaderHeading mb-2">Cost Price
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input
                                                                min="1"
                                                                onKeyDown={blockInvalidChar}
                                                                className="customform-control"
                                                                placeholder="Cost Price"
                                                                name="text"
                                                                type="number"
                                                                value={costPrice}
                                                                onChange={(e) => setcostPrice(e.target.value)}
                                                            />
                                                            <span className='dollrsign_'>$</span>
                                                        </div>
                                                    </div>
                                                    <div className='form-group col-md-6 mb-4'>
                                                        <label className='subheaderHeading mb-2' htmlFor='amount'>Unit</label>
                                                        <select autoComplete="new-password" className='customform-control select' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                                                            <option value="per_unit">per unit</option>
                                                            <option value="per_box">per box</option>
                                                            <option value="per_bag">per bag</option>
                                                            <option value="per_pound">per pound</option>
                                                            <option value="per_feet">per feet</option>
                                                            <option value="per_gallon">per gallon</option>
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            {/* Start ProductAvailability Area */}
                                            <div className='outerArea mb-3 h-100'>
                                                <h3 className='subheaderHeading mb-4'>Product availability</h3>
                                                <ul className='productToggleMenus'>
                                                    <li className='productToggleItems'>
                                                        <div className="form-check form-switch me-2 mb-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={activePos}
                                                                onChange={() => setActivePos(!activePos)}
                                                            />
                                                        </div>
                                                        <h3 className='labelTxt'><b>JOBR POS</b> <i>(Physical store)</i></h3>
                                                    </li>
                                                    <li className='productToggleItems'>
                                                        <div className="form-check form-switch me-2 mb-0">
                                                            <input
                                                                type="checkbox"
                                                                onChange={() => handleChangeJobrPos()}
                                                                checked={activeBtob || activeBtoc}
                                                            />
                                                        </div>
                                                        <h3 className='labelTxt'><b>JOBR Online Store</b></h3>
                                                    </li>
                                                    {activeOnlineStore ?
                                                        <>
                                                            <li className='productToggleItems ms-4'>
                                                                <div className="form-check form-switch me-2 mb-0">
                                                                    <input type="checkbox"
                                                                        checked={activeBtoc}
                                                                        onChange={() => setActiveBtoc(!activeBtoc)}
                                                                    />
                                                                </div>
                                                                <h4 className='storeText'>JOBR B2C</h4>
                                                            </li>
                                                            <li className='productToggleItems pl-2 ms-4'>
                                                                <div className="form-check form-switch  me-2 mb-0 ">
                                                                    <input type="checkbox"
                                                                        checked={activeBtob}
                                                                        onChange={() => setActiveBtob(!activeBtob)}
                                                                    />
                                                                </div>
                                                                <h4 className='storeText'>JOBR B2B</h4>
                                                            </li>
                                                        </>
                                                        :
                                                        ""}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            {/* End ProductAvailability Area */}
                                            {/* Start Inventory Area */}
                                            <div className='outerArea mb-3 h-100'>
                                                <h3 className='subheaderHeading mb-4'>Inventory</h3>
                                                <form className='inventory'>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">Inventory-Opening
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input
                                                                min="1"
                                                                onKeyDown={blockInvalidChar}
                                                                className="customform-control border"
                                                                placeholder=" Quantity "
                                                                type="number"
                                                                value={inventoryOpening}
                                                                onChange={(e) => setInventoryOpening(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className="deatilsHead mb-2 fw-normal">Inventory-Reorder Point
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input
                                                                min="1"
                                                                onKeyDown={blockInvalidChar}
                                                                className="customform-control border"
                                                                placeholder=" Quantity reorder point"
                                                                type="number"
                                                                value={inventoryReorderPoint}
                                                                onChange={(e) => setInventoryReorderPoint(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* End Inventory Area */}
                                            {/* Start Attributes Area */}
                                        </div>
                                        <div className='col-md-4'>
                                            {/* End Attributes Area */}
                                            {/* Start ShippingPickup Area */}
                                            <div className='outerArea h-100'>
                                                <h3 className='subheaderHeading mb-4'>Shipping or Pickup</h3>
                                                <ul className='productToggleMenus'>
                                                    <li className='productToggleItems'>
                                                        <div className="form-check form-switch me-2 mb-0">
                                                            <input type="checkbox"
                                                                id="pickup"
                                                                value="pickup"
                                                                onChange={() => { handlechangeShippingOrPickup("pickup") }}
                                                                checked={deliveryOptions?.includes("pickup")}
                                                            />
                                                        </div>
                                                        <h3 className='labelTxt'><b>Pickup</b></h3>
                                                    </li>
                                                    <li className='productToggleItems'>
                                                        <div className="form-check form-switch me-2 mb-0">
                                                            <input type="checkbox"
                                                                id="delivery"
                                                                value="delivery"
                                                                onChange={() => { handlechangeShippingOrPickup("delivery") }}
                                                                checked={deliveryOptions?.includes("delivery")}
                                                            />
                                                        </div>
                                                        <h3 className='labelTxt'><b>Delivery</b></h3>
                                                    </li>
                                                    <li className='productToggleItems'>
                                                        <div className="form-check form-switch me-2 mb-0">
                                                            <input type="checkbox"
                                                                id="shipping"
                                                                value="shipping"
                                                                onChange={() => { handlechangeShippingOrPickup("shipping") }}
                                                                checked={deliveryOptions?.includes("shipping")}
                                                            />
                                                        </div>
                                                        <h3 className='labelTxt'><b>Shipping</b></h3>
                                                    </li>
                                                </ul>
                                                <p className='descriptionPara_ mt-4'>
                                                    Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                                </p>
                                            </div>
                                            {/* End ShippingPickup Area */}
                                        </div>
                                    </div>
                                </div>
                                {
                                    deliveryOptions?.length > 0 && deliveryOptions?.includes("shipping") &&

                                    <div className='outerArea'>
                                        <form className='editFormControl'>
                                            <div className='form-group mb-3 '>
                                                <label className="deatilsHead mb-2 fw-normal">Product weight
                                                    <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                </label>

                                                <div className='productWeight_'>
                                                    <div className='col-lg-12'>
                                                        <div className='row align-items-center '>
                                                            <div className='col-xl-12 col-lg-12 col-md-12 pe-0'>
                                                                <div className='inputGroup'>
                                                                    <input className="customform-control weightPlace" placeholder="lb" name="weight" type="number" value={dimensions?.weight} onChange={(e) => { handledimensions(e) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="deatilsHead mb-2 fw-normal">Product Dimensions
                                                    <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                </label>
                                                <ul className="dimensionsGroup mb-3">
                                                    <li className='dimensionsGroupItems'>
                                                        <label className='textinner_ mb-1'><i>Length</i></label>
                                                        <input type="number" className="form-control" placeholder="length" name="length" aria-describedby="button-addon1" value={dimensions?.length} onChange={(e) => { handledimensions(e) }} />
                                                    </li>
                                                    <li className='dimensionsGroupItems'>
                                                        <label className='textinner_ mb-1'><i>Breadth</i></label>
                                                        <input type="number" className="form-control" placeholder="breadth" name="breadth" aria-describedby="button-addon1" value={dimensions?.breadth} onChange={(e) => { handledimensions(e) }} />
                                                    </li>
                                                    <li className='dimensionsGroupItems'>
                                                        <label className='textinner_ mb-1'><i>Height</i></label>
                                                        <input type="number" className="form-control" placeholder="height" name="height" aria-describedby="button-addon1" value={dimensions?.height} onChange={(e) => { handledimensions(e) }} />
                                                    </li>
                                                    <li className='dimensionsGroupItems'>
                                                        <span className='linkText'>in</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>}

                                <div className="row">
                                    {/* <div className='col-md-12'>
                                        <div className='outerArea mt-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="skipPOS"
                                                            checked={skipPosDetails}
                                                            onChange={(e) => { handleSkipPOSDetails(e) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Skip Details Screen in POS</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-2 h6'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>
                                        </div>
                                    </div> */}

                                    <div className='col-md-12'>
                                        <div className='outerArea mt-3'>
                                            <h2 className='headingBlue mb-4'>
                                                Select Address
                                            </h2>
                                            <select autoComplete="new-password" className='customform-control  selectControl form-select' value={addressId} onChange={(e) => setAddressId(e.target.value)}>

                                                {allAddress?.map((val, index) => {
                                                    return (
                                                        <option value={val?.id} key={index}>{val?.format_address}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <ProductPrice activeData={activeData} activePos={activePos} selectedPrices={prices} activeBtoc={activeBtoc} activeBtob={activeBtob} activeOnlineStore={activeOnlineStore} prices={(e) => handleprices(e)} costPrice={costPrice} />
                                {/* <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                    <h2 className='headingBlue mb-4'>
                                        Options
                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                    </h2>
                                    <button className='addOptions' onClick={() => { setModalDetail({ show: true, flag: "AddOptions" }); setKey(Math.random()); }}>
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" className='me-2' xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.25021 0C9.43145 7.6429e-06 9.60655 0.0656426 9.74314 0.184767C9.87973 0.303891 9.96856 0.468446 9.99321 0.648L10.0002 0.75L10.0012 8H17.2542C17.4442 8.00006 17.6272 8.07224 17.766 8.20197C17.9049 8.3317 17.9893 8.5093 18.0022 8.69888C18.0152 8.88846 17.9557 9.07589 17.8358 9.2233C17.7159 9.37071 17.5445 9.4671 17.3562 9.493L17.2542 9.5H10.0012L10.0032 16.75C10.0029 16.9399 9.93054 17.1227 9.80073 17.2613C9.67092 17.4 9.49334 17.4842 9.30383 17.4971C9.11433 17.5099 8.92702 17.4503 8.77972 17.3304C8.63242 17.2105 8.5361 17.0392 8.51021 16.851L8.50321 16.749L8.50121 9.5H1.25221C1.06219 9.49994 0.879272 9.42776 0.740421 9.29803C0.60157 9.1683 0.517139 8.9907 0.504187 8.80112C0.491235 8.61154 0.550728 8.42411 0.670645 8.2767C0.790563 8.12929 0.961963 8.0329 1.15021 8.007L1.25221 8H8.50221L8.50021 0.75C8.50021 0.551088 8.57923 0.360322 8.71988 0.21967C8.86054 0.0790175 9.0513 0 9.25021 0Z" fill="#275AFF" />
                                        </svg>
                                        Add Options
                                    </button>

                                    {optionsList?.length > 0 ? <>
                                        <div className='row mt-4'>
                                            <div className='col-md-12'>
                                                <h4 className='deatilsHead mb-2 fw-normal'>Varients</h4>
                                            </div>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className='col-md-6'>
                                                <input
                                                    type="checkbox"
                                                    className='checkBox'
                                                    id="view1"
                                                    checked={allSelectChecked}
                                                    onChange={(e) => { handleSelectAllChange(e) }}
                                                />
                                                <label htmlFor="view1" className='storeText'>{optionsList?.length} variations</label>
                                            </div>
                                            <div className='col-md-6 text-end'>
                                                <div className='justify-content-end variantBtn'>
                                                    <div className='imgFileBox me-3'>
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            accept="image/*"
                                                            className="file-input__input"
                                                            onChange={(e) => { uploadImage(e) }}
                                                            required=""
                                                        />
                                                        <label className="varientImgBtn" htmlFor="file-input">
                                                            <img src={imgGallery1} alt="image" className='me-2 img-fluid imgGalleryBtn' />
                                                            Image Upload
                                                        </label>
                                                    </div>
                                                    <button className='varientImgBtn' onClick={() => { setModalDetail({ show: true, flag: "AddOptions" }); setKey(Math.random()); }}>Manage</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='table-responsive'>
                                            <table className='table variantTables mt-3'>
                                                <tbody>
                                                    {optionsList?.map((item, index) => {
                                                        return (
                                                            <tr className='variantTable' key={index}>
                                                                <td className='variantItems'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className="rolecheckbox_">
                                                                            <input
                                                                                className="me-2"
                                                                                type="checkbox"
                                                                                id={`view1${index}`}
                                                                                name={item?.id}
                                                                                value={item?.id}
                                                                                checked={checkedOptionList[index]}
                                                                                onChange={() => handleCheckbox(index)}
                                                                            />
                                                                            <label className="contentheadtext_" htmlFor="view1"></label>
                                                                        </div>
                                                                        <div className='d-flex'>
                                                                            <div className='imgFileBox me-3'>
                                                                                <input
                                                                                    type="file"
                                                                                    name="file"
                                                                                    id="file"
                                                                                    accept="image/*"
                                                                                    className="file-input__input"
                                                                                    onChange={(e) => { uploadImage(e) }}
                                                                                />


                                                                                <label className="" htmlFor="file-input">
                                                                                    <span className='galleryCircle me-2'>
                                                                                        {loading && checkedValue?.includes(index) ?
                                                                                            <span className="spinner-border spinner-border-sm"></span>
                                                                                            :
                                                                                            <img src={item.image ? item.image : galleryImg} alt='' className='' />
                                                                                        }
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            {item.attribute_values.map((ele, index) => {
                                                                                return (
                                                                                    <div className='variantItemsContent' key={index}>

                                                                                        <p className='subtextDark_' >{ele?.attribute_value_name}</p>

                                                                                    </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className='variantItems'>
                                                                    <p className='textinner_'>{item?.price ? item?.price : "$0.00"}</p>
                                                                </td>

                                                                <td className='variantItems'>
                                                                    <p className='textinner_'> {item?.upc ? item?.upc : "0"}</p>
                                                                </td>
                                                                <td className='variantItems'>
                                                                    <div className=' d-flex align-items-center justify-content-between'>
                                                                        <p className='textinner_'>{item?.weight ? item?.weight : "0"}</p>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                        : <></>}

                                </div> */}
                            </div>
                            {/* End ProductDetails Area */}
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids=
                {
                    modalDetail.flag === "AddOptions" ? "addOptions" :
                        modalDetail.flag === "AddAttributesModal" ? "addAttributesModal" : ""
                }
                size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
                child=
                {
                    modalDetail.flag === 'AddOptions' ? <AddOptionsModal id={selectedProductDtails?.service_id} close={() => handleOnCloseModal()} options={(attributes, data, value) => handleOptions(attributes, data, value)} combinations={combinations} selectedAttributesValue={selectedAttributesValue} handleModalChange={(e) => handleModalChange(e)} />
                        : modalDetail.flag === 'AddAttributesModal' ? <AddAttributesModal close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                            : modalDetail.flag === 'createVariant' ? <CreateOptionModal close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} selectedProductDtails={selectedProductDtails}/>
                                : <></>
                }
                header={
                    <>
                        {modalDetail.flag === 'AddOptions'
                            ?
                            <>
                                <h3 className='subheading_ mb-2'>Variants manage</h3>
                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </>
                            :
                            <>
                                <h3 className='subheading_ mb-2'>Add custom attribute</h3>
                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </>
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )
}

export default EditProductSupply;