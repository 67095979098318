import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { selectLoginAuth } from '../auth/authSlice';
import { imageUploadAsync } from '../message/messageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FileUploader } from "react-drag-drop-files";
import { deleteIcon, imgGallery1 } from '../../../utilities/images';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const VariantImageUpload = (props) => {
    const [image, setImage] = useState(props?.selectedVariants?.image ? props?.selectedVariants?.image : "");
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false);

    const handleChange = (file) => {
        uploadImage(file)
    };


    console.log(image, "dddddddddddddddddddddddddddddddddd");

    const uploadImage = (image) => {

        let formData = new FormData();

        // Object.values(image).forEach((val, index) => {
        formData.append(`file`, image);
        // });

        let params = {
            file: formData,
            token: auth?.payload?.token
        }
        setLoading(true)
        dispatch(imageUploadAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                console.log(obj, "sasasaas");
                setImage(obj?.payload[0]?.filePath)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }

            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const deleteImage = (item) => {
        //     const filterImage = image.filter(image => image.filePath != item);
        //     setImage(filterImage)
        // }

        setImage("")
    }

    const handleSave = () => {
        let result = props?.variantOptionList
        result = result.map(variant => {
            return {
                ...variant,
                image: props?.selectedVariants.includes(variant.variant_name) ? image : variant.image
            };
        });
        props?.setVariantOptionList(result)
        props?.close()
    }


    return (
        <div className='variantDetails_'>
            <div className='editVarientSection'>
                <div className='ModalvarientSection'>
                    <div className='uploadBox_ mt-4 text-start'>
                        <div id='dropzone'>
                            <FileUploader
                                multiple={false}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                children={
                                    <div >
                                        <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} />
                                        <label htmlFor="file">
                                            <span className="file-button uploadImages_">
                                                <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" />
                                                <div className='dropText ms-3'>
                                                    <span className='textinner_'>Drop your files here or</span>
                                                    <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                                </div>
                                            </span>
                                        </label>
                                    </div>
                                }
                            />
                        </div>
                        {loading ?
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                            </> : <>

                                {image && <img className="w3-round" src={image} ></img>}
                                {image && <img className='deleteIconn' src={deleteIcon} onClick={(e) => deleteImage()} />}
                            </>
                        }


                    </div>
                </div>
                <div className=' modalfooterBtn'>
                    <button className='me-4 w-100 btnDiscard' type="button" onClick={() => props?.close()}>Discard</button>
                    <button className='w-100 btnSave' type="button" onClick={() => handleSave()} >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VariantImageUpload