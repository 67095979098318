import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loginApi,
  forgetPassword,
  verifyResetPassword,
  resetPassword,
  getServicesApi,
  sigupApi,
  verifyOtp,
  resendOtp,
  getAddressApi,
  sendPhoneOtpApi,
  verifyPhoneOtpApi,
  sendEmailOtp,
  verifyEmailOtp,
  resendOtpApi,
  createPosUserApi,
  getUserByIdApi,
  deleteAddressApi,
  checkPosUserApi,
  createRoleApi,
  getPrivilegesApi,
  getRoleApi,
  getPosUsers,
  exchangeTokenApi,
  getSinglePosUser,
  getSingleInvitationPosUser,
  updatPosUserApi,
  updateRoleApi,
  deleteRoleApi,
  deletePosUserApi,
  updateMerchantApi,
  verifyQrCodeApi,
  setPasswordApi,
  updateCustomerApi,
  updateInvitedCustomerApi,
  sendOtpPhoneApi,
  validatePhoneApi,
  sendOtpEmailApi,
  validateEmailApi,
} from "./authApi";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  signup: null,
  register: null,
  forgetPassword: null,
  verifyResetPassword: null,
  resetPassword: null,
  addressList: null,
  verifyTwoFactor: false,
  posUser: null,
  status: "idle",
};

export const loginAsync = createAsyncThunk("auth/loginApi", async (data) => {
  const response = await loginApi(data);
  return response.data;
});
export const exchangeTokenApiAsync = createAsyncThunk(
  "auth/exchangeTokenApi",
  async (data) => {
    const response = await exchangeTokenApi(data);
    return response.data;
  }
);
export const forgetPasswordAsync = createAsyncThunk(
  "auth/forgetPassword",
  async (data) => {
    const response = await forgetPassword(data);
    return response.data;
  }
);
export const verifyPasswordAsync = createAsyncThunk(
  "auth/verifyResetPassword",
  async (data) => {
    const response = await verifyResetPassword(data);
    return response.data;
  }
);
export const resetPasswordAsync = createAsyncThunk(
  "auth/resetPassword",
  async (data) => {
    const response = await resetPassword(data);
    return response.data;
  }
);
export const getServicesApiAsync = createAsyncThunk(
  "auth/getServicesApi",
  async (data) => {
    const response = await getServicesApi(data);
    return response.data;
  }
);
export const sigupApiAsync = createAsyncThunk("auth/sigupApi", async (data) => {
  const response = await sigupApi(data);
  return response.data;
});
export const verifyOtpAsync = createAsyncThunk(
  "auth/verifyOtp",
  async (data) => {
    const response = await verifyOtp(data);
    return response.data;
  }
);
export const resendOtpAsync = createAsyncThunk(
  "auth/resendOtp",
  async (data) => {
    const response = await resendOtp(data);
    return response.data;
  }
);
export const getAddressApiAsync = createAsyncThunk(
  "auth/getAddressApi",
  async (data) => {
    const response = await getAddressApi(data);
    return response.data;
  }
);
export const deleteAddressApiAsync = createAsyncThunk(
  "auth/deleteAddressApi",
  async (data) => {
    const response = await deleteAddressApi(data.tokenData, data.postData);
    return response.data;
  }
);
export const sendPhoneOtpApiAsync = createAsyncThunk(
  "auth/sendPhoneOtpApi",
  async (data) => {
    const response = await sendPhoneOtpApi(data);
    return response.data;
  }
);
export const verifyPhoneOtpApiAsync = createAsyncThunk(
  "auth/verifyPhoneOtpApi",
  async (data) => {
    const response = await verifyPhoneOtpApi(data);
    return response.data;
  }
);
export const sendEmailOtpAsync = createAsyncThunk(
  "auth/sendEmailOtp",
  async (data) => {
    const response = await sendEmailOtp(data);
    return response.data;
  }
);
export const verifyEmailOtpAsync = createAsyncThunk(
  "auth/verifyEmailOtp",
  async (data) => {
    const response = await verifyEmailOtp(data);
    return response.data;
  }
);
export const resendOtpApiAsync = createAsyncThunk(
  "auth/resendOtpApi",
  async (data) => {
    const response = await resendOtpApi(data);
    return response.data;
  }
);
export const createPosUserApiAsync = createAsyncThunk(
  "auth/createPosUserApi",
  async (data) => {
    const response = await createPosUserApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getUserByIdApiAsync = createAsyncThunk(
  "auth/getUserByIdApi",
  async (data) => {
    const response = await getUserByIdApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const checkPosUserAsync = createAsyncThunk(
  "auth/checkPosUserApi",
  async (data) => {
    const response = await checkPosUserApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const createRoleApiAsync = createAsyncThunk(
  "auth/createRoleApi",
  async (data) => {
    const response = await createRoleApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const updateRoleApiAsync = createAsyncThunk(
  "auth/updateRoleApi",
  async (data) => {
    const response = await updateRoleApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const deleteRoleApiAsync = createAsyncThunk(
  "auth/deleteRoleApi",
  async (data) => {
    const response = await deleteRoleApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getPrivilegesApiAsync = createAsyncThunk(
  "auth/getPrivilegesApi",
  async () => {
    const response = await getPrivilegesApi();
    return response.data;
  }
);
export const getRoleApiAsync = createAsyncThunk(
  "auth/getRoleApi",
  async (data) => {
    const response = await getRoleApi(data);
    return response.data;
  }
);
export const getPosUsersAsync = createAsyncThunk(
  "auth/getPosUsers",
  async (data) => {
    const response = await getPosUsers(data.postData, data.tokenData);
    return response.data;
  }
);
export const getSinglePosUserAsync = createAsyncThunk(
  "auth/getSinglePosUser",
  async (data) => {
    const response = await getSinglePosUser(data.postData, data.tokenData);
    return response.data;
  }
);
export const getSingleInvitationPosUserAsync = createAsyncThunk(
  "auth/getSingleInvitationPosUser",
  async (data) => {
    const response = await getSingleInvitationPosUser(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);

export const updatPosUserApiAsync = createAsyncThunk(
  "auth/updatPosUserApi",
  async (data) => {
    const response = await updatPosUserApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const deletePosUserApiAsync = createAsyncThunk(
  "auth/deletePosUserApi",
  async (data) => {
    const response = await deletePosUserApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const updateMerchantApiAsync = createAsyncThunk(
  "auth/updateMerchantApi",
  async (data) => {
    const response = await updateMerchantApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const updateCustomerApiAsync = createAsyncThunk(
  "auth/updateCustomerApiupdateCustomerApi",
  async (data) => {
    const response = await updateCustomerApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const updateInvitedCustomerApiAsync = createAsyncThunk(
  "auth/updateInvitedCustomerApi",
  async (data) => {
    const response = await updateInvitedCustomerApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const verifyQrCodeAsync = createAsyncThunk(
  "systemConfig/verifyQrCodeApi",
  async (data) => {
    const response = await verifyQrCodeApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const setPasswordApiAsync = createAsyncThunk(
  "systemConfig/setPasswordApi",
  async (data) => {
    const response = await setPasswordApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const sendOtpPhoneApiAsync = createAsyncThunk(
  "systemConfig/sendOtpPhoneApi",
  async (data) => {
    const response = await sendOtpPhoneApi(data);
    return response.data;
  }
);
export const validatePhoneApiAsync = createAsyncThunk(
  "systemConfig/validatePhoneApi",
  async (data) => {
    const response = await validatePhoneApi(data);
    return response.data;
  }
);
export const sendOtpEmailApiAsync = createAsyncThunk(
  "systemConfig/sendOtpEmailApi",
  async (data) => {
    const response = await sendOtpEmailApi(data);
    return response.data;
  }
);
export const validateEmailApiAsync = createAsyncThunk(
  "systemConfig/validateEmailApi",
  async (data) => {
    const response = await validateEmailApi(data);
    return response.data;
  }
);

//Update user email and phone

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.signup = null;
      state.register = null;
      state.verifyTwoFactor = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.status = "idle";
      })
      .addCase(loginAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null;
      })
      .addCase(updateMerchantApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMerchantApiAsync.fulfilled, (state, { payload }) => {
        state.user.payload.user.user_profiles = payload.payload;
        state.status = "idle";
      })
      .addCase(updateMerchantApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null;
      })
      .addCase(exchangeTokenApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(exchangeTokenApiAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.status = "idle";
      })
      .addCase(exchangeTokenApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null;
      })
      .addCase(setPasswordApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setPasswordApiAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.status = "idle";
      })
      .addCase(setPasswordApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null;
      })
      .addCase(sigupApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sigupApiAsync.fulfilled, (state, { payload }) => {
        state.signup = payload;
        state.status = "idle";
      })
      .addCase(sigupApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.signup = null;
      })
      .addCase(forgetPasswordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgetPasswordAsync.fulfilled, (state, { payload }) => {
        state.forgetPassword = payload;
        state.status = "idle";
      })
      .addCase(forgetPasswordAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.forgetPassword = null;
      })
      .addCase(verifyPasswordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyPasswordAsync.fulfilled, (state, { payload }) => {
        state.verifyResetPassword = payload;
        state.status = "idle";
      })
      .addCase(verifyPasswordAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.verifyResetPassword = null;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordAsync.fulfilled, (state, { payload }) => {
        state.resetPassword = payload;
        state.status = "idle";
      })
      .addCase(resetPasswordAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.resetPassword = null;
      })
      .addCase(verifyOtpAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtpAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.status = "idle";
      })
      .addCase(verifyOtpAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null;
      })
      .addCase(getAddressApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAddressApiAsync.fulfilled, (state, { payload }) => {
        state.addressList = payload;
        state.status = "idle";
      })
      .addCase(getAddressApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.addressList = null;
      })
      .addCase(verifyQrCodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyQrCodeAsync.fulfilled, (state, { payload }) => {
        state.verifyTwoFactor = true;
        state.status = "idle";
      })
      .addCase(verifyQrCodeAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.verifyTwoFactor = false;
      })
      .addCase(getPosUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPosUsersAsync.fulfilled, (state, { payload }) => {
        state.posUser = payload;
        state.status = "idle";
      })
      .addCase(getPosUsersAsync.rejected, (state, { error }) => {
        state.status = "idle";
      });
  },
});

export const { logout } = authSlice.actions;
export const selectLoginAuth = (state) => state.auth.user;
export const selectRegisterAuth = (state) => state.auth.user;
export const selectAddressList = (state) => state.auth.addressList;
export const selectVerifyTwoFactor = (state) => state.auth.verifyTwoFactor;
export const selectSignUpAuth = (state) => state.auth.signup;
export const selectedPosUserData = (state) => state.auth.posUser;

export default authSlice.reducer;
