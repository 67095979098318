import React, { useEffect, useState } from 'react'
import VariantDetails from './variantDetails'
import VariantmanageStocks from './variantmanageStocks'
import VariantAttributes from './variantAttributes'

const EditvariantModal = (props) => {
  const [activeTab, setActiveTab] = useState("VariantDetails");

  useEffect(() => {
    if (props?.isStock) {
      setActiveTab("VariantmanageStocks")
    }
  }, [])

  return (
    <div className='editvariantModal_'>
      <div className='topvarientsTabs_'>
        <button className={`tabsBoxes_ ${activeTab === 'VariantDetails' ? 'active' : ""}`}
          onClick={() => setActiveTab('VariantDetails')}>Details</button>
        <button className={`tabsBoxes_ ${activeTab === 'VariantmanageStocks' ? 'active' : ""}`}
          onClick={() => setActiveTab('VariantmanageStocks')}>Manage Stocks</button>
        <button className={`tabsBoxes_ ${activeTab === 'VariantAttributes' ? 'active' : ""}`}
          onClick={() => setActiveTab('VariantAttributes')}>Custom Attributes</button>
      </div>
      <div className='variantareaContainer'>
        {activeTab == 'VariantDetails'
          ?
          <VariantDetails costPrice={props?.costPrice} close={() => props?.close()} variantOptionList={props?.variantOptionList} selectedVariant={props?.selectedVariant} selectedVariantIndex={props?.selectedVariantIndex} setVariantOptionList={props?.setVariantOptionList} />
          : activeTab == "VariantmanageStocks" ?
            <VariantmanageStocks close={() => props?.close()} variantOptionList={props?.variantOptionList} selectedVariant={props?.selectedVariant} selectedVariantIndex={props?.selectedVariantIndex} setVariantOptionList={props?.setVariantOptionList} />
            :
            activeTab == "VariantAttributes" ?
              <VariantAttributes close={() => props?.close()} variantOptionList={props?.variantOptionList} selectedVariant={props?.selectedVariant} selectedVariantIndex={props?.selectedVariantIndex} setVariantOptionList={props?.setVariantOptionList} />
              :
              ""
        }
      </div>
    </div>
  )
}

export default EditvariantModal