import React, { useEffect, useState } from 'react'
import { selectLoginAuth } from '../auth/authSlice';
import { imageUploadAsync } from '../message/messageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { imgGallery, modalCancel } from '../../../utilities/images';
import CustomModal from '../../components/shared/CustomModal'
import ImgUploadedModal from './imgUploadedModal'
import CustomLoader from "../../components/shared/CustomLoader"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import BrowseImgUpload from '../product/browseImgUpload';

const ProductImageAdd = (props) => {
    const location = useLocation();
    const selectedProductDtails = location?.state;
    const newArrayWithFilePath = selectedProductDtails?.product_images?.map(item => {
        return { filePath: item.url };
    });
    let imageData
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(Math.random());
    const [selectedFlag, setSelectedFlag] = useState("")
    const [imageUrl, setImageUrl] = useState([])
    const [mainImage, setMainImage] = useState("")
    const [differentAngelsImage, setDifferentAngelsImage] = useState("")
    const [closeUpImage, setCloseUpImage] = useState("")
    const [inUseImage, setInUseImage] = useState("")
    const [styleImage, setStyleImage] = useState("")
    const auth = useSelector(selectLoginAuth)
    const [indexVal, setIndexVal] = useState("")
    const [fileBlob, setFileBlob] = useState();
    const toastId = React.useRef(null);
    const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
    const dispatch = useDispatch()
    const handleChange = (file) => {
        const image = file
        uploadImage(image)
        //setFile(file);
    };

    const handleUserProfile = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            type: flag,
        });
        setKey(Math.random());
    };

    const handleImageRemove = (e) => {
        var temp = imageUrl.filter((item, index) => index !== e)
        setImageUrl(temp)
        setMainImage(temp[0]?.filePath ?? "")

        imageData = {
            "image": temp[0]?.filePath ?? "",
            "images": temp?.map(val => val.filePath)
        }

        props?.imageData(imageData)
        setCloseUpImage("")
        setInUseImage("")
        setDifferentAngelsImage("")
        setStyleImage("")
    }

    const cropImageFunc = (file, blobData) => {

        let data = {
            filePath: file,
            mimeType: blobData?.type,
            size: blobData?.size,
            originalname: blobData?.name
        }
        let result = [...imageUrl]
        result[indexVal] = data
        setImageUrl(result)
    }
    const uploadImage = (image) => {
        let formData = new FormData();
        let fileBlobs = [];
        Object.values(image).forEach((val, index) => {
            formData.append(`file`, val);
            fileBlobs.push(val);
            setFileBlob(fileBlobs);
        });

        let params = {
            file: formData,
            token: auth?.payload?.token
        }
        if (image?.length <= 10) {
            setLoading(true)
            dispatch(imageUploadAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    setMainImage(obj?.payload[0]?.filePath)
                    imageData = {
                        "image": obj?.payload[0]?.filePath,
                        "images": [...obj?.payload?.map(val => val.filePath), ...imageUrl.map(item => item.filePath)]
                    }
                    props.imageData(imageData)
                    setImageUrl([...obj?.payload, ...imageUrl])
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
        else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please upload image less then 10")
            }
        }

    }

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleSelectedImage = (e) => {
        const imageurls = imageUrl?.map(val => val.filePath)
        if (selectedFlag === "mainImage") {
            setMainImage(e)
            imageData = {
                "image": e,
                "images": imageurls
            }
            props.imageData(imageData)
        }
        if (selectedFlag === "styleImage") {
            setStyleImage(e)
        }
        if (selectedFlag === "differentAngelsImage") {
            setDifferentAngelsImage(e)
        }
        if (selectedFlag === "closeUpImage") {
            setCloseUpImage(e)
        }
        if (selectedFlag === "inUseImage") {
            setInUseImage(e)
        }
    }


    useEffect(() => {
        setImageUrl(selectedProductDtails?.product_images ? newArrayWithFilePath : []);
        setMainImage(selectedProductDtails?.image ? selectedProductDtails?.image : "");
    }, [])


    return (
        <div className='addProductContainer'>
            {loading ?
                //    <CustomLoader/>
                <span className="spinner-border inner-spin spinner-border-sm"></span>
                :
                <div className='row'>
                    <div className='col-md-2'>
                        <div onClick={() => { handleUserProfile("browseImageUpload") }} className="inputfile-box active">
                            {/* <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" accept="image/png, image/jpeg" multiple /> */}
                            <label htmlFor="file">
                                <span id="file-name" className="file-box d-none">Choose File</span>
                                <div className="file-button">
                                    <img src={imgGallery} width="25" id="profileImagePreview1" alt="" className="beforeUpload" />
                                    <p className='subtextSmall_'>Drop your files here</p>
                                    <p className='linkTxt'>
                                        <u><b>Browse</b></u>
                                    </p>
                                </div>
                            </label>
                        </div>
                        {/* <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                            classes="addUploadProduct"
                            multiple={true}
                            children={
                                <div className="inputfile-box active">
                                    <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" a    ccept="image/png, image/jpeg" multiple />
                                    <label htmlFor="file">
                                        <span id="file-name" className="file-box d-none">Choose File</span>
                                        <div className="file-button">
                                            <img src={imgGallery} width="25" id="profileImagePreview1" className="beforeUpload" />
                                            <p className='subtextSmall_'>Drop your files here</p>
                                            <p className='linkTxt' onClick={() => {
                                                handleUserProfile("browseImageUpload")
                                            }}>
                                                <u><b>Browse</b></u>
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            }
                        /> */}
                    </div>
                    <div className='col-md-2'>
                        <div className={mainImage ? 'fillUploadedFrames imgUploaded' : 'fillUploadedFrames'}
                            onClick={() => {
                                setModalDetail({ show: true, flag: "ImageUpload" });
                                setKey(Math.random());
                                setSelectedFlag("mainImage")
                            }}
                        >
                            {mainImage ?
                                <img src={mainImage} className='uploadedImage' alt='UploadImg' />
                                : <></>}
                            {/* <p className='subtextSmall_ mt-auto'>Main image</p> */}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={differentAngelsImage ? 'fillUploadedFrames imgUploaded' : 'fillUploadedFrames'}
                            onClick={() => {
                                setModalDetail({ show: true, flag: "ImageUpload" });
                                setKey(Math.random());
                                setSelectedFlag("differentAngelsImage")
                            }}>
                            {differentAngelsImage ? <img src={differentAngelsImage} className='uploadedImage' alt='UploadImg' /> : <></>}
                            {/* <p className='subtextSmall_ mt-auto'>Different angels </p> */}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={closeUpImage ? 'fillUploadedFrames imgUploaded' : 'fillUploadedFrames'}
                            onClick={() => {
                                setModalDetail({ show: true, flag: "ImageUpload" });
                                setKey(Math.random());
                                setSelectedFlag("closeUpImage")
                            }}>
                            {closeUpImage ? <img src={closeUpImage} className='uploadedImage' alt='UploadImg' /> : <></>}
                            {/* <p className='subtextSmall_ mt-auto'>Close-Up</p> */}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={inUseImage ? 'fillUploadedFrames imgUploaded' : 'fillUploadedFrames'}
                            onClick={() => {
                                setModalDetail({ show: true, flag: "ImageUpload" });
                                setKey(Math.random());
                                setSelectedFlag("inUseImage")
                            }}>
                            {inUseImage ? <img src={inUseImage} className='uploadedImage' alt='UploadImg' /> : <></>}
                            {/* <p className='subtextSmall_ mt-auto'>In use</p> */}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={styleImage ? 'fillUploadedFrames imgUploaded' : 'fillUploadedFrames'}
                            onClick={() => {
                                setModalDetail({ show: true, flag: "ImageUpload" });
                                setKey(Math.random());
                                setSelectedFlag("styleImage")
                            }}>
                            {styleImage ? <img src={styleImage} className='uploadedImage' alt='UploadImg' /> : <></>}
                            {/* <p className='subtextSmall_ mt-auto'>Style</p> */}
                        </div>
                    </div>
                </div>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "ImageUpload" ? "imageUpload" : modalDetail.flag === "browseImageUpload" ? "imgUploadBrowse" : ""}
                size={modalDetail.flag === "ImageUpload" ? "xl" : "md"}
                child={modalDetail.flag === "browseImageUpload" ? (
                    <BrowseImgUpload indexId={indexVal} indexVal={setIndexVal} fileBlob={fileBlob} setCroppedImage={(e, data) => cropImageFunc(e, data)} setMainImage={setMainImage} imageData={(e) => props.imageData(e)} loading={loading} uploadImageHandle={(e) => uploadImage(e)} selectedImage={(e) => handleSelectedImage(e)} images={imageUrl} setImageUrl={setImageUrl} setDifferentAngelsImage={setDifferentAngelsImage} setCloseUpImage={setCloseUpImage} setInUseImage={setInUseImage} setStyleImage={setStyleImage} close={() => handleOnCloseModal()} />
                ) : modalDetail.flag === 'ImageUpload' ? <ImgUploadedModal close={() => handleOnCloseModal()} selectedImage={(e) => handleSelectedImage(e)} imageData={imageUrl} removeImage={(e) => { handleImageRemove(e) }} />
                    : ""
                }
                header={
                    modalDetail.flag === "browseImageUpload" ?
                        <>
                            <h4 className='modalHeading_'>Image Upload</h4>
                            <p className='modal_cancel'>
                                <img style={{ cursor: "pointer" }} onClick={handleOnCloseModal} src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                            </p>
                        </>
                        :
                        ''
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default ProductImageAdd