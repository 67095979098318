import React, { useState } from 'react'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
const VariantAttributes = (props) => {
    const [tags, setTags] = useState(props?.selectedVariant?.custom_attributes? props?.selectedVariant?.custom_attributes: [])

    const handleSubmit = () => {
        if(tags?.length > 0) {
            let result = props?.variantOptionList
            result[props?.selectedVariantIndex].custom_attributes = tags
            props?.setVariantOptionList(result)
        }
        props?.close()
    }
    return (
        <div className='variantAttributes_'>
            <div className='variantsContainer_'>
                <div className='optionName_ mt-0'>
                    <span className='label-text'>Custom Attributes</span>
                    <TagsInput
                        name="tags"
                        value={tags}
                        onChange={e => setTags(e)} />
                </div>
                <p className='writetext_ mt-4'>Write option name and use <strong className='enterbox_'> Enter </strong></p>

            </div>

            <div className=' modalfooterBtn '>
                <button className='me-4 w-100 btnDiscard' type="button" onClick={() => props?.close()}>Discard</button>
                <button className='w-100 btnSave' type="button" onClick={() => handleSubmit()}>
                    Save
                </button>
            </div>
        </div>
    )
}

export default VariantAttributes