import React from 'react';
import { Route } from 'react-router-dom';
import Authenticator from './authenticator';

import DashboardLayout from '../components/shared/DashboardLayout';
import { useSelector } from 'react-redux';
import { selectLoginAuth } from '../containers/auth/authSlice';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => 
{
  const auth = useSelector(selectLoginAuth)
  console.log(auth?.payload?.token ,"token dattataa")

return(
  <Route {...rest} render={props => (
    auth?.payload?.token ?
    (
    <Authenticator isAuthenticationRequired={true} {...props}>
      <DashboardLayout>
        <Component {...props} />
      </DashboardLayout>
    </Authenticator>)
    :
    <Redirect to="/" />
  )} />
)
}

export default PrivateRoute;
