import React, { useEffect, useState } from 'react'
import { visaImg } from '../../../../utilities/images'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import CustomModal from '../../../components/shared/CustomModal'
import PlansModal from '../../scalePlan/plansModal'
import ViewPlanModal from './ViewPlanModal'
import { getMerchantActiveSubscriptionAsync } from '../../dashboard/dashboardSlice'
import { selectLoginAuth } from '../../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment-timezone'



const PlanSubscriptions = () => {
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const [selectedPlan, setSelectedPlan] = useState("")
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    console.log(selectedPlan, "selected plans")
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const details = (data) => {
        setSelectedPlan(data)
    }
    const getMerchantActiveSubscription = () => {
        setLoading(true)
        dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setSelectedPlan(obj?.payload)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    console.log(selectedPlan,"selected plan")
    useEffect(() => {
        getMerchantActiveSubscription()
    }, [])
    return (

        <>
            <DashboardNavbar title="Plans & Subscriptions" backShow={false} />
            <div className='plansDetails'>
                <div className='container-fluid'>
                    <div className='row'>
                        {
                            selectedPlan?.length > 0 ?
                            <div className='mainCatalog plans_ mb-3'>
                            <div className='col-md-12'>
                                <h4 className='successMain'>Business Plan</h4>
                                <div className='mainBussiness'>
                                    <div className='commonBussiness'>
                                        <div className='planBasic' onClick={() => {
                                            setModalDetail({ show: true, flag: "viewPlan" });
                                            setKey(Math.random());
                                        }} >
                                            <h4 className='inActiveHead mt-4 mb-4'>{selectedPlan[0]?.plan_id?.name}</h4>
                                            <div className='bussinesDetails'>
                                                <h4 className='monshead14'>Your Plan includes app </h4>
                                                {
                                                    selectedPlan[0]?.plan_id?.included_apps?.length > 0 ?
                                                        selectedPlan[0]?.plan_id?.included_apps?.map((val, ind) => {
                                                            return (
                                                                <div key={ind}>
                                                                    <h4 className='subtextSmall_ text-start mt-1'>{val}</h4>
                                                                </div>
                                                            )
                                                        }) : <></>
                                                }
                                                {/* <div key={ind} className="customRadio_ text-start">
                                                    <input type="radio" id="test1" checked />
                                                    <label for="test1">{val}</label>
                                                </div> */}
                                                {/* <p className='subtextSmall_'>JOBR B2B</p>
                                                <p className='subtextSmall_'>JOBR B2c </p>
                                                <p className='subtextSmall_'>JOBR Admin</p>
                                                <p className='subtextSmall_'>JOBR POS</p> */}

                                            </div>
                                            <div className='bussinesDetails mt-3 mb-3'>
                                                <h4 className='monshead14 text-start'>Next billing date</h4>
                                                <p className='subtextSmall_'>{moment(selectedPlan[0]?.expiry_date).format("MMM DD, YYYY")} for ${selectedPlan[0]?.amount}USD</p>
                                            </div>
                                            <div className='bussinesDetails mt-3 mb-3'>
                                                <h4 className='monshead14 text-start'>Payment method</h4>
                                                <div className='visaBUssiness'>
                                                    {/* <img src={visaImg} alt='visaImages' className='img-fluid visaIcons' /> */}
                                                    {/* <span className='monshead14'> Visa ending in 2275</span> */}
                                                    <span className='monshead14'> JBR coin</span>                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bussinesDetails  mt-3 mb-3'>
                                            <button onClick={() => {
                                                setModalDetail({ show: true, flag: "PickPlan" });
                                                setKey(Math.random());
                                            }} className='backgroundFillbtn'>Change Plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                            :<div className='mainCatalog plans_ mb-3'>
                                <h4 className='text-center'>No Active Plan Found</h4>
                                </div>
                        }


                        {/* <div className='mainCatalog plans_ mb-3'>
                            <div className='col-md-12'>
                                <h4 className='successMain'>Payroll Subscription</h4>
                                <div className='mainBussiness'>

                                    <div className='commonBussiness'>
                                        <h4 className='inActiveHead mt-4 mb-4'>Small Team</h4>

                                        <div className='bussinesDetails mt-3 mb-3'>
                                            <h4 className='monshead14 text-start'>Next billing date</h4>
                                            <p className='subtextSmall_'>March 2, 2023 for $1.00 USD</p>
                                        </div>
                                        <div className='bussinesDetails mt-3 mb-3'>
                                            <h4 className='monshead14 text-start'>Payment method</h4>
                                            <div className='visaBUssiness'>
                                                <img src={visaImg} alt='visaImages' className='img-fluid visaIcons' />
                                                <span className='monshead14'> Visa ending in 2275</span>
                                            </div>
                                        </div>
                                        <div className='bussinesDetails  mt-3 mb-3'>
                                            <button className='upgradeBtn'>Upgrade</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='mainCatalog plans_ mb-3'>
                            <div className='col-md-12'>
                                <h4 className='successMain'>Payroll Subscription</h4>
                                <div className='bussinessBox'>
                                    <button className='upgradeBtn'> Buy a payroll Plan</button>
                                    <h4 className='monshead14  mt-3 '>You have no active subscriptions</h4>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>

            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "PickPlan" ? "pickPlan" :
                        modalDetail.flag === "viewPlan" ? "ViewPlan" :
                            modalDetail.flag === "FindProduct" ? "findProduct" : ""
                }
                size={
                    modalDetail.flag === "PickPlan" ? "xl" :
                        modalDetail.flag === "FindProduct" ? "md" : "lg"
                }
                child={modalDetail.flag === "viewPlan" ? <ViewPlanModal close={() => handleOnCloseModal()} selectedPlan={selectedPlan} loading={loading} /> :
                    modalDetail.flag === 'PickPlan' ? <PlansModal handleSelectedPlan={(data) => details(data)} close={() => handleOnCloseModal()} /> :
                        <></>
                }
                header={
                    <>
                        {modalDetail.flag === 'PickPlan'
                            ?
                            <>
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                        <h3 className='heading_ mb-2'>Plan that fits your scale</h3>
                                        <p className='mb-0'>Simple, Transparent pricing that grows with you</p>
                                        <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                            <i className="las la-times " aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {/* <h3 className='subheading_ mb-2'>Based on your Store Profile setup</h3> */}
                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times crossBg " aria-hidden="true"></i>
                                </span>
                            </>
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>

    )
}

export default PlanSubscriptions