import React, { useState } from 'react'
import { toast } from 'react-toastify';

const CreatevariationModal = (props) => {

    console.log(props?.variantPairs, "variant pairs");
    const [selectedTags, setSelectedTags] = useState([]);
    const handleCheckboxChange = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(item => item?.variant_name !== tag?.variant_name));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };


    const handleSelectAll = (e) => {
        console.log(e.target.checked, "dddddddddddddddddddddddd");
        if (e.target.checked) {
            setSelectedTags(props?.variantPairs);
        }
        else {
            setSelectedTags([]);
        }
    };

    const handleSubmit = () => {
        if(selectedTags?.length <= 0){
            toast.error("Please select atleast one option")
            return false
        }
        props?.setVariantOptionList(selectedTags)
        props?.setOptionList(props?.optionListNew)
        props?.close()
    }

    console.log(selectedTags, "selected tags");
    return (
        <>
            <div className='d-flex justify-content-between'>
                <div className='leftheadvarient mt-3'>
                    <span className="crossIcon me-3" onClick={() => props?.close()}>
                        <i className="las la-times" aria-hidden="true"></i>
                    </span>
                    <h3 className='subheading_ mb-2'>Create variations</h3>
                </div>
                <div className='rightheadvarient'>
                    <button className='next_button saveactive' type='button' onClick={() => handleSubmit()}>Create {selectedTags?.length} Variations</button>
                </div>
            </div>

            <div className='createvariationModal'>
                <p className='headerHeading_'>The variations below will be created from your options.</p>
                <div className='varientOptions_'>
                    <div className='checkoptions_'>
                        <div className='innerselct_'>
                            <input type="checkbox" onChange={handleSelectAll} />
                            <label for="option1"> <strong>All Options</strong></label><br />
                        </div>

                        {props?.variantPairs.map((tag, index) => (
                            <div className='innerselct_' key={index}>
                                <input type="checkbox" id="option1" name="option1" value={tag?.variant_name} checked={selectedTags.includes(tag)}
                                    onChange={() => handleCheckboxChange(tag)} />
                                <label for="option1"> <strong>{tag?.variant_name}</strong></label><br />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatevariationModal