import React from 'react'
import { useHistory } from 'react-router-dom';

const AddProductHeader = (props) => {
    console.log(props,"propsokk");
    const history = useHistory()

    const handleSave = () => {
        props?.save()
    }
    return (
        <div className='topHeader_'>
            <div className="productTopStickyHeader_ mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">   
                        <h2 className="innerSubheadNormal">
                            <span onClick={() => history.goBack()}>
                                <i className="las la-angle-left me-3 directionArrow"></i>
                            </span>
                            <span className='headerHeading_ '>Add Product</span>
                        </h2>
                    </div>
                    <div className='col-md-6 text-end'>
                        <button className='discardBtn me-3' type="button" onClick={() => history.goBack()}>
                            Discard
                        </button>
                        <button className='saveBtn ' type="button" onClick={() => handleSave()} disabled={props?.successLoader}>
                            {props?.successLoader && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProductHeader