import { toast } from 'react-toastify';

function formValidator(obj, activePos, activeBtob, activeBtoc) {
    console.log(obj?.prices?.length, "objyyyyy");
    let hasEmptyValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && key !== 'upc' && obj[key] === '') {
                    return true; // Empty value found
                }
            }
        }
        return false; // No empty values found
    }

    const showToast = (message, type) => {
        toast.dismiss(); // Clear all existing toasts
        if (type === 'error') {
            toast.error(message);
            return false
        } else if (type === 'success') {
            toast.success(message);
        }
    };


    console.log(obj?.prices,"price responseeeeeeeeeeeeeeeeeeee");


    const validateData = (data) => {
        let isValid = true;
    
        for (let index = 0; index < data?.length; index++) {
            const item = data[index];
            for (const [key, value] of Object.entries(item)) {
                console.log(key, value, "keykeykey");
                if (!value && key !== "is_available_for_sale" && key !== "prices" && key !== "stock") {
                    showToast(`Value for key '${key}' at row ${index + 1} should not be empty.`, 'error');
                    return false; // Exit the function immediately after showing the error
                }
    
                if (key === "upc") {
                    if (!value || value.length < 11 || value.length > 14) {
                        showToast(`UPC length should be between 11 to 14 digits at row ${index + 1}.`, 'error');
                        return false; // Exit the function immediately after showing the error
                    }
                }
    
                if ((key === "stocks" || key === "prices") && Array.isArray(value) && value.length === 0) {
                    showToast(`'${key}' at row ${index + 1} should not be empty.`, 'error');
                    return false; // Exit the function immediately after showing the error
                }
            }
        }
        
    
        return isValid;
    };


    let filterB2B = obj?.prices?.filter((val) => (val?.price_type == "quantity_base"))
    console.log(filterB2B, "filterB2B");
    let resultB2B = filterB2B?.length > 0 ? hasEmptyValues(filterB2B[0]?.bundle_price) : ""

    let priceResult = obj?.prices?.length > 0 ? hasEmptyValues(obj?.prices) : ""

    console.log(priceResult, "priceResult");

    let optionListResponse = obj?.attributes?.length > 0 ? hasEmptyValues(obj?.attributes) : ""

    console.log(obj?.attributes, optionListResponse, "response in validator")

    console.log((obj?.attributes?.length > 0 && optionListResponse), optionListResponse, obj?.attributes?.length > 0, obj?.attributes, "response of varinat");

    if (!obj?.image) {
        toast.error("Please select product images")
        return false
    }
    if (!obj?.name) {
        toast.error("Please enter product name")
        return false
    }
    if (!obj?.service_id) {
        toast.error("Please select master category")
        return false
    }
    if (!obj?.category_id) {
        toast.error("Please select category")
        return false
    }
    if (!obj?.sub_category_id) {
        toast.error("Please select sub-category")
        return false
    }
    if (!obj?.brand_id) {
        toast.error("Please select brand")
        return false
    }
    if (!obj?.description) {
        toast.error("Please enter product description")
        return false
    }
    if (!obj?.price) {
        toast.error("Please enter product price")
        return false
    }
    if (!obj?.type) {
        toast.error("Please enter product type")
        return false
    }
    if (!obj?.barcode) {
        toast.error("Please enter barcode")
        return false
    }
    if ((obj?.barcode && obj?.barcode?.length < 11) || (obj?.barcode && obj?.barcode?.length > 14)) {
        toast.error("Please enter valid barcode")
        return false
    }
    if ((obj?.sku && obj?.sku?.length < 11) || (obj?.sku && obj?.sku?.length > 14)) {
        toast.error("Please enter valid sku")
        return false
    }
    if (obj?.delivery_options?.length <= 0) {
        toast.error("Please select delivery options")
        return false
    }
    if (obj?.delivery_options?.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.weight) {
        toast.error("Please enter product weight")
        return false
    }
    if (obj?.delivery_options?.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.length) {
        toast.error("Please enter product length")
        return false
    }
    if (obj?.delivery_options?.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.breadth) {
        toast.error("Please enter product bredth")
        return false
    }
    if (obj?.delivery_options?.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.height) {
        toast.error("Please enter product height")
        return false
    }
    if (!obj?.quantity) {
        toast.error("Please enter Inventory-Opening")
        return false
    }
    // if (!obj?.quantity_reorder_point) {
    //     toast.error("Please enter Inventory-Reorder Point")
    //     return false
    // }
    // if (obj?.ingredients?.length <= 0) {
    //     toast.error("Please select attributes")
    //     return false
    // }
    if (!activePos && !activeBtob && !activeBtoc) {
        toast.error("Please select product availability")
        return false
    }
    if (obj?.prices?.length == 0 || priceResult) {
        toast.error("Please add selling price and profit margin")
        return false
    }
    if (filterB2B?.length > 0 && resultB2B) {
        toast.error("Please add selling quantity and selling price")
        return false
    }
    // if (obj?.attributes?.length > 0 && optionListResponse) {
    //     toast.error("Variant info could not be empty")
    //     return false
    // }

    if (!validateData(obj?.product_variants) && obj?.product_options?.length > 0) {
        // toast.error("Please enter varinat info correctly")
        return false
    }

    return true
}
export { formValidator };