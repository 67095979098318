import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  calendar,
  downlight,
  productList1,
  productList2,
  productList3,
  productList4,
  search_light,
  addPlusBtn,
  modalCancel,
} from "../../../utilities/images";
import ProductListTable from "./productListTable";
import ProductListView from "./productListView";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductApiAsync,
  getBrandOfCategoryAsync,
  getCategoriesAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DashboardNavbar from "../dashboard/DashboardNavbar";

var isOpen = false;
var isBrandOpen = false;
const ProductList = (props) => {
  const { seller_id } = { ...props };
  console.log(seller_id, "from Product List");
  const childRef = useRef(null);
  const containerRef = useRef(null);
  const brandRef = useRef(null);
  const [categoryData, setCategoryData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchBrandValue, setSearchBrandValue] = useState();
  const dispatch = useDispatch();
  const [newMessagekey, setNewMessagekey] = useState(Math.random());
  const toastId = React.useRef(null);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [activeTab, setActiveTab] = useState("tableView");
  const [brandId, setBrandId] = useState("");
  const [brandIds, setBrandIds] = useState([]);
  const [brand, setBrand] = useState([]);
  const [selectedValue, setSelectedValue] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [columnStatus, setColumnStatus] = useState({
    upc: true,
    barcode: true,
    locations: true,
    category: true,
    subCategory: true,
    brand: true,
    stock: true,
    availableToSell: true,
    price: true,
    weight: true,
  });

  const handleFilterValue = (e, value) => {
    if (value === "upc") {
      setColumnStatus({ ...columnStatus, upc: e.target.checked });
    }
    if (value === "barcode") {
      setColumnStatus({ ...columnStatus, barcode: e.target.checked });
    }
    if (value === "locations") {
      setColumnStatus({ ...columnStatus, locations: e.target.checked });
    }
    if (value === "category") {
      setColumnStatus({ ...columnStatus, category: e.target.checked });
    }
    if (value === "subCategory") {
      setColumnStatus({ ...columnStatus, subCategory: e.target.checked });
    }
    if (value === "brand") {
      setColumnStatus({ ...columnStatus, brand: e.target.checked });
    }
    if (value === "stock") {
      setColumnStatus({ ...columnStatus, stock: e.target.checked });
    }
    if (value === "availableToSell") {
      setColumnStatus({ ...columnStatus, availableToSell: e.target.checked });
    }
    // if (value === "price") {
    //     setColumnStatus({ ...columnStatus, price: e.target.checked });
    // }
    if (value === "weight") {
      setColumnStatus({ ...columnStatus, weight: e.target.checked });
    }
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const getAddress = () => {
    let params = {
      token: auth?.payload?.token,
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAddressData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const fetchAllCategories = (searchKeyword) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        with_subcategories: true,
        service_type: "product",
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchKeyword) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          search: searchKeyword,
          check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const fetchAllBrand = (searchKeyword = null) => {
    let brandData;
    if (searchKeyword && !subCategoryIds?.length > 0) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchKeyword,
          check_product_existance: false,
        },
      };
    } else if (subCategoryIds?.length > 0 && searchKeyword) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchKeyword,
          category_id: subCategoryIds.toString(),
          check_product_existance: false,
        },
      };
    } else if (subCategoryIds?.length > 0 && !searchKeyword) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          category_id: subCategoryIds.toString(),
          check_product_existance: false,
        },
      };
    } else {
      brandData = {
        tokenData: auth?.payload?.token,
        check_product_existance: false,
      };
    }
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        // let filterResult = obj?.payload?.data?.map((item) => {
        //     return {
        //         value: item?.id,
        //         label: item?.name
        //     };
        // });
        setBrand(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const handleSubcategoryChange = (subCategoryId) => {
    let filterData = [...subCategoryIds];
    if (filterData?.includes(subCategoryId)) {
      filterData = filterData?.filter((val) => val != subCategoryId);
      setSubCategoryIds(filterData);
    } else {
      filterData = [...subCategoryIds, subCategoryId];
      setSubCategoryIds(filterData);
    }
  };
  const handleBrandChange = (brandId) => {
    let filterData = [...brandIds];
    if (filterData?.includes(brandId)) {
      filterData = filterData?.filter((val) => val != brandId);
      setBrandIds(filterData);
    } else {
      filterData = [...brandIds, brandId];
      setBrandIds(filterData);
    }
  };

  useEffect(() => {
    fetchAllCategories();
    fetchAllBrand();
    getAddress();
  }, []);

  const handleToggle = () => {
    setDropdownOpen(true);
    setTimeout(() => {
      isOpen = true;
      setNewMessagekey(Math.random());
    }, 100);
    // setIsOpen(true);
  };
  const handleBrandToggle = () => {
    setTimeout(() => {
      isBrandOpen = true;
      setNewMessagekey(Math.random());
    }, 100);
    // setIsOpen(true);
  };

  const handleClickOutside = async (event) => {
    if (
      containerRef?.current &&
      !containerRef.current.contains(event.target) &&
      isOpen
    ) {
      isOpen = false;
      setNewMessagekey(Math.random());
    }
    // setSelectedValue(false)
  };
  const handleClickOutsideBrand = async (event) => {
    if (
      brandRef?.current &&
      !brandRef.current.contains(event.target) &&
      isBrandOpen
    ) {
      isBrandOpen = false;
      setNewMessagekey(Math.random());
    }
    // setSelectedValue(false)
  };
  const setValue = (data) => {
    setSelectedValue(data);
  };

  const handleDownloadCSV = () => {
    if (selectedRows?.length > 0) {
      // Convert your data into CSV format using PapaParse
      const csvData = Papa.unparse(selectedRows);
      // Create a Blob containing the CSV data
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      // Save the Blob as a file with a .csv extension
      saveAs(blob, "product_list.csv");
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select product to import");
      }
    }
  };

  const handleDeleteProduct = () => {
    if (selectedRows?.length > 0) {
      const productIds = selectedRows.map((item) => item.id);
      Swal.fire({
        title: "Do you want to delete the product?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let params = {
            postData: {
              product_ids: productIds,
            },
            tokenData: auth?.payload?.token,
          };
          setDeleteLoading(true);
          dispatch(deleteProductApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
              setDeleteLoading(false);
              //   childRef.getProductList();
              childRef.current.getProductListParent();
              toast.success(obj?.msg);
            })
            .catch((obj) => {
              setDeleteLoading(false);
            });
        }
      });
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select product to delete");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutsideBrand);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickOutsideBrand);
    };
  }, []);

  useEffect(() => {
    if (searchValue) {
      const search = setTimeout(() => {
        //Your search query and it will run the function after 3secs from user stops typing
        var keyword = searchValue.toLowerCase();
        fetchAllCategories(keyword);
      }, 1000);
      return () => clearTimeout(search);
    } else {
      fetchAllCategories();
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchBrandValue) {
      const search = setTimeout(() => {
        //Your search query and it will run the function after 3secs from user stops typing
        var keyword = searchBrandValue.toLowerCase();
        fetchAllBrand(keyword);
      }, 1000);
      return () => clearTimeout(search);
    } else {
      fetchAllBrand();
    }
  }, [searchBrandValue, subCategoryIds]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Products-List";
  }, []);

  return (
    <>
      {seller_id ? (
        <></>
      ) : (
        <DashboardNavbar title="Products " backShow={false} />
      )}
      <div className="productList productModuleList" key={newMessagekey}>
        {/* <div className='topHeader_'>
                    <div className="topStickyHeader_ position-static w-100">
                        <div className="row align-items-center">
                            <div className="col-md-9">
                                <h2 className="mainHeading">Product /<span className="innerSubtext">Products List</span> </h2>
                            </div>
                            <div className='col-md-3'>
                                <div className='actionbtns_'>
                                    {
                                        selectedRows?.length > 0 && activeTab == 'tableView' ?
                                            <button className='deletebtn_' disabled={loading} type="button" onClick={() => handleDeleteProduct()}> <i className="fas fa-trash-alt"></i> </button>
                                            : <></>
                                    }
                                    <button className='downloadbtn_' type="button" onClick={() => handleDownloadCSV()}> <i className="fas fa-arrow-down"></i> </button>
                                    <div className="dropdown weekDropdown">
                                        <img src={addPlusBtn} alt="" className="img-fluid plusDropImg ms-2" data-bs-toggle="dropdown" aria-expanded="false" />
                                        <button className="downloadbtn_ ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span></button>
                                        <ul className="dropdown-menu categoryDrop">
                                            <li className='categoryFlex tablesubHead '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.upc}
                                                    onChange={(e) => handleFilterValue(e, 'upc')}
                                                />
                                                <label className='tablesubHead ' for="view1">UPC</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.locations}
                                                    onChange={(e) => handleFilterValue(e, 'locations')}
                                                />
                                                <label className='tablesubHead mb-3' for="view1">Locations</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.category}
                                                    onChange={(e) => handleFilterValue(e, 'category')}
                                                />
                                                <label className='tablesubHead ' for="view1">Category</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.subCategory}
                                                    onChange={(e) => handleFilterValue(e, 'subCategory')}
                                                />
                                                <label className='tablesubHead ' for="view1">Sub-Category</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.brand}
                                                    onChange={(e) => handleFilterValue(e, 'brand')}
                                                />
                                                <label className='tablesubHead ' for="view1">Brand</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.stock}
                                                    onChange={(e) => handleFilterValue(e, 'stock')}
                                                />
                                                <label className='tablesubHead ' for="view1">Stock</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.availableToSell}
                                                    onChange={(e) => handleFilterValue(e, 'availableToSell')}
                                                />
                                                <label className='tablesubHead ' for="view1">Available to Sell</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.price}
                                                    onChange={(e) => handleFilterValue(e, 'price')}
                                                />
                                                <label className='tablesubHead ' for="view1">Price</label>
                                            </li>
                                            <li className='categoryFlex '>
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="columnStatus"
                                                    value={columnStatus?.weight}
                                                    onChange={(e) => handleFilterValue(e, 'weight')}
                                                />
                                                <label className='tablesubHead ' for="view1">Weight</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3 text-end">
                                <button className='backgroundFillbtn' type="button" onClick={() => {
                                    setModalDetail({ show: true, flag: "FindProduct" });
                                    setKey(Math.random());
                                }}> <span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span> Add Product to Shop</button>
                            </div> 
                        </div>
                    </div>
                </div> */}
        <div className="tableSectionBackground">
          <div className="tableFihandleBrandDataChangelters tableFilters ">
            <div className="col-lg-12">
              <div className="row align-items-center">
                {/* <div className='col-md-2'>
                                <div className="calenderIcon">
                                    <img src={calendar} alt="calender" />
                                    <DatePicker
                                        className='cateDate'
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={today}
                                        selectsRange
                                        placeholderText="Date"
                                    />
                                </div>
                            </div>  */}

                <div className="col-lg-6 col-md-12">
                  <div className="productMainCategory">
                    <div className="productMainbox_">
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle()}
                      >
                        All Categories{" "}
                        <span>
                          <i
                            className="fal fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {isOpen ? (
                        <div className="filterDropdown_" ref={containerRef}>
                          <div className="input-group search_group">
                            <img
                              src={search_light}
                              alt=""
                              className="img-fluid searchIcon"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {categoryData?.length > 0 ? (
                                  categoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        <h3 className="mainboldHead">
                                          {val?.name}
                                        </h3>
                                        <ul className="listItemsprodct_">
                                          {val?.sub_catgories?.map(
                                            (data, index) => {
                                              return (
                                                <li
                                                  className={`textParagh ${
                                                    subCategoryIds?.includes(
                                                      data?.id
                                                    )
                                                      ? "selectProduct"
                                                      : ""
                                                  } mt-2 mb-2`}
                                                  key={index}
                                                  onClick={() => {
                                                    handleSubcategoryChange(
                                                      data?.id
                                                    );
                                                    setSelectedValue(true);
                                                  }}
                                                >
                                                  {data?.name?.slice(0, 30)}
                                                  {data?.name?.length >= 30
                                                    ? "..."
                                                    : ""}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div>No Categories Found</div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="productMainbox_">
                      <button
                        className="filterTab_"
                        onClick={() => handleBrandToggle()}
                      >
                        All Brand{" "}
                        <span>
                          <i
                            className="fal fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {isBrandOpen ? (
                        <div className="filterDropdown_" ref={brandRef}>
                          <div className="input-group search_group">
                            <img
                              src={search_light}
                              alt=""
                              className="img-fluid searchIcon"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              value={searchBrandValue}
                              onChange={(e) =>
                                setSearchBrandValue(e.target.value)
                              }
                            />
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {brand?.length > 0 ? (
                                  <div className="listProductbody_ mt-3">
                                    <ul className="listItemsprodct_">
                                      {brand?.map((data, index) => {
                                        return (
                                          <li
                                            className={`textParagh ${
                                              brandIds?.includes(data?.id)
                                                ? "selectProduct"
                                                : ""
                                            } mt-2 mb-2`}
                                            key={index}
                                            onClick={() => {
                                              handleBrandChange(data?.id);
                                              setSelectedValue(true);
                                            }}
                                          >
                                            {data?.name?.slice(0, 30)}
                                            {data?.name?.length >= 30
                                              ? "..."
                                              : ""}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : (
                                  <div>No Brand Found</div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className='col-md-2'>
                                <Select
                                    value={selectedBrand}
                                    onChange={handleBrandDataChange}
                                    options={brand}
                                    isSearchable={true}
                                    placeholder="Select Brand"
                                />
                            </div> */}

                {/* <div className='col-md-2'>
                                <div className='productMainbox_'>
                                    <button className="filterTab_" onClick={() => handleBrandToggle()}
                                    >
                                        All Brand <span ><i className="fal fa-chevron-down" aria-hidden="true"></i></span>
                                    </button>
                                    {
                                        isBrandOpen ?
                                            <div className="filterDropdown_" ref={brandRef}>
                                                <div className="input-group search_group">
                                                    <img src={search_light} alt="" className="img-fluid searchIcon" />
                                                    <input type="text" className="form-control" placeholder="Search here" value={searchBrandValue} onChange={(e) => setSearchBrandValue(e.target.value)} />
                                                </div>
                                                <div className='listdropfilterList_'>
                                                    {
                                                        loading ?
                                                            <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                                                            :
                                                            <>
                                                                {
                                                                    brand?.length > 0 ?
                                                                        <div className='listProductbody_ mt-3'>
                                                                            <ul className='listItemsprodct_'>
                                                                                {
                                                                                    brand?.map((data, index) => {
                                                                                        return (
                                                                                            <li className={`textParagh ${brandIds?.includes(data?.id) ? "selectProduct" : ""} mt-2 mb-2`} key={index} onClick={() => { handleBrandChange(data?.id); setSelectedValue(true) }}>{(data?.name)?.slice(0, 30)}{data?.name?.length >= 30 ? "..." : ""}</li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        : <div>No Brand Found</div>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                </div>
                            </div> */}
                {/* <div className='col-md-2'>
                                <select className="cateSelectdFilter" required>
                                    <option value="" selected>
                                        All Locations
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option>
                                </select>
                            </div> */}
                <div className="col-lg-6 col-md-12">
                  <div className="productListTab productListBtn">
                    <div className="actionbtns_">
                      <div className="dropdown weekDropdown me-2 dropdown-menu-end">
                        {/* <img src={addPlusBtn} alt="" className="img-fluid plusDropImg ms-2" data-bs-toggle="dropdown" aria-expanded="false" /> */}
                        <button
                          className="productHeadBtn ms-2"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="plusIcon_ m-0">
                            <i className="fa-solid fa-plus"></i>{" "}
                          </span>
                        </button>
                        <ul className="dropdown-menu categoryDrop ">
                          <li className="categoryFlex tablesubHead ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.upc}
                              onChange={(e) => handleFilterValue(e, "upc")}
                            />
                            <label className="tablesubHead " for="view1">
                              SKU
                            </label>
                          </li>
                          <li className="categoryFlex tablesubHead ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.barcode}
                              onChange={(e) => handleFilterValue(e, "barcode")}
                            />
                            <label className="tablesubHead " for="view1">
                              UPC
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.locations}
                              onChange={(e) =>
                                handleFilterValue(e, "locations")
                              }
                            />
                            <label className="tablesubHead" for="view1">
                              Locations
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.category}
                              onChange={(e) => handleFilterValue(e, "category")}
                            />
                            <label className="tablesubHead " for="view1">
                              Category
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.subCategory}
                              onChange={(e) =>
                                handleFilterValue(e, "subCategory")
                              }
                            />
                            <label className="tablesubHead " for="view1">
                              Sub-Category
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.brand}
                              onChange={(e) => handleFilterValue(e, "brand")}
                            />
                            <label className="tablesubHead " for="view1">
                              Brand
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.stock}
                              onChange={(e) => handleFilterValue(e, "stock")}
                            />
                            <label className="tablesubHead " for="view1">
                              Stock
                            </label>
                          </li>
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.availableToSell}
                              onChange={(e) =>
                                handleFilterValue(e, "availableToSell")
                              }
                            />
                            <label className="tablesubHead " for="view1">
                              Available to Sell
                            </label>
                          </li>
                          {/* <li className='categoryFlex '>
                                                        <input
                                                            className="me-2"
                                                            type="checkbox"
                                                            id="columnStatus"
                                                            checked={columnStatus?.price}
                                                            onChange={(e) => handleFilterValue(e, 'price')}
                                                        />
                                                        <label className='tablesubHead ' for="view1">Price</label>
                                                    </li> */}
                          <li className="categoryFlex ">
                            <input
                              className="me-2"
                              type="checkbox"
                              id="columnStatus"
                              checked={columnStatus?.weight}
                              onChange={(e) => handleFilterValue(e, "weight")}
                            />
                            <label className="tablesubHead " for="view1">
                              Weight
                            </label>
                          </li>
                        </ul>
                      </div>
                      {selectedRows?.length > 0 && activeTab == "tableView" ? (
                        <button
                          className="deletebtn_"
                          disabled={loading}
                          type="button"
                          onClick={() => handleDeleteProduct()}
                        >
                          {" "}
                          <i className="fas fa-trash-alt"></i>{" "}
                        </button>
                      ) : (
                        <></>
                      )}
                      <button
                        className="productHeadBtn me-2"
                        type="button"
                        onClick={() => handleDownloadCSV()}
                      >
                        {" "}
                        <i className="fas fa-arrow-down"></i>{" "}
                      </button>
                    </div>
                    <button
                      className={`plansBtns ${
                        activeTab === "tableView" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("tableView")}
                    >
                      <img
                        src={productList1}
                        alt="image"
                        className="showImage"
                      />
                      <img
                        src={productList2}
                        alt="image"
                        className="hideImage"
                      />
                    </button>
                    <button
                      className={`plansBtns ${
                        activeTab === "cartView" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("cartView")}
                    >
                      <img
                        src={productList3}
                        alt="image"
                        className="hideImage"
                      />
                      <img
                        src={productList4}
                        alt="image"
                        className="showImage"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {loading === true ?
                    // <CustomLoader />
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    : */}
          <div className="listAreaContainer m-0 ">
            {activeTab == "tableView" ? (
              <ProductListTable
                addressData={addressData}
                selectedRows={selectedRows}
                seller_id={seller_id}
                columnStatus={columnStatus}
                ref={childRef}
                setSelectedRows={setSelectedRows}
                statusBrand={isBrandOpen}
                startDate={startDate}
                endDate={endDate}
                statusCategory={isOpen}
                selectedValue={selectedValue}
                setValue={(a) => setValue(a)}
                subCategoryId={subCategoryIds}
                brandId={brandIds}
              />
            ) : activeTab == "cartView" ? (
              // <ProductListView ProductList={productList} />
              <ProductListView
                addressData={addressData}
                selectedRows={selectedRows}
                seller_id={seller_id}
                setSelectedRows={setSelectedRows}
                columnStatus={columnStatus}
                statusBrand={isBrandOpen}
                startDate={startDate}
                endDate={endDate}
                statusCategory={isOpen}
                selectedValue={selectedValue}
                setValue={(a) => setValue(a)}
                subCategoryId={subCategoryIds}
                brandId={brandIds}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
