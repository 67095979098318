import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import DataTable from "react-data-table-component";
import {
  deleteCouponApiAsync,
  getProductCouponListApiAsync,
} from "../dashboard/dashboardSlice";
// import moment from "moment-timezone";
import moment from "moment";
import DatePicker from "react-datepicker";
import { calendar } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CreateCoupon from "./createCouponModal";
import CouponViewModal from "./couponViewModal";

const Coupons = () => {
  const pathname = window.location.pathname;

  console.log(pathname, "pathname");
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [CouponList, setCouponList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteHandle = (id) => {
    console.log(id, "idddddddddddddd");
    Swal.fire({
      title: "Do you want to delete this Coupon?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            id: id?.id,
          },

          tokenData: auth?.payload?.token,
        };
        dispatch(deleteCouponApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getCouponList();
            toast.success(obj?.msg);
          })
          .catch((obj) => { });
      }
    });
  };

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  console.log(CouponList, "LLLLLLLLLLLLLLLL");

  let columns1 = [
    {
      name: "#",
      // selector: row => row?.id,
      selector: (row, i) => i + 1 * (perPage * (currentPage - 1)) + 1,
      width: "40px",
    },
    {
      name: `${pathname === "/serviceOffer" ? "Service" : "Coupon"} Name`,
      selector: (row) => {
        return (
          <>
            <div
              className="d-flex align-items-center"
              onClick={() => handleRowClick(row)}
            >
              {/* <img className="userInnerImg" src={row?.product?.image} /> */}
              <div className="ms-2">
                <span className="textParagh ">
                  {row?.product?.name?.length > 15
                    ? row?.name?.substring(0, 15) + "..."
                    : row?.name}
                </span>
              </div>
            </div>
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Code",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {row?.code?.name?.length > 15
                ? row?.code?.substring(0, 15) + "..."
                : row?.code}
            </span>
          </>
        );
      },
    },
    {
      name: "Minimum Discount",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {/* {row?.subcategories?.name?.length > 15
                ? row?.min_discount?.substring(0, 15) + "..."
                : row?.min_discount} */}
              {/* {row?.min_discount ? "$" : ""}
              {row?.min_discount} */}
              {row?.min_discount
                ? `$${row?.min_discount.toLocaleString()}`
                : "$0.00"}

              {/* {row?.min_discount
    ? `$${Number(row?.min_discount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : "0.00"} */}
            </span>
          </>
        );
      },
      width: "180px",
    },
    {
      name: "Minimum Order Amount",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {/* {row?.minimum_order_amount?.length > 15
                ? row?.minimum_order_amount?.substring(0, 15) + "..."
                : row?.minimum_order_amount} */}

              {row?.minimum_order_amount ? "$" : "0.00"}
              {row?.minimum_order_amount}

              {/* {row?.minimum_order_amount
                ? `$${row?.minimum_order_amount.toLocaleString()}`
                : "0.00"} */}
            </span>
          </>
        );
      },
      width: "180px",
    },
    {
      name: "Maximum total usage",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {/* {row?.minimum_order_amount?.length > 15
                ? row?.max_total_usage?.substring(0, 15) + "..."
                : row?.max_total_usage} */}
              {/* {row?.max_total_usage ? "$" : ""}
              {row?.max_total_usage} */}
              {row?.max_total_usage
                ? `${row?.max_total_usage.toLocaleString()}`
                : ""}

              {/* {row?.max_total_usage ? `$${row?.max_total_usage.toFixed(2)}` : "0.00"} */}
            </span>
          </>
        );
      },
      width: "180px",
    },

    {
      name: "Update time",
      selector: (row) => {
        return (
          // row.updated_at
          moment?.utc(row?.updated_at)?.format("MMM DD, YYYY")
        );
      },
    },

    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center pe-3 ps-3">
              <div className="dropdown tableDropdown ms-3 ">
                <span
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div
                  className="dropdown-menu tableDropdownMenus categoryDrop"
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedProduct(row);
                      setModalDetail({ show: true, flag: "viewCoupon" });
                      setKey(Math.random());
                    }}
                  >
                    View
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedProduct(row);
                      setModalDetail({ show: true, flag: "editCoupon" });
                      setKey(Math.random());
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => deleteHandle(row)}
                  >
                    Delete
                  </li>
                </div>
              </div>
            </div>
          </>
        );
      },
      width: "100px",
    },
  ];

  if (pathname === "/productOffer") {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 3),
      {
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.brands?.name?.length > 15
                  ? row?.brands?.name?.substring(0, 15) + "..."
                  : row?.brands?.name}
              </span>
            </>
          );
        },
        name: "Brand",
      },
      ...columns1.slice(columns1?.length - 3),
    ];
  }

  const handleRowClick = (e) => {
    setSelectedProduct(e);
    setModalDetail({ show: true, flag: "viewCoupon" });
    setKey(Math.random());
  };

  const getCouponList = (page) => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: page ? page : currentPage,
        limit: perPage,
        // type: pathname === "/serviceOffer" ? "service" : "product",
      },
    };
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    dispatch(getProductCouponListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log(obj?.payload, "Couponsll");
        console.log("hello000");
        // console.log(CouponList[0].data)
        setCouponList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false);
      });

    console.log(selectedProduct + "selected product");
  };

  useEffect(() => {
    getCouponList();
  }, [currentPage, perPage, endDate, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Coupons";
  }, []);

  return (
    <>
      <DashboardNavbar
        title={`${pathname === "/serviceOffer" ? "Services/ offer " : "Coupons"
          }`}
        backShow={false}
      />
      <div className="tableSectionBackground productOfferSection">
        <div className="filteOffer">
          <div className="row">
            <div className="col-lg-6">
              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                <DatePicker
                  className="cateDate"
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <button
                className="backgroundFillbtn"
                type="button"
                onClick={() => {
                  setModalDetail({ show: true, flag: "createcoupons" });
                  setKey(Math.random());
                }}
              >
                Create Coupon
              </button>
            </div>
          </div>
        </div>
        <div className="plansAreasContainer">
          <section className="recentTransections mt-1 commonTableheight table-responsive">
            <DataTable
              columns={columns1}
              data={CouponList}
              progressPending={loading}
              defaultSortFieldId={1}
              customStyles={customStyles}
              onRowClicked={(e) => handleRowClick(e)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </section>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop={true}
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "viewCoupon" ?
              "m" : ""
          }
          ids={
            modalDetail.flag === "createcoupons"
              ? "createcoupons"
              : modalDetail.flag === "viewCoupon"
                ? "viewCoupon"
                : modalDetail.flag === "editOffer"
                  ? "editOffer"
                  : ""
          }
          size={
            modalDetail.flag === "viewOffer" || modalDetail.flag === "MakeOffer"
              ? "xl"
              : "md" ||
                modalDetail.flag === "editOffer" ||
                modalDetail.flag === "MakeOffer"
                ? "xl"
                : "md"
          }
          child={
            modalDetail.flag === "createcoupons" ? (
              <CreateCoupon
                close={() => handleOnCloseModal()}
                setTotalRows={setTotalRows}
                getCouponList={() => getCouponList()}
              />
            ) : modalDetail.flag === "editCoupon" ? (
              <CreateCoupon
                close={() => handleOnCloseModal()}
                selectedProduct={selectedProduct}
                isEdit={true}
                getCouponList={() => getCouponList()}
              />
            ) : modalDetail.flag === "viewCoupon" ? (
              <CouponViewModal
                selectedProduct={selectedProduct}
                close={() => handleOnCloseModal()}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading_">
                  {modalDetail.flag === "createcoupons"
                    ? "Create"
                    : "" || modalDetail.flag === "editCoupon"
                      ? "Edit"
                      : "" || modalDetail.flag === "viewCoupon"
                        ? "view"
                        : ""}{" "}
                  Coupon
                </h2>

                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default Coupons;
