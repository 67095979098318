import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteicon, penIcon, user,plusImg, viewIcon } from '../../../../utilities/images';
import CustomModal from '../../../components/shared/CustomModal';
import PoliciesModal from './policiesModal';
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { activeInactivePolicyApiAsync, deletePolicyApiAsync, getTermsConditionsPoliciesApiAsync, systemConfigAsync } from '../systemConfigSlice';
import moment from 'moment-timezone';
import CreatePolicyModal from './createPolicyModal';
import Swal from 'sweetalert2';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const Policies = () => {
    const [loading, setLoading] = useState(false);
    const [policiesData, setPoliciesData] = useState([]);
    const [selectedPolicies, setSelectedPolicies] = useState("");
    const [flag, setFlag] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [indexId, setIndexId] = useState();
    const dispatch = useDispatch()
    const [key, setKey] = useState(Math.random());
    const auth = useSelector(selectLoginAuth);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const getpoliciesData = () => {
        setLoading(true)
        dispatch(getTermsConditionsPoliciesApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                setPoliciesData(obj?.payload);
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const availabilityStatusHandle = (id, flag) => {
        let data = {
            "postData": {
                'id': id,
                "flag": flag
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(activeInactivePolicyApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                getpoliciesData()
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handlePolicyDelete = (id) => {
        Swal.fire({
            title: 'Do you want to delete this policy?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: auth?.payload?.token
                }
                dispatch(deletePolicyApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false);
                        getpoliciesData()
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
        })
    }

    console.log(policiesData, "policies dtaa");

    useEffect(() => {
        getpoliciesData()
    }, [])

    return (
        <>  <DashboardNavbar title="Policies" backShow={false} />
            <div className='legal'>
                {/* <div className='topHeader_'>
                    <div className="topStickyHeader_">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>System Config / <span className="innerSubtext">Policies</span> </span>
                                </h2>
                            </div>
                            <div className="col-md-6 text-end">
                                <button className='btn btn-primary' onClick={() => { setModalDetail({ show: true, title: "createPolicy", flag: "createPolicy" }); setKey(Math.random()); setFlag("create"); setSelectedPolicies("") }}>Create New Policy</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {
                    loading ?
                        <span className="spinner-border inner-spin spinner-border-sm"></span> :
                        <div className='row'>
                            {policiesData ?
                                policiesData?.filter(v => v?.flag != "legal_policy")?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {/* <h5>{item?.flag_policies?.length > 0 && item?.flag?.charAt(0)?.toUpperCase() + item?.flag?.replace(/_/g, ' ')?.slice(1)}</h5> */}
                                            {item?.flag_policies?.length > 0 ?
                                                item?.flag_policies?.map((v, i) => {
                                                    return (
                                                        <div className='col-md-3 mt-4' key={i}>
                                                            <div className='legalCard'>
                                                            <strong>{
                                                                (item?.flag== "" || item?.flag== "terms_and_conditons")?
                                                                "Terms & Condition":
                                                                item?.flag== "shipping_policy"?
                                                                "Shipping Policy":
                                                                item?.flag== "return_policy"?
                                                                "Return Policy":
                                                                item?.flag== "discount_coupon_policy"?
                                                                "Discount Coupon Policy":
                                                                item?.flag== "privacy_policy"?
                                                                "Privacy Policy":
                                                                item?.flag== "cancellation_policy"?
                                                                "Cancel Policy":
                                                                ""
                                                                }</strong>
                                                                <div className='policycardHead'>
                                                                    <div className='leftPolicy'>
                                                                        <h1 className='descriptionPara_ fw-normal'>Publish date:</h1>
                                                                        <p><span className='commonsmallgeryHead_ me-3'>{moment(v?.created_at).format('MMM DD, YYYY')}</span> <span className='commonsmallgeryHead_'>{moment(v?.created_at).format('hh:mm A')}</span></p>
                                                                    </div>
                                                                    {v?.is_active == true ?
                                                                        <p className='activeStatusbtnn_'>Active</p>
                                                                        :
                                                                        <div className="form-check form-switch me-2 mb-0">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="availabilityStatus"
                                                                                name="availabilityStatus"
                                                                                value="availabilityStatus"
                                                                                checked={v?.is_active === true}
                                                                                onChange={() => availabilityStatusHandle(v?.id, v?.flag)}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className='termsmiddleContent_ w-100 text-start mt-4'><strong>{v?.title}</strong>
                                                                    <div className='descriptionPara_ fw-normal mt-2' dangerouslySetInnerHTML={{ __html: (v?.content.length > 30 ? `${v?.content.slice(0, 30)}...` : v?.content) }} />
                                                                </div>
                                                                <div className='dFlex mt-3'>
                                                                    <div className='editLegalTxt'>
                                                                        <h1 className='descriptionPara_ fw-normal'>Last update date:</h1>
                                                                        <p><span className='commonsmallgeryHead_ me-3'>{moment(v?.updated_at).format('MMM DD, YYYY, hh:mm A')}</span> </p>
                                                                    </div>
                                                                    <img style={{ cursor: "pointer", width: "45px" }} className='editBtn_' onClick={() => { setModalDetail({ show: true, title: "createPolicy", flag: "createPolicy" }); setKey(Math.random()); setSelectedPolicies(v); setIndexId(index) }} src={penIcon} alt="" />
                                                                    <img style={{ cursor: "pointer", width: "43px" }} onClick={() => handlePolicyDelete(v?.id)} className='editBtn_ ms-2' src={deleteicon} alt="delete" />
                                                                    <img onClick={() => { setModalDetail({ show: true, title: "policiesModal", flag: "policiesModal" }); setKey(Math.random()); setSelectedPolicies(v); setIndexId(index) }} style={{ cursor: "pointer", width: "50px" }} className='editBtn_ ms-2' src={viewIcon} alt="eye" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <></>
                                                // <div className='ms-5'>No {item?.flag?.replace(/_/g, ' ')} record found</div>
                                            }
                                        </React.Fragment>
                                    )
                                }) : <div className='ms-5 mb-2'>No policies record found</div>}
                                <div className='col-md-3 mt-4'>
                                    <div  onClick={() => { setModalDetail({ show: true, title: "createPolicy", flag: "createPolicy" }); setKey(Math.random()); setFlag("create"); setSelectedPolicies("") }} className='legalCard'>
                                        <div className='legalAdd'>
                                            <button className='newbtn'><img src={plusImg} className='plusAdd' alt='plusimage' />
                                                <h4 className='addNew mt-4 '>Add New</h4></button>
                                        </div>
                                    </div>
                                </div>
                        </div>}
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className="customContent"
                ids={modalDetail.flag === "createPolicy" ? "CreatePolicy" : "legalModal"}
                size="lg"
                child={modalDetail.flag === "policiesModal" ?
                    <PoliciesModal close={() => handleOnCloseModal()} selectedPolicies={selectedPolicies} policiesData={policiesData} indexId={indexId} getpoliciesData={() => getpoliciesData()} /> :
                    modalDetail.flag === "createPolicy" ? <CreatePolicyModal flag={flag} selectedPolicies={selectedPolicies} getpoliciesDataHandle={() => getpoliciesData()} close={() => handleOnCloseModal()} /> : ""}
                onCloseModal={() => handleOnCloseModal()}
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {
                                    modalDetail.flag === "createPolicy" ?
                                        <div className='anotherHeader'>
                                            <h2 className="modalHeading">{!selectedPolicies ? "Create" : "Update"} Policy</h2>
                                        </div> :
                                        modalDetail.flag === "policiesModal" ?
                                            <div className='anotherHeader'>
                                                <h2 className="modalHeading">{selectedPolicies?.title}</h2>
                                            </div>
                                            : ""
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>
                            </div>
                        </div>
                    </>
                }
            />

        </>
    )
}

export default Policies