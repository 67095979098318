import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import DataTable from "react-data-table-component";
import CustomModal from "../../components/shared/CustomModal";
import MyProductDetials from "./myProductDetails";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductServiceListApiAsync,
  selectProductData,
} from "./productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { dots, modalCancel } from "../../../utilities/images";
import Swal from "sweetalert2";
import { deleteProductApiAsync } from "../dashboard/dashboardSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditVarientsModal from "./EditVarientsModal";

const ProductListTable = forwardRef((props, ref) => {
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productList, setProductList] = useState([]);
  const [oldSubcatIds, setOldSubCatIds] = useState(props?.subCategoryId);
  const [oldBrandIds, setOldBrandIds] = useState(props?.brandId);
  const { seller_id } = { ...props };
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [callPagination, setCallPagination] = useState(false);
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const productData = useSelector(selectProductData);
  const [oldStartDate, setOldStartDate] = useState("");
  const [oldEndDate, setOldEndDate] = useState("");

  let ProductDatas = productData?.payload?.data;
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleColumnHeaderClick = () => {
    console.log("mai chal gyaaaaaaa");
  };

  let columns1 = [
    // {
    //   name: '#',
    //   // selector: row => row.id,
    //   selector: (row, i) => (i + 1 * (perPage * (currentPage - 1)) + 1),
    //   width: "100px"
    // },
    {
      name: "Item",
      selector: (row) => {
        return (
          <>
            <div
              className="d-flex align-items-center"
              onClick={() => handleRowClick(row)}
            >
              <img className="userInnerImg" src={row?.image} />
              <div className="ms-2">
                <span className="textParagh listhead_">{row?.name}</span>
              </div>
            </div>
          </>
        );
      },
      width: "250px",
    },
    // {
    //   name: 'POS Price',
    //   selector: row => {
    //     return (
    //       <>
    //         <span className='dateapoint d-flex justify-content-center' onClick={() => handleRowClick(row)}>{`${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "pos") ? '$' : ''}${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "pos")?.selling_price ?? "-"}`}</span>
    //       </>
    //     );
    //   },
    //   width: "150px",
    // },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center pe-3 ps-3">
              <div className="dropdown tableDropdown ms-3 ">
                <span
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div
                  className="dropdown-menu tableDropdownMenus categoryDrop"
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setModalDetail({ show: true, flag: "MyProductDetials" });
                      setKey(Math.random());
                      setSelectedProduct(row);
                    }}
                  >
                    View
                  </li>
                  {/* <li
                    className="dropdown-item"
                    onClick={() => {
                      setModalDetail({ show: true, flag: "editVarients" });
                      setKey(Math.random());
                      setSelectedProduct(row);
                    }}
                  >
                    Edit Supply
                  </li> */}
                  {/* <li className='dropdown-item'>Active</li> */}
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      history.push({ pathname: `/editProducts`, state: row })
                    }
                  >
                    Edit
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => deleteHandle(row)}
                  >
                    Delete
                  </li>
                </div>
              </div>
            </div>
          </>
        );
      },
      width: "100px",
    },
  ];

  if (props.columnStatus.upc) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        name: "SKU",
        selector: (row) => {
          return (
            <>
              <span
                className="textParagh listhead_ "
                onClick={() => handleRowClick(row)}
              >
                {row?.sku}
              </span>
            </>
          );
        },
        width: "150px",
      },
      columns1[columns1.length - 1],
    ];
  }

  if (props.columnStatus.barcode) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        name: "UPC",
        selector: (row) => {
          return (
            <>
              <span
                className="textParagh listhead_ "
                onClick={() => handleRowClick(row)}
              >
                {row?.barcode}
              </span>
            </>
          );
        },
        width: "150px",
      },
      columns1[columns1.length - 1],
    ];
  }

  if (props.columnStatus.locations) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        name: "Location",
        selector: (row) => {
          let selectedAddressIds = row?.supplies?.map(
            (v) => v?.seller_address_id
          );
          let serviceSelected = props?.addressData
            ?.filter((value) => selectedAddressIds?.includes(value?.id))
            ?.map((v, i) => {
              return v?.format_address;
            });
          return (
            <>
              <span
                className="textParagh listhead_"
                onClick={() => handleRowClick(row)}
              >
                {serviceSelected?.toString()}
              </span>
            </>
          );
        },
        width: "200px",
      },
      columns1[columns1.length - 1],
    ];
  }

  // if (props.columnStatus.availableToSell) {
  //   columns1 = [
  //     ...columns1.slice(0, columns1.length - 1),
  //     {
  //       name: "Available to Sell",
  //       selector: (row) => {
  //         console.log(row,"dddddddddddddddddddddddddaaaaaaaaaaaaaa");
  //         return (
  //           <>
  //             <span
  //               className="textParagh listhead_"
  //               onClick={() => handleRowClick(row)}
  //             >
  //               {row?.supplies ? row?.supplies[0]?.rest_quantity : ""}
  //             </span>
  //           </>
  //         );
  //       },
  //       width: "200px",
  //     },
  //     columns1[columns1.length - 1],
  //   ];
  // }

  if (props.columnStatus.category) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.category?.name?.length > 15
                  ? row?.category?.name?.substring(0, 15) + "..."
                  : row?.category?.name}
              </span>
            </>
          );
        },
        width: "160px",
        name: "Category",
      },
      columns1[columns1.length - 1],
    ];
  }
  if (props.columnStatus.subCategory) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        name: "Sub-Category",
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.sub_category?.name?.length > 15
                  ? row?.sub_category?.name?.substring(0, 15) + "..."
                  : row?.sub_category?.name}
              </span>
            </>
          );
        },
        width: "160px",
      },
      columns1[columns1.length - 1],
    ];
  }
  if (props.columnStatus.brand) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.brand?.name?.length > 15
                  ? row?.brand?.name?.substring(0, 15) + "..."
                  : row?.brand?.name}
              </span>
            </>
          );
        },
        name: "Brand",
        width: "250px",
      },
      columns1[columns1.length - 1],
    ];
  }
  // if (props.columnStatus.stock) {
  //   columns1 = [
  //     ...columns1.slice(0, columns1.length - 1),
  //     {
  //       selector: (row) => {
  //         return (
  //           <>
  //             <span className="textParagh " onClick={() => handleRowClick(row)}>
  //               {row?.supplies ? row?.supplies[0]?.total_quantity : ""}
  //             </span>
  //           </>
  //         );
  //       },
  //       name: "Stock",
  //       width: "150px",
  //     },
  //     columns1[columns1.length - 1],
  //   ];
  // }
  // if (props.columnStatus.price) {
  //   columns1 = [...columns1.slice(0, columns1.length - 1),
  //   {
  //     name: 'Online Price',
  //     selector: row => {
  //       return (
  //         <>
  //           {/* <span className='dateapoint ' onClick={() => handleRowClick(row)}>{`${row?.price ? '$' : ''}${row?.price ? row?.price : ""}`}</span> */}
  //           <span className='dateapoint ' onClick={() => handleRowClick(row)}>{`${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "b2c") ? '$' : ''}${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "b2c")?.selling_price ?? "-"}`}</span>
  //         </>
  //       );
  //     },
  //     width: "140px",
  //   },
  //   columns1[columns1.length - 1],
  //   ]
  // }
  if (props.columnStatus.weight) {
    columns1 = [
      ...columns1.slice(0, columns1.length - 1),
      {
        name: "Weight",
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.weight && row?.weight != 0 ? row?.weight + row?.weight_unit:"--"}
              </span>
            </>
          );
        },
        width: "100px",
      },
      columns1[columns1.length - 1],
    ];
  }

  const handleRowClick = (e) => {
    // setSelectedProduct(e);
    // setModalDetail({ show: true, flag: "MyProductDetials" });
    // setKey(Math.random());
  };

  const deleteHandle = (row) => {
    Swal.fire({
      title: "Do you want to delete the product?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            product_ids: [row?.id],
          },
          tokenData: auth?.payload?.token,
        };
        setDeleteLoading(true);
        dispatch(deleteProductApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getProductList();
            setDeleteLoading(false);
            toast.success(obj?.msg);
          })
          .catch((obj) => {
            setDeleteLoading(false);
          });
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCallPagination(true);
    // props?.setValue(true)
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
    setCallPagination(true);
    // props?.setValue(true)
  };

  useImperativeHandle(ref, () => ({
    getProductListParent() {
      setLoading(true);
      let params = {
        tokenData: auth?.payload?.token,
        postData: {
          seller_id: seller_id
            ? seller_id
            : auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          type: "physical",
          app_name: "merchant",
          lat: 32.5534,
          long: 76.1258,
          category_ids: props?.categoryId ? props?.categoryId : [],
          sub_category_ids: props?.subCategoryId
            ? (props?.subCategoryId).toString()
            : [],
          brand_id: props?.brandId ? (props?.brandId).toString() : [],
          page: currentPage,
          filter_deleted: true,
          limit: perPage,
        },
      };
      dispatch(getProductServiceListApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          setProductList(obj?.payload?.data);
          setTotalRows(obj?.payload?.total);
          setCallPagination(false);
        })
        .catch((obj) => {
          setLoading(false);
        });
    },
  }));

  const getProductList = () => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "physical",
        app_name: "merchant",
        lat: 32.5534,
        long: 76.1258,
        category_ids: props?.categoryId ? props?.categoryId : [],
        sub_category_ids: props?.subCategoryId
          ? (props?.subCategoryId).toString()
          : [],
        brand_id: props?.brandId ? (props?.brandId).toString() : [],
        page: currentPage,
        limit: perPage,
      },
    };
    if (props?.startDate != null && props?.endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(props?.startDate).format("YYYY-MM-DD"),
        end_date: moment(props?.endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    } else {
      params = {
        tokenData: auth?.payload?.token,
        postData: {
          seller_id: seller_id
            ? seller_id
            : auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          type: "physical",
          app_name: "merchant",
          lat: 32.5534,
          long: 76.1258,
          category_ids: props?.categoryId ? props?.categoryId : [],
          sub_category_ids: props?.subCategoryId
            ? (props?.subCategoryId).toString()
            : [],
          brand_id: props?.brandId ? (props?.brandId).toString() : [],
          page: currentPage,
          filter_deleted: true,
          limit: perPage,
        },
      };
    }
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setProductList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCallPagination(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let subcatdata = props?.subCategoryId;
    setOldSubCatIds(subcatdata);
    let branddata = props?.brandId;
    setOldBrandIds(branddata);
    setOldStartDate(props?.startDate);
    setOldEndDate(props?.endDate);
    const areCategoryEqual =
      props?.subCategoryId?.length === oldSubcatIds?.length &&
      oldSubcatIds?.every(
        (value, index) => value === props?.subCategoryId[index]
      );
    const areBrandEqual =
      props?.brandId?.length === oldBrandIds?.length &&
      oldBrandIds?.every((value, index) => value === props?.brandId[index]);

    if (
      props?.subCategoryId?.length > 0 &&
      props.statusCategory === true &&
      areCategoryEqual === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.subCategoryId?.length > 0 &&
      props.statusCategory === false &&
      areCategoryEqual === true
    ) {
      getProductList();
    }
    if (
      props?.endDate == null &&
      props?.subCategoryId?.length === 0 &&
      props?.brandId?.length === 0 &&
      props.statusCategory === false &&
      props.statusBrand === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.subCategoryId?.length === 0 &&
      props?.brandId?.length === 0 &&
      props.statusCategory === false &&
      props.statusBrand === false
    ) {
      getProductList();
    }
    if (
      props?.brandId?.length > 0 &&
      props.statusBrand === true &&
      areBrandEqual === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.brandId?.length > 0 &&
      props.statusBrand === false &&
      areBrandEqual === true
    ) {
      getProductList();
    }
    if (callPagination === true) {
      getProductList();
    }
    if (
      (areCategoryEqual === false && props.statusCategory === true) ||
      (areBrandEqual === false && props.statusBrand === true)
    ) {
      getProductList();
    }
  }, [
    currentPage,
    perPage,
    props?.subCategoryId,
    props?.brandId,
    props?.endDate,
  ]);

  // useEffect(() => {
  //   getProductList();
  // }, [props?.startDate, props?.endDate])

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
    props?.setSelectedRows(state.selectedRows);
  };

  console.log(selectedRows, "selected rows");
  return (
    <>
      <section className="recentTransections table-responsive mt-1 commonTableheight tableItemData">
        {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
        <DataTable
          columns={columns1}
          //  data={props.ProductList}
          data={ProductDatas}
          progressPending={loading}
          onRowClicked={handleRowClick}
          customStyles={customStyles}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          // customRowComponent={(row) => <CustomRow {...row} onClick={handleRowClick} />}
          pagination
          paginationServer
          paginationTotalRows={productData?.payload?.total}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          selectableRows
          onSelectedRowsChange={handleRowSelected} // Callback for row selection
        />
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "MyProductDetials"
            ? "myProductDetails"
            : modalDetail.flag === "editVarients"
            ? "EditVarients"
            : ""
        }
        size={
          modalDetail.flag === "MyProductDetials" ||
          modalDetail.flag === "editVarients"
            ? "xl"
            : "md"
        }
        child={
          modalDetail.flag === "editVarients" ? (
            <EditVarientsModal
              close={() => handleOnCloseModal()}
              getProductList={() => getProductList()}
              type="product"
              selectedProduct={selectedProduct}
            />
          ) : modalDetail.flag === "MyProductDetials" ? (
            <MyProductDetials
              close={() => handleOnCloseModal()}
              type="product"
              selectedProductId={selectedProduct?.id}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "editVarients" ? (
            <>
              <div className="headerLeft">
                <h4 className="modalHeading_ me-3">Edit Supply</h4>
                <button
                  onClick={() =>
                    history.push({
                      pathname: `/createProductSupply`,
                      state: selectedProduct,
                    })
                  }
                  className="btn btn-primary"
                >
                  Add Supply
                </button>
              </div>

              <p
                style={{ cursor: "pointer" }}
                onClick={handleOnCloseModal}
                className="modal_cancel"
              >
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
});

export default ProductListTable;
