import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { backArrow, smallProduct, galleryImg, penIcon, doubleTick, galleryUpload, dots } from '../../../utilities/images'
import parse from 'html-react-parser';
import { getAddressApiAsync, selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getProductListByIdAsync } from './productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from '../../components/shared/CustomModal';
import DataTable from 'react-data-table-component';
import EditProductModal from './EditProductModal';
import { addThousandSeparator } from '../../../utilities/thousandCommaSeparated';

const MyProductDetials = (props) => {

  // const product = props?.selectedProduct;
  console.log("propsProductdetails", props?.serviceType);
  const [selectedProduct, setSelectedProduct] = useState("")
  const [address, setAddress] = useState([])
  const [product, setProduct] = useState(props?.selectedProduct ? props?.selectedProduct : "");
  console.log(product, 'pppppppppppppppppppppppppppppp');
  const [activeTab, setActiveTab] = useState('product1');
  const [selectedImage, setSelectedImage] = useState(product?.image);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const history = useHistory();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const columns1 = [
    {
      name: 'Seller Address',
      selector: row => address?.find(item => item?.id == row?.seller_address_id)?.format_address,
      width: "300px"
    },
    {
      name: "Sold quantity",
      selector: row => {
        return (
          <>
            <span className='textParagh listhead_' onClick={() => handleRowClick(row)}>{row?.sold_quantity}</span>
          </>
        );
      },
      width: "150px",
    },
    {
      name: 'Rest Quantity',
      selector: row => {
        return (
          <>
            <span className='textParagh listhead_' onClick={() => handleRowClick(row)}>{row?.rest_quantity}</span>
          </>
        );
      },
      width: "150px",
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={() => handleRowClick(row)}>{row?.total_quantity}</span>
          </>
        );
      },
      width: "160px",
      name: 'Total Quantity'
    },
    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={() => handleRowClick(row)}>{row?.status === true ? "Active" : "Inactive"}</span>
          </>
        );
      },
      width: "150px",
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh productindetail_' onClick={() => handleRowClick(row)}>{
              row?.supply_prices?.map((val, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className='textInnerHead px-3'>App Name: <span className='mainlightHead'>{val?.app_name} </span></p>
                    <p className='textInnerHead px-3'>Selling Prices: <span className='mainlightHead'>${val?.selling_price ? Number(val?.selling_price)?.toFixed(2) : 0} </span></p>
                  </React.Fragment>
                )
              })
            }</span>
          </>
        );
      },
      name: 'Supply Prices',
      width: "250px"
    },
  ];

  const getProductListById = (id) => {
    setLoading(true)
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        id: id,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        // seller_id:"deca13cf-3f00-4776-9bfc-30a29c40af2f",
        app_name: "merchant",
        need_pos_users: true
      }
    }
    dispatch(getProductListByIdAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.payload?.product_detail, 'objjjjjjjjjj');
        setProduct(obj?.payload?.product_detail);
        const paramsData = {
          sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
          token: auth?.payload?.token
        }
        dispatch(getAddressApiAsync(paramsData))
          .then(unwrapResult)
          .then((obj1) => {
            setLoading(false)
            setAddress(obj1?.payload)
          })
          .catch((obj) => {
            setLoading(false)
          })
        // setProductList(obj?.payload?.data)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const handleRowClick = (e) => {
    setSelectedProduct(e);
    setModalDetail({ show: true, flag: "editInventory" });
    setKey(Math.random());
  }

  console.log(product, "selected product")

  const handleSave = (editedData) => {
    // Handle saving the edited data
    setProduct(editedData);
    console.log('Saved data:', editedData);
  };

  useEffect(() => {
    if (props.selectedProductId) {
      getProductListById(props.selectedProductId);
    }
  }, []);

  return (
    <div className='productDetialsView'>
      {loading ?
        // <span className="spinner-border spinner-border-sm serviceList"></span>
        <span className="spinner-border inner-spin spinner-border-sm"></span>
        : <>
          <div className='productDetialsHead'>
            <div className='d-flex align-items-center'>
              <h1 className='modalHeading_'>
                <span><Link to="#" onClick={() => { props.close() }}> <i className="las la-angle-left me-3 backArrowImg_"></i> </Link></span>
                {product?.name}</h1>
            </div>
            {/* <button className='termseditBtn' onClick={()=>history.push({pathname:'/editProducts' , state : product })}><img src={penIcon} alt="image" className='me-1' /> Edit</button> */}
          </div>
          <div className='productviewInfo'>
            <div className='row '>
              <div className='col-md-8'>
                <div className='d-flex align-items-center'>
                  <h1 className='textInnerHead me-2'>{props?.serviceType ? "Service Name:" : "Product Name:"}</h1>
                  <span className='subHeading'> {product?.name}</span>
                </div>
                <div className='productBrand'>
                  {product?.type == "physical" &&
                    <p className='textInnerHead mt-2'>Brand : <span className='mainlightHead'>{product?.brand?.name ?? "---"} </span></p>}
                  <p className='textInnerHead mt-2'>Category : <span className='mainlightHead'>{product?.category?.name} </span></p>
                  <p className='textInnerHead mt-2'>Sub-category : <span className='mainlightHead'> {product?.sub_category?.name ? product?.sub_category?.name : "--"}</span></p>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <h1 className='textInnerHead'>Details :</h1>
                    <p className='textinner_ '>{product?.description ? parse(product?.description) : ""} </p>
                  </div>
                </div>
                {
                  product?.type == "digital" &&
                  <div className='my-2'>
                    <h1 className='textInnerHead mb-2'>Approx Service Time</h1>
                    <p className='mainlightHead mb-4'>{product?.supplies ? `${product?.supplies[0]?.approx_service_time} min` : ""}</p>
                  </div>

                }
                {
                  product?.type == "digital" &&
                  <div className='my-2'>
                    <h1 className='textInnerHead mb-2'>POS User</h1>
                    <p className='mainlightHead mb-4'>{
                      product?.pos_staff?.length > 0 ?
                        product?.pos_staff?.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              {v?.user?.user_profiles?.firstname + (i === product?.pos_staff?.length - 1 ? "" : ", ")}
                            </React.Fragment>
                          )
                        }) : "No POS user"
                    }</p>
                  </div>

                }
                {
                  product?.type === "physical" ?
                    <>
                      <h1 className='storeHeading mt-3'>Physical Product</h1>
                      <p className='textinner_ mb-1'>Barcode (ISBN, UPC, GTIN, etc.)</p>
                      <p className='subtextDark_'>{product?.barcode}</p>
                      <p className='textinner_ mb-1'>SKU</p>
                      <p className='subtextDark_'>{product?.sku ? product?.sku : "--"}</p>
                      <h1 className='storeHeading mt-2'>Shipping or Pickup</h1>
                      <ul className="otherMainus">
                        {product?.supplies?.length > 0 ? product?.supplies[0]?.delivery_options?.split(",")?.map((v, i) => {
                          return (
                            <>
                              {
                                v === '2' ? <></> : <li key={i} className="otherItems">
                                  <img src={doubleTick} className="" alt="" />
                                  <span className="">{v == 1 ? "Delivery" : v == 3 ? "Pickup" : v == 4 ? "Shipping" : ""} </span>
                                </li>
                              }
                            </>


                          )
                        }) : <></>}
                      </ul>
                    </> : <></>}

                {/* <div className='productSelling'>
                {product?.supplies[0]?.supply_prices?.length > 0 ?
                  <>
                    <h1 className='textInnerHead'>Selling Price</h1>
                    <ul className="productSellingList">
                      {product?.supplies[0]?.supply_prices?.map((val, i) => {
                        return (
                          <li key={i}>
                            <p className='storeHeading'>JOBR {val?.app_name?.toUpperCase()} <span className='textinner_'><i>{val?.app_name === "pos" ? "(Physical Store)" : val?.app_name === "b2c" ? "(Online Store)" : (val?.app_name === "b2b" && val?.price_type === "quantity_base") ? "(Online Store) Price based on Quantity" : (val?.app_name === "b2b" && val?.price_type === "fixed") ? "(Online Store) Fixed" : ""} </i></span></p>
                            {val.price_type == "quantity_base" ?
                              <table className='sellingTable'>
                                <tr>
                                  <th><p className='textinner_'>Qyt</p></th>
                                  <td>{val?.min_qty} -{val?.max_qty}</td>
                                </tr>
                                <tr>
                                  <th><p className='textinner_'>Price</p></th>
                                  <td>${val?.selling_price}</td>
                                </tr>
                              </table> :
                              <p className='textinner_'>${val?.selling_price} </p>
                            }
                          </li>
                        )
                      })}
                    </ul>
                  </> : <></>}
              </div> */}

              </div>
              <div className='col-md-4'>
                <div className='productDetialsRight'>
                  <div className='productImageView'>
                    <img src={selectedImage ? selectedImage : product?.image} className="custProductImg_" alt="image" />

                    <ul className='previewList_ mt-3' >
                      {product?.product_images && product?.product_images.map((item, index) => {
                        return (
                          <li className={`${activeTab == item?.url ? 'active' : ''} listImage_`}
                            onClick={() => { setActiveTab(item?.url); setSelectedImage(item?.url) }} key={index}>
                            <img className='smallproduct smallproductImg' src={item?.url} /> </li>
                        )
                      })}
                    </ul>
                  </div>
                  {product?.supplies && product?.supplies[0]?.supply_prices?.length > 0 ?
                    <>
                      <h1 className='storeHeading mt-3'>{props?.serviceType ? "Service Availability" : "Product availability"}</h1>
                      <ul className="otherMainus">
                        {product?.supplies[0]?.supply_prices?.filter(val => (val?.app_name === "pos"))?.map((v, i) => {
                          return (
                            <li key={i} className="otherItems">
                              <img src={doubleTick} className="" alt="" />
                              <span className="">JOBR {v?.app_name?.toUpperCase()} (Physical store) </span>
                            </li>
                          )
                        })}
                        {product?.supplies[0]?.supply_prices?.filter(val => (val?.app_name === "b2b" || val?.app_name === "b2c"))?.length > 0 ?
                          <li className="otherItems">
                            <img src={doubleTick} className="" alt="" />
                            <span className="">JOBR Online Store </span>
                            <p className="innerStore"><img src={doubleTick} className="" alt="" /> JOBR {product?.supplies[0]?.supply_prices?.find(val => (val?.app_name === "b2b"))?.app_name?.toUpperCase()} {product?.supplies[0]?.supply_prices?.filter(val => (val?.app_name === "b2b" || val?.app_name === "b2c"))?.length > 1 ? "&" : ""} {product?.supplies[0]?.supply_prices?.find(val => (val?.app_name === "b2c"))?.app_name?.toUpperCase()} </p>
                          </li>
                          : ""
                        }

                        {
                          product?.product_ingredient?.length > 0 ?
                            <div className='productAttibute'>
                              <h1 className='storeHeading'>Attributes</h1>
                              {product?.product_ingredient?.map((v, i) => {
                                return (
                                  <p key={i} className='productHeading'>{v?.ingredient?.name}: <span className='mainlightHead'> {v?.ingredient?.description}</span></p>
                                )
                              })}
                            </div> : ""
                        }
                      </ul>
                    </>
                    : <></>}
                </div>
              </div>
            </div>
          </div>

          {/* {
            // product?.type != "digital" &&
            <div className='row mt-1'>
              <div className='col-md-12'>
                <div className='productSelling'>
                  <h1 className='textInnerHead'>Inventory Details
                    <button className='termseditBtn' type="button" onClick={() => {
                      setModalDetail({ show: true, flag: "editInventory" });
                      setKey(Math.random());
                    }}><img src={penIcon} alt="image" className='me-1' /> Edit</button>
                  </h1>
                  <section className='recentTransections table-responsive mt-1'>
                    <DataTable
                      columns={columns1}
                      data={product?.supplies}
                      progressPending={loading}
                      onRowClicked={handleRowClick}
                      customStyles={customStyles}
                    />
                  </section>
                </div>
              </div>
            </div>
          } */}
          <section className='recentTransections productviewInfo mt-4'>
            <div className='row mt-1'>
              <div className='col-md-12'>
                <div className='productSelling'>
                  {product?.supplies?.length > 0 ?
                    product?.supplies?.map((v, i) => {
                      console.log(v,"prodctView");
                      return (
                        <React.Fragment key={i}>
                          <div className='d-flex align-items-center justify-content-space-between'>
                            <h1 className='textInnerHead'>Address: {address?.find(item => item?.id == v?.seller_address_id)?.format_address}</h1>
                            {/* <button className='backgroundFillbtn ms-auto' onClick={() => history.push({ pathname: `/${props?.type == "product" ? "editProductSupply" : "editServiceSupply"}`, state: { ...v, product } })}>Edit Supply</button> */}
                          </div>
                          <div className="table-responsive mt-3">
                            <table className="table  listItemsContainer">
                              <thead className="productHead_">
                                <th className="dateapoint">#</th>
                                {props?.selectedProduct?.type === 'digital' ? <></> : <th className="dateapoint text-center">Cost Price</th>}

                                {
                                  props?.serviceType ?
                                    <></> :
                                    <>
                                      {props?.selectedProduct?.type === 'digital' ? <></> : <th className="dateapoint text-center">Sold Quantity</th>}
                                      {props?.selectedProduct?.type === 'digital' ? <></> : <th className="dateapoint text-center">Rest Quantity</th>}
                                      {props?.selectedProduct?.type === 'digital' ? <></> : <th className="dateapoint text-center">Total Quantity</th>}
                                    </>
                                }

                                <th className="dateapoint text-center">Status</th>
                                <th className="dateapoint text-center">Supply Prices</th>
                              </thead>
                              <tbody>
                                <tr role="row">
                                  <td className="text-start">{i + 1}</td>
                                  {props?.selectedProduct?.type === 'digital' ? <></> : <td className="text-center">
                                    <div className="name_content">
                                      <h6 className="contentheadtext_"> ${Number(v?.supply_variants[0]?.cost_price)?.toFixed(2)}</h6>
                                      {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                                    </div>
                                  </td>}

                                  {
                                    props?.serviceType ?
                                      <></>
                                      :
                                      <>
                                        {props?.selectedProduct?.type === 'digital' ? <></> : <td className="text-center">
                                          <div className="name_content">
                                            <h6 className="contentheadtext_"> {v?.supply_variants[0]?.sold_quantity}</h6>
                                            {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                                          </div>
                                        </td>}
                                        {props?.selectedProduct?.type === 'digital' ? <></> : <td className="text-center">
                                          <p className="contentheadtext_">{v?.supply_variants[0]?.rest_quantity}</p>
                                        </td>}
                                        {props?.selectedProduct?.type === 'digital' ? <></> : <td className="text-center">
                                          <p className="contentheadtext_">{v?.supply_variants[0]?.total_quantity}</p>
                                        </td>}
                                      </>
                                  }


                                  <td className="text-center">
                                    <p className="itemsTxt">{v?.status === true ? "Active" : "Inactive"}</p>
                                  </td>
                                  <td className="text-center">
                                    <p className="itemsTxt"><span className='textParagh productindetail_'>{
                                      v?.supply_variants[0]?.supply_variant_prices?.map((val, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <p className='textInnerHead px-3'>App Name: <span className='mainlightHead'>{val?.app_name} </span></p>
                                            <p className='textInnerHead px-3'>Selling Prices: <span className='mainlightHead'>${val?.selling_price ? Number(val?.selling_price)?.toFixed(2) : 0} </span></p>
                                          </React.Fragment>
                                        )
                                      })
                                    }</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {
                            props?.serviceType ? <></> :
                              v?.supply_variants?.length > 0 && v?.supply_variants?.every((val) => val?.product_variant_id) &&(
                                <div className="table-responsive">
                                  <table className="table  listItemsContainer">
                                    <thead className="productHead">
                                      <th className="dateapoint text-startr">#</th>
                                      <th className="dateapoint text-center">Image/Name</th>
                                      <th className="dateapoint text-center">Price</th>
                                      <th className="dateapoint text-center">Stock</th>
                                      <th className="dateapoint text-center">UPC</th>
                                      <th className="dateapoint text-center">Weight</th>
                                    </thead>
                                    <tbody>
                                      {
                                        v?.supply_variants?.map((val, ind) => {
                                          return (
                                            <tr key={ind} role="row">
                                              <td className="text-start">{ind + 1}</td>
                                              <td className="text-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                  <div className='imgFileBox me-2'>
                                                    <label className="" htmlFor="file-input">
                                                      <span className='galleryCircle me-2'>
                                                        <img src={val?.product_variant?.image} alt='' className='' />
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className='variantItemsContent'>
                                                    {/* <p className='subtextDark_'>{JSON.parse(val?.attribute_variant?.variants)[0]?.attribute_name + "/" + JSON.parse(val?.attribute_variant?.variants)[0]?.attribute_value_name}</p> */}
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                {/* <p className="contentheadtext_">${Number(val?.price)?.toFixed(2)}</p> */}
                                                <p className="itemsTxt"><span className='textParagh productindetail_'>{
                                                  val?.supply_variant_prices?.map((val, index) => {
                                                    console.log(val,"dddddddddddddddddddddddddddddaaaaaaaaaaaaaaa");
                                                    return (
                                                      <React.Fragment key={index}>
                                                        <p className='textInnerHead px-3'>App Name: <span className='mainlightHead'>{val?.app_name} </span></p>
                                                        <p className='textInnerHead px-3'>Selling Prices: <span className='mainlightHead'>${val?.selling_price ? Number(val?.selling_price)?.toFixed(2) : 0} </span></p>
                                                      </React.Fragment>
                                                    )
                                                  })
                                                }</span>
                                                </p>
                                              </td>
                                              <td className="text-center">
                                                <p className="contentheadtext_">{val?.rest_quantity}</p>
                                              </td>
                                              <td className="text-center">
                                                <p className="contentheadtext_">{val?.product_variant?.upc}</p>
                                              </td>
                                              <td className="text-center">
                                                <p className="contentheadtext_">{val?.product_variant?.weight}</p>
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </div>)}
                        </React.Fragment>
                      )
                    }) : <></>
                  }
                </div>
              </div>
            </div>
          </section>

        </>
      }

      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "editInventory" ? "myProductDetails" : ""}
        size={modalDetail.flag === "editInventory" ? "xl" : "md"}
        child={modalDetail.flag === 'editInventory' ? <EditProductModal productId={props.selectedProductId} close={() => handleOnCloseModal()} handleSave={(e) => handleSave(e)} selectedProduct={selectedProduct} />
          : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div >
  )
}

export default MyProductDetials