import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { helpCenter, setupGuide, topics, bellring, nodata } from '../../../utilities/images';
import PlansModal from './plansModal';
import CustomModal from '../../components/shared/CustomModal';
import { useHistory } from 'react-router-dom';
import FindProductModal from './findProductModal';
import AddBalanceModal from './addBalanceModal';
import { getUserByIdApiAsync, selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { getWalletBallanceApiAsync, getMerchantStepStatusAsync, getMerchantActiveSubscriptionAsync, selectMerchantStatus } from '../dashboard/dashboardSlice';
import DashboardNavbar from '../dashboard/DashboardNavbar';

const ScalePlan = () => {
  const pathname = window.location.pathname;
  const dispatch = useDispatch()
  document.title = "Plans"
  const [activeTab, setActiveTab] = useState("createWallet")
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState('');
  const merchantStatus = useSelector(selectMerchantStatus)
  console.log(merchantStatus, 'statussssssss');
  const history = useHistory('')
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });

  const details = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  }

  const createwalletHandle = () => {
    if (merchantStatus?.payload?.wallet_status === true) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("You already have a wallet account");
      }
      return false
    }
    history.push(`/create-wallet`)
  }

  const pickPlanHandle = () => {
    if (merchantStatus?.payload?.wallet_status === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please create your wallet first");
      }
      return false
    }
    setModalDetail({ show: true, flag: "PickPlan" });
    setKey(Math.random());
  }

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const getMerchantStepStatus = () => {
    setLoading(true)
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        obj?.payload?.wallet_status === true ? setLoading(true) : setLoading(false)
        if (obj?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token
          }
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance)
              setLoading(false)
            })
            .catch((obj) => {
              setLoading(false)
            })
        }
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getMerchantStepStatus()
  }, [])

  return (
    <>
      <DashboardNavbar title="" />
      <div className="loading-area d-none ">
        <div className="loader1">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {loading === true ?
        <span className="spinner-border inner-spin spinner-border-sm"></span>
        :
        <div className='row'>
          <div className='col-xl-9 col-lg-9 col-md-9'>
            <div className='scalePlans'>

              {/* {
            merchantStatus?.subscription_status === true ?
              <></> :
              <>
                <div className='pickPlanContainer mb-4'>
                  <div className='row align-items-center'>
                    <div className='col-md-9'>
                      <p className='pickPlanDes mb-0'>
                        Build your dream business at your own pace. Enjoy 3 months of JOBR for $1/month when you sign up for a monthly Basic or Starter plan.
                      </p>
                    </div>
                    <div className='col-md-3 text-end'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <button className='pickBtn me-4'
                          onClick={() => pickPlanHandle()}
                        >Pick a Plan</button>
                        <i className="las la-times"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          } */}

              <Accordion defaultActiveKey="0" className='mb-4' flush>
                <Accordion.Item eventKey="0" className='backContainer'>
                  <Accordion.Header>
                    <div className=''>
                      <h3 className='subheading_'>Setup your business</h3>
                      <p className='agentHeading mt-2 mb-0'>Use this personalized guide to get your store up and running.</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className='mt-4'>
                    <div className='plansContainer'>
                      <p className='smallText mb-3'>{Number(merchantStatus?.payload?.completed_step_count) - 1} of 3 tasks complete</p>
                      {/* Start InnerCollapse */}
                      <Accordion defaultActiveKey={
                        (merchantStatus?.payload?.wallet_status === false)?
                        "4":
                        (merchantStatus?.payload?.wallet_status === true && merchantStatus?.payload?.product_status === false && merchantStatus?.payload?.subscription_status === false)?
                        "0"
                        :
                        (merchantStatus?.payload?.wallet_status === true && merchantStatus?.payload?.subscription_status === true && merchantStatus?.payload?.product_status === false)?
                        "3":
                        ""
                      }>

                        <Accordion.Item eventKey="4" className={`plansItems ${merchantStatus?.payload?.wallet_status === true ? 'filled' : ""} ${(activeTab == "CreateWallet" || (merchantStatus?.payload?.wallet_status === false)) ? "active" : ""}`} onClick={() => setActiveTab("CreateWallet")}>
                          <Accordion.Header>
                            <label className='borderCircle me-3'></label>
                            <h4 className='textInnerDark mb-1'>Configure JBR wallet</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className='smallText mb-3'>Before you open your store, first you need to create wallet.</p>
                            {merchantStatus?.payload?.wallet_status === true ? <></> : <button className='submitBtn' onClick={() => { merchantStatus?.payload?.wallet_status === true ? void (0) : createwalletHandle() }}>Set your Wallet base</button>}
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="0" className={`plansItems ${merchantStatus?.payload?.subscription_status === true ? 'filled' : ""} ${(activeTab == "ChooseYourPlan" || (merchantStatus?.payload?.wallet_status === true && merchantStatus?.payload?.product_status === false)) ? "active" : ""}`} onClick={() => setActiveTab("ChooseYourPlan")}>
                          <Accordion.Header>
                            <label className='borderCircle me-3'></label>
                            <h4 className='textInnerDark mb-1'>Choose Your Plan</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className='smallText mb-3'>Before you open your store, first you need to choose plan.</p>
                            {merchantStatus?.payload?.subscription_status === true ? <></> : <button className='submitBtn' onClick={() => { (merchantStatus?.payload?.wallet_status === false) ? toast.error("Please create your wallet first") : (merchantStatus?.payload?.subscription_status === true && merchantStatus?.payload?.wallet_status === true) ? void (0) : pickPlanHandle() }}> Choose Your Plan </button>}
                          </Accordion.Body>
                        </Accordion.Item>

                        {/* <Accordion.Item eventKey="1" className={`plansItems ${activeTab == "JBRwallet" ? "active" : ""}`} onClick={() => setActiveTab("JBRwallet")}>
                      <Accordion.Header>
                        <label className='borderCircle me-3'></label>
                        <h4 className='textInnerDark mb-1'>Configure JBR wallet</h4>
                      </Accordion.Header>
                      <Accordion.Body className=''>
                        <p className='smallText'>Before you open your store, first you need some products.</p>
                        <button className='submitBtn' onClick={() => history.push('/payments')}>Configure JBR wallet</button>
                      </Accordion.Body>
                    </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="2" className={`plansItems ${merchantStatus?.payload?.address_status === true ? 'filled' : ""} ${activeTab == "SetupAddresses" ? "active" : ""}`} onClick={() => setActiveTab("SetupAddresses")}>
                          <Accordion.Header>
                            <label className='borderCircle me-3'></label>
                            <h4 className='textInnerDark mb-1'>Setup Addresses</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className='smallText mb-3'>Before you open your store, first you need some products.</p>
                            {merchantStatus?.payload?.address_status === true ? <></> : <button className='submitBtn' onClick={() => { history.push({ pathname: '/locations', state: "plans" }) }}>Setup your business addresses</button>}
                          </Accordion.Body>
                        </Accordion.Item> */}

                        <Accordion.Item eventKey="3" className={`plansItems ${merchantStatus?.payload?.product_status === true ? 'filled' : ""} ${(activeTab == "AddProducts" || (merchantStatus?.payload?.wallet_status === true && merchantStatus?.payload?.subscription_status === true && merchantStatus?.payload?.product_status === false)) ? "active" : ""}`} onClick={() => setActiveTab("AddProducts")}>
                          <Accordion.Header>
                            <label className='borderCircle me-3'></label>
                            <h4 className='textInnerDark mb-1'>Add Products</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className='smallText mb-3'>Before you open your store, first you need some products.</p>
                            {merchantStatus?.payload?.product_status === true ? <></> : <button className='submitBtn' onClick={() => {
                              if (merchantStatus?.payload?.product_status === true || merchantStatus?.payload?.subscription_status === false || merchantStatus?.payload?.wallet_status === false || merchantStatus?.payload?.address_status === false) {
                                void (0)
                              }
                              else {
                                setModalDetail({ show: true, flag: "FindProduct" });
                                setKey(Math.random());
                              }
                            }}>Find your Products</button>}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {/* End InnerCollapse */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* <Accordion defaultActiveKey="1" className='mb-4' flush>
                <Accordion.Item eventKey="1" className='backContainer'>
                  <Accordion.Header>
                    <div className=''>
                      <h3 className='subheading_'>Explore more support</h3>
                      <p className='agentHeading mb-0'>Check out these resources for answers to your questions, videos, and best practices.</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className='mt-4'>
                    <div className='plansContainer moreExport'>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0" className={`plansItems ${activeTab == "SetupGuide" ? "active" : ""}`} onClick={() => setActiveTab("SetupGuide")}>
                          <Accordion.Header>
                            <img src={setupGuide} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Setup Guide</h4>
                              <p className='smallText mb-0'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className=''>

                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className={`plansItems ${activeTab == "HelpCenter" ? "active" : ""}`} onClick={() => setActiveTab("HelpCenter")}>
                          <Accordion.Header>
                            <img src={helpCenter} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Help Center</h4>
                              <p className='smallText mb-0'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={`plansItems ${activeTab == "AskTopic" ? "active" : ""}`} onClick={() => setActiveTab("AskTopic")}>
                          <Accordion.Header>
                            <img src={topics} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Ask us about a topic</h4>
                              <p className='smallText'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}

              <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                  modalDetail.flag === "PickPlan" ? "pickPlan" :
                    modalDetail.flag === "addBalance" ? "addBalance" :
                      modalDetail.flag === "FindProduct" ? "findProduct" : ""
                }
                size={
                  modalDetail.flag === "PickPlan" ? "xl" :
                    modalDetail.flag === "addBalance" ? "md" :
                      modalDetail.flag === "FindProduct" ? "lg" : "md"
                }
                child={
                  modalDetail.flag === 'PickPlan' ? <PlansModal details={(e) => details(e)} walletBalance={walletBalance} close={() => handleOnCloseModal()} /> :
                    modalDetail.flag === 'FindProduct' ? <FindProductModal flag={pathname} close={() => handleOnCloseModal()} /> :
                      modalDetail.flag === 'addBalance' ? <AddBalanceModal details={(e) => details(e)} close={() => handleOnCloseModal()} />
                        : ""
                }
                header={
                  <>
                    {modalDetail.flag === 'PickPlan'
                      ?
                      <>
                        <div className='row'>
                          <div className='col-md-12 text-center'>
                            <h3 className='heading_ mb-2'>Plan that fits your scale</h3>
                            <p>Simple, Transparent pricing that grows with you</p>
                            <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                              <i className="las la-times" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                      </> :
                      modalDetail.flag === "addBalance" ?
                        <>
                          <h3 className='subheading_ mb-2'>Add balance to your Wallet</h3>
                          <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                            <i className="las la-times" aria-hidden="true"></i>
                          </span>
                        </>
                        :
                        <>
                          <h3 className='subheading_ mb-2'>Based on your Store Profile setup</h3>
                          <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                            <i className="las la-times" aria-hidden="true"></i>
                          </span>
                        </>
                    }
                  </>
                }
                onCloseModal={() => handleOnCloseModal()}
              />
            </div>
          </div>


          <div className='col-xl-3 col-lg-3 col-md-3'>
            <div className='notificationBoard'>
              {/* <h3 className='mainboldHead'><b>Notification board</b></h3>
              <ul className='notificationDetails'>
                <li className='notificationItems'>
                  <Link to='#' className='notificationTxt notShow'>
                    <span className='hightlightSmall'>JOBR POS/</span>
                    3 items sales @$56.00
                  </Link>
                </li>
                <li className='notificationItems'>
                  <Link to='#' className='notificationTxt notShow'>
                    <span className='hightlightSmall'>JOBR POS/</span>
                    3 items sales @$56.00
                  </Link>
                </li>
                <li className='notificationItems'>
                  <Link to='#' className='notificationTxt showed'>
                    <span className='hightlightSmall'>JOBR POS/</span>
                    3 items sales @$56.00
                  </Link>
                </li>
                <li className='notificationItems'>
                  <Link to="#" className='notificationTxt showed'>
                    <span className='hightlightSmall'>JOBR POS/</span>
                    3 items sales @$56.00
                  </Link>
                </li>
              </ul>
              <button className='viewAll'>View All</button> */}
              <div className='no-datashown'>
                <img src={bellring} className='icons_bell' alt='bellImg' />
                <p>No Notification to show here</p>
              </div>
            </div>
            {/* <div className='datanotify'>
              <div className='no-datashown'>
                <img src={nodata} className='icons_bell' alt='bellImg' />
                <p> No Data to Show here </p>
              </div>
            </div> */}

          </div>
        </div>
      }

    </>
  )
}

export default ScalePlan