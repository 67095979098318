import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { selectLoginAuth } from '../auth/authSlice';
import { imageUploadAsync } from '../message/messageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FileUploader } from "react-drag-drop-files";
import { deleteIcon, imgGallery1 } from '../../../utilities/images';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const VariantDetails = (props) => {

    const [image, setImage] = useState(props?.selectedVariant?.image ? props?.selectedVariant?.image : "");
    const [upc, setUpc] = useState(props?.selectedVariant?.upc ? props?.selectedVariant?.upc : "");
    const [unit, setUnit] = useState(props?.selectedVariant?.unit ? props?.selectedVariant?.unit : "per_item")
    const [posPrice, setPosPrice] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'pos') ? props?.selectedVariant?.prices?.find(val => val?.app_name == 'pos')?.selling_price : "");
    const [b2bPrice, setB2bPrice] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2b') ? props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2b')?.selling_price : "");
    const [onlinePrice, setOnlinePrice] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2c') ? props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2c')?.selling_price : "");
    const [weight, setWeight] = useState(props?.selectedVariant?.weight ? props?.selectedVariant?.weight : "");
    const [availableToSell, setAvailableToSell] = useState(props?.selectedVariant?.is_available_for_sale ? props?.selectedVariant?.is_available_for_sale : false)
    const [activePos, setActivePos] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'pos') ? true : false);
    const [activeOnlineStore, setActiveOnlineStore] = useState((props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2c') || props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2b')) ? true : false);
    const [activeBtoc, setActiveBtoc] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2c') ? true : false);
    const [activeBtob, setActiveBtob] = useState(props?.selectedVariant?.prices?.find(val => val?.app_name == 'b2b') ? true : false);
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false);

    const handleChange = (file) => {
        uploadImage(file)
    };

    console.log(props?.variantOptionList, props?.selectedVariant, image[0]?.filePath, "dddddddddddddddddddddddddddddddddd");


    const uploadImage = (image) => {

        let formData = new FormData();

        // Object.values(image).forEach((val, index) => {
        formData.append(`file`, image);
        // });

        let params = {
            file: formData,
            token: auth?.payload?.token
        }
        setLoading(true)
        dispatch(imageUploadAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                console.log(obj, "sasasaas");
                setImage(obj?.payload[0]?.filePath)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }

            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const deleteImage = (item) => {
        //     const filterImage = image.filter(image => image.filePath != item);
        //     setImage(filterImage)
        // }

        setImage("")
    }

    const handleChangeJobrPos = () => {
        setActiveOnlineStore(!activeOnlineStore);
        if (activeBtoc) {
            setActiveBtoc(false);
        }
        if (activeBtob) {
            setActiveBtob(false);
        }
    }

    const handleSave = () => {
        let resultActive = [];

        if (activePos) {
            let price = posPrice
            const posMargin = ((price - props?.costPrice) / props?.costPrice) * 100;

            resultActive.push(
                {
                    "app_name": "pos",
                    "price_type": "fixed",
                    "selling_price": posPrice,
                    "margin_percentage": posMargin
                }
            );
        }
        if (activeBtob) {
            let price = b2bPrice
            const b2bMargin = ((price - props?.costPrice) / props?.costPrice) * 100;
            resultActive.push(
                {
                    "app_name": "b2b",
                    "price_type": "fixed",
                    "selling_price": b2bPrice,
                    "margin_percentage": b2bMargin
                }
            );
        }
        if (activeBtoc) {
            let price = onlinePrice
            const b2cMargin = ((price - props?.costPrice) / props?.costPrice) * 100;
            resultActive.push(
                {
                    "app_name": "b2c",
                    "price_type": "fixed",
                    "selling_price": onlinePrice,
                    "margin_percentage": b2cMargin
                }
            );
        }
        let params = {
            options: props?.selectedVariant?.options,
            variant_name: props?.selectedVariant?.variant_name,
            image: image,
            prices: resultActive,
            upc: upc,
            weight: weight,
            unit: unit,
            cost_price: props?.costPrice,
            is_available_for_sale: availableToSell,
            stocks: props?.selectedVariant?.stocks,
            custom_attributes: props?.selectedVariant?.custom_attributes || undefined
        }
        let result = props?.variantOptionList
        result[props?.selectedVariantIndex] = params
        props?.setVariantOptionList(result)
        props?.close()
        console.log(params, props?.selectedVariantIndex, props?.variantOptionList, "output")
    }


    return (
        <div className='variantDetails_'>
            <div className='editVarientSection'>
                <div className='ModalvarientSection'>
                    <div className='variationName_'>
                        <label className='variantnameText_'>Variation Name</label>
                        <span className='blacktext_'>{props?.selectedVariant?.variant_name}</span>
                    </div>
                    <div className='variantInfo'>
                        <div className='varientInfoSub'>
                            <h4 className='varientName'>UPC</h4>
                            <input type="text" className='varientdata' placeholder='upc' value={upc} maxLength={14} onChange={e => setUpc(e.target.value)} />
                        </div>
                        <div className='varientInfoSub'>
                            <h4 className='varientName'>Unit</h4>
                            <select name="cars" id="cars" className='varientdata' value={unit} onChange={(e) => setUnit(e.target.value)}>
                                <option value="per_item">Per Item</option>
                                <option value="per_box">Per Box</option>
                            </select>
                        </div>
                        <div className='varientInfoSub'>
                            <h4 className='varientName'>Price</h4>
                            <div className='onlinePrice'>
                                <div className='onlinePosSub'>
                                    <div className='descriptionPara_'>POS</div>
                                    <input type="text" className='varientdata' placeholder='POS price' value={posPrice} onChange={e => setPosPrice(e.target.value)} />
                                </div>
                                <div className='onlinePosSub'>
                                    <div className='descriptionPara_'>Online</div>
                                    <input type="text" className='varientdata' placeholder='Online price' value={onlinePrice} onChange={e => setOnlinePrice(e.target.value)} />
                                </div>
                                <div className='onlinePosSub'>
                                    <div className='descriptionPara_'>B2B</div>
                                    <input type="text" className='varientdata' placeholder='B2B price' value={b2bPrice} onChange={e => setB2bPrice(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='varientInfoSub'>
                            <h4 className='varientName'>Weight</h4>
                            <input type="text" className='varientdata weightControl' placeholder='Lb' value={weight} onChange={e => setWeight(e.target.value)} />
                        </div>
                    </div>
                    <div className='availableVarient mb-4'>
                        <div className="form-check form-switch">
                            <input type="checkbox"
                                checked={availableToSell}
                                onChange={() => setAvailableToSell(!availableToSell)} />
                        </div>
                        <h4 className='textinner_'>Available for Sell</h4>
                    </div>
                    <div className='storeConatiner_'>
                        <div className='posCheckBx mb-4'>
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    checked={activePos}
                                    onChange={() => setActivePos(!activePos)} />
                            </div>
                            <h4 className='customTableHead'>JOBR POS <span className='storeText'>(Physical store) </span></h4>
                        </div>
                        <div className='posCheckBx'>
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    checked={activeOnlineStore}
                                    onChange={() => handleChangeJobrPos()} />
                            </div>
                            <h4 className='customTableHead'>JOBR Online Store</h4>
                        </div>
                        {activeOnlineStore?
                       <>
                        <div className='posCheckBx posCheckSub'>
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    checked={activeBtoc} onChange={() => setActiveBtoc(!activeBtoc)} />
                            </div>
                            <h4 className='customTableHead'>JOBR B2C</h4>
                        </div>
                        <div className='posCheckBx posCheckSub'>
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    checked={activeBtob} onChange={() => setActiveBtob(!activeBtob)} />
                            </div>
                            <h4 className='customTableHead'>JOBR B2B</h4>
                        </div>
                        </>:"" }
                    </div>
                    <div className='uploadBox_ mt-4 text-start'>
                        <div id='dropzone'>
                            <FileUploader
                                multiple={false}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                children={
                                    <div >
                                        <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} />
                                        <label htmlFor="file">
                                            <span className="file-button uploadImages_">
                                                <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" />
                                                <div className='dropText ms-3'>
                                                    <span className='textinner_'>Drop your files here or</span>
                                                    <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                                </div>
                                            </span>
                                        </label>
                                    </div>
                                }
                            />
                        </div>
                        {/* {image && image.length > 0 ?
                            <label className='uploadContent mt-2 mb-3' htmlFor='amount'>
                                For bulk image upload: <span className='subtextSmall_'><i>Name your images like</i><Link to='#' className='linkText ms-1'>main image</Link></span>
                            </label>
                            : ""} */}
                        {loading ?
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                            </> : <>
                                {/* {image && image?.length > 0 ?
                                    <>
                                        {image?.map((item, index) => {
                                            return (
                                                <ul className='uploadProfile_' key={index}> */}
                                {image && <img className="w3-round" src={image} ></img>}
                                {/* <div className='profileDetail_'>
                                                        <h5 className='profileboldHead'>{item.originalname}</h5>
                                                        <p className='profileboldgraph'> {(item.size / (1024 * 1024)).toFixed(2)} MB </p>
                                                    </div> */}
                                {image && <img className='deleteIconn' src={deleteIcon} onClick={(e) => deleteImage()} />}
                                {/* </ul>
                                            )
                                        })} */}
                                {/* <button type='button' className='submitBtn' onClick={() => sendPhoto(image)}>Send <img className='sendFill' src={sendIcon} /></button> */}
                                {/* </>
                                    :
                                    <></>
                                } */}
                            </>
                        }


                    </div>
                </div>
                <div className=' modalfooterBtn'>
                    <button className='me-4 w-100 btnDiscard' type="button" onClick={() => props?.close()}>Discard</button>
                    <button className='w-100 btnSave' type="button" onClick={() => handleSave()} >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VariantDetails