import Multiselect from "multiselect-react-dropdown";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductsToInventoryAsync,
  getAddedToInventoryProductsAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DBImportProductModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(props?.loading ? props?.loading : false);
  const [productQuantities, setProductQuantities] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [allAddressIds, setAllAddressIds] = useState(props?.allAddressIds ? props?.allAddressIds : []);
  const toastId = React.useRef(null);
  const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleChange = (productId, quantity) => {
    let updatedQuantities = [...productQuantities];
    let existingProductIndex = updatedQuantities.findIndex(
      (item) => item.id === productId
    );

    if (existingProductIndex !== -1) {
      updatedQuantities[existingProductIndex] = {
        ...updatedQuantities[existingProductIndex],
        qty: quantity,
      };
    } else {
      updatedQuantities.push({ id: productId, qty: quantity });
    }
    setProductQuantities(updatedQuantities);
  };

  const handleClose = (productId) => {
    // Filter out the product with the given ID from the productQuantities
    let result = productQuantities?.filter(product => product?.id !== productId)
    setProductQuantities(result);
  }

  let productParams = {
    tokenData: auth?.payload?.token,
    postData: {
      type: "physical",
    },
  };

  const handleAddProducts = () => {

    let result = productQuantities.map((product) => ({
      id: product.id,
      qty: product?.qty,
    }));

    let data = {
      postData: {
        products: result,
        seller_address_ids: allAddressIds,
      },
      tokenData: auth?.payload?.token,
    };
    setLoadingButton(true);
    dispatch(addProductsToInventoryAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        dispatch(getAddedToInventoryProductsAsync(productParams))
          .then(unwrapResult)
          .then((obj) => {
            setLoadingButton(false);
            history.push(`/productList`);
          })
          .catch((obj) => { });
      })
      .catch((obj) => {
        setLoadingButton(false);
      });
  };

  useEffect(() => {
    // Initialize productQuantities with selected product IDs and default quantity 100
    const initialQuantities = props?.selectedProductData?.map((product) => ({
      id: product.id,
      name: product?.name,
      image: product?.image,
      qty: 100, // Default quantity
    }));
    setProductQuantities(initialQuantities);
  }, []);

  return (
    <div className="pro productitemas__">
      <div className="dbSection">
        {loading ? (
          <>
            <div className="loaderOuter">
              <div className="spinner-grow loaderSpinner text-center my-5"></div>
            </div>
          </>
        ) : (
          productQuantities.map((data, index) => {
            return (
              <>
                <div className=" productViewCard  mb-4" key={index}>
                  <div className="dbFlex">
                    <div className="d-flex align-items-center dbInnerBox">
                      <img
                        src={data?.image}
                        alt="SmokeImg"
                        style={{ width: "100px" }}
                        className="resultProductImg me-2"
                      />
                      <div className="">
                        <h4 className="subtext_ text-start">
                          {(data?.name).slice(0, 30)}
                          {data?.name?.length > 30 ? "..." : <></>}
                        </h4>
                        <p className="tableHeadsmall text-start">#{data?.id}</p>
                      </div>
                    </div>
                    <div className="dbControl">
                      <label className=" mb-1 text-start w-100 labelText">Quantity</label>
                      <input
                        min="1"
                        onKeyDown={blockInvalidChar}
                        className="customform-control"
                        placeholder="Please enter Quantity"
                        name="Quantity"
                        type="number"
                        value={
                          data?.qty || ""
                        }
                        onChange={(e) =>
                          handleChange(data.id, parseInt(e.target.value))
                        }
                      />
                    </div>
                    <button className="closeButton dbClose" onClick={(e) => handleClose(data?.id)} type="button">
                      <i className="las la-times"></i>
                    </button>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
      <div className="dataBaseBtn">
        <button
          className="acceptBtn_"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={() => handleAddProducts()}
          disabled={loadingButton}
        >
          {loadingButton && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span> Add Products</span>
        </button>
      </div>
    </div>
  );
};

export default DBImportProductModal;
