import React, { useState } from 'react'
import { shops, threeStep } from '../../../../utilities/images'
import { selectLoginAuth, selectSignUpAuth, setPasswordApiAsync } from '../authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import CustomModal from '../../../components/shared/CustomModal'
import VerifyModal from '../verifyModal'
import SuccessfulModal from '../successfulModal'
import LoginProfileModal from '../loginProfileModal'
import { unwrapResult } from '@reduxjs/toolkit'

const ChooseProtection = () => {
  const phoneData = localStorage.getItem("phone_details") ? JSON.parse(localStorage.getItem("phone_details")) : ""
  const existing_user = localStorage.getItem("existing_user") ? JSON.parse(localStorage.getItem("existing_user")) : ""
  const [loading, setLoading] = useState(false)
  const [securityPin, setSecurityPin] = useState(existing_user?.user_profiles?.security_pin.length == 4 ? existing_user?.user_profiles?.security_pin : "");
  const [password, setPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false)
  const [pinToggle, setPinToggle] = useState(false)
  const signupAuth = useSelector(selectSignUpAuth)
  const [passwordStrength, setPasswordStrength] = useState("")
  const [pinStrength, setPinStrength] = useState("")
  const [key, setKey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    })
    setKey(Math.random());
  }
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to test if the input is numeric
    if (/^\d*$/.test(inputValue)) {
      setSecurityPin(inputValue);
      calculatePinStrength(inputValue)
    }
  };

  const blockInvalidChar = (e) => {
    // Allow backspace
    if (e.key === 'Backspace') {
      return;
    }
    // Block alphabets and specific characters
    if (!/^\d$/.test(e.key) || ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleModalDetails = (data) => {
    if (data === "Successful" || data === "LoginProfile") {
      setModalDetail({ show: true, flag: data })
      setKey(Math.random())
    }
  }
  const toastId = React.useRef(null)
  const calculatePasswordStrength = (password) => {
    if (password.length < 4) {
      setPasswordStrength("weak")
    }
    if (password.length > 4) {
      setPasswordStrength("moderate")
    }
    if (password.length >= 8 && (/[A-Z]/.test(password)) && (/[a-z]/.test(password)) && (/[0-9]/.test(password)) && (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password))) {
      setPasswordStrength("strong")
    }
    if (password.length == 0) {
      setPasswordStrength("")
    }
  };

  const calculatePinStrength = (pin) => {
    if (pin.length < 2) {
      setPinStrength("weak")
    }
    if (pin.length > 2) {
      setPinStrength("moderate")
    }
    if (pin.length == 4 && (/^\d{4}$/.test(pin))) {
      setPinStrength("strong")
    }
    if (pin.length == 0) {
      setPinStrength("")
    }
  };

  const handleSetPassword = (e) => {
    e.preventDefault()
    let data = {
      "password": password,
    }
    if (password.length < 8) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password should be of 8 digits")
      }
      return
    }
    if (!(/^\d{4}$/.test(securityPin)) && (phoneData?.is_security_pin_exist === false || phoneData?.is_security_pin_exist == undefined)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Security pin must be number and of 4 digit")
      }
      return
    }
    if (!phoneData?.is_security_pin_exist) {
      data = {
        ...data,
        "security_pin": securityPin
      }
    }
    let params = {
      postData: data,
      tokenData: auth?.payload?.user?.token ? auth?.payload?.user?.token : signupAuth?.payload?.token
    }
    setLoading(true)
    dispatch(setPasswordApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setModalDetail({ show: true, flag: "Successful" });
        setKey(Math.random());
        localStorage.removeItem("phone_details")
        localStorage.removeItem("email_details")
        localStorage.removeItem("email_info")
        localStorage.removeItem("existing_user")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  return (
    <div className='protectionSection'>
      <div className="commonLogin">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-6 p-0 text-center">
              <div className='leftSect'>
                <div className="login_left">
                  <img src={shops} className="d-none d-md-block img-fluid mx-auto mb-3" alt="img" />
                  <span className='heading_'>Create Your <br />Organization</span>
                </div>
                <div className='aboutBusiness'>
                  <img src={threeStep} className='img-fluid mb-4' />
                  <h4 className='mb-2 innerSubtext'>Set Your Protections</h4>
                  <span className=''>Please let us know what you need help with and We will  <br />do our best to assist you.</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form autoComplete="nope" className="form_horizontal row" onSubmit={(e) => handleSetPassword(e)}>
                <div className='protectionRight'>
                  <div className='loginhorrizontal-form '>
                    <h4 className="subheading_">Choose Your Protections</h4>
                    <div className='inputProgress'>
                      <div className="phone-numbpart ">
                        <label className="form-label validText">Type Password </label>
                        <input
                          className="customform-control id_password"
                          placeholder="Type Password here"
                          name="password"
                          type={passwordToggle ? "text" : "password"}
                          value={password}
                          onChange={(e) => { setPassword(e.target.value); calculatePasswordStrength(e.target.value) }}
                          autoComplete="new-password"
                        />
                        <span className="toggle_password_ info_icon" onClick={() => setPasswordToggle(prev => !prev)}>
                          <span className={passwordToggle ? "show-icon togglePassword togglePassword" : "show-icon togglePassword hide-icon togglePassword"}></span>
                        </span>
                      </div>
                      <div className='protectionProgress'>
                        <div className={passwordStrength == "weak" ? "progressRed" : passwordStrength == "moderate" ? "progressOrange" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        <div className={passwordStrength == "weak" ? "progressRed" : passwordStrength == "moderate" ? "progressOrange" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        <div className={passwordStrength == "weak" ? "progresspart" : passwordStrength == "moderate" ? "progressOrange" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        <div className={passwordStrength == "weak" ? "progresspart" : passwordStrength == "moderate" ? "progressOrange" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        <div className={passwordStrength == "weak" ? "progresspart" : passwordStrength == "moderate" ? "progresspart" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        <div className={passwordStrength == "weak" ? "progresspart" : passwordStrength == "moderate" ? "progresspart" : passwordStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                      </div>
                    </div>

                    {phoneData?.is_security_pin_exist == true ?
                      <></>
                      :
                      <div className='inputProgress'>
                        <div className="phone-numbpart ">
                          <label className="form-label validText">Type Pin </label>
                          <input
                            className="customform-control id_password"
                            placeholder="Type Pin here"
                            name="pin"
                            type={pinToggle ? "text" : "password"}
                            maxLength="4"
                            value={securityPin}
                            onChange={handleInputChange}
                            onKeyDown={blockInvalidChar}
                            autoComplete="new-password"
                          />
                          <span className="toggle_password_ info_icon" onClick={() => setPinToggle(prev => !prev)}>
                            <span className={pinToggle ? "show-icon togglePassword togglePassword" : "show-icon togglePassword hide-icon togglePassword"}></span>
                          </span>
                        </div>
                        <div className='protectionProgress'>
                          <div className={pinStrength == "weak" ? "progressRed" : pinStrength == "moderate" ? "progressOrange" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                          <div className={pinStrength == "weak" ? "progressRed" : pinStrength == "moderate" ? "progressOrange" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                          <div className={pinStrength == "weak" ? "progresspart" : pinStrength == "moderate" ? "progressOrange" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                          <div className={pinStrength == "weak" ? "progresspart" : pinStrength == "moderate" ? "progressOrange" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                          <div className={pinStrength == "weak" ? "progresspart" : pinStrength == "moderate" ? "progresspart" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                          <div className={pinStrength == "weak" ? "progresspart" : pinStrength == "moderate" ? "progresspart" : pinStrength == "strong" ? "progressGreen" : 'progresspart'}></div>
                        </div>
                      </div>
                    }

                    {/* <div className='form-group col-md-12 phone-numbpart'>
                    <input type="checkbox" id="register_business" name="register_business" value="register_business" />
                    <label htmlFor="register" className='ms-2 mainlightPhragraph '>Always Ask for 2-step Verifications </label><br />
                  </div> */}
                  </div>
                  <div className="col-md-12 protectionBtn">
                    <button className={`loginBtnSign w-100 ${(password && ((phoneData?.is_security_pin_exist) || (!phoneData?.is_security_pin_exist && securityPin?.length == 4))) ? 'buttonActive' : ''}`} type="submit" disabled={loading || !(password && ((phoneData?.is_security_pin_exist) || (!phoneData?.is_security_pin_exist && securityPin?.length == 4)))}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Set Password</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "VerifyModal" ? "verifyModal"
            : modalDetail.flag === "Successful" ? "successful"
              : modalDetail.flag === "LoginProfile" ? "loginProfileModal"
                : ""
        }
        size={modalDetail.flag === "VerifyModal" ? "md" : modalDetail.flag === "Successful" ? "md" : ""}
        child={
          modalDetail.flag === 'Successful' ? <SuccessfulModal close={() => handleOnCloseModal()}
            details={(e) => handleModalDetails(e)} />
            : modalDetail.flag === 'LoginProfile' ? <LoginProfileModal close={() => handleOnCloseModal()} />
              : <></>}
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  )
}

export default ChooseProtection