import React from "react";
import {
  uspsImg,
  wholeFood,
  brandLogo,
  barcode,
  profileImg1,
} from "../../../utilities/images";
import moment from "moment-timezone";

const WalletPurhaseModal = (props) => {
  const { data } = { ...props };
  console.log(data, "from Modal")
  return (
    <>
      <div className="walletPurchaseSection">
        <div className={`recordWalletData `}>
          <div className='reviewuserinfo_'>
            <img className='invoveuser me-3' style={{ width: "50px" }} src={data?.seller_details?.banner_image ? data?.seller_details?.banner_image : profileImg1} />
            <article className='ivoiceuserDetail_'>
              <h4 className='monshead14'>{data?.seller_details?.organization_name}</h4>
              <p className='monshead12'>{data?.seller_details?.current_address?.street_address}</p>
            </article>
          </div>

          <div className="walletStoreData">
            {
              data?.delivery_option == 1 && data?.delivery_details ?
                <div className='reviewuserinfo_'>
                  <img className='invoveuser me-3' style={{ width: "50px" }} alt='' src={data?.driver_details?.profile_photo ? data?.driver_details?.profile_photo : profileImg1} />
                  <article className='ivoiceuserDetail_'>
                    <h4 className='monshead14 blueText_'>{data?.delivery_details?.title}</h4>
                    {/* <p className='monshead12'>Immediately</p> */}
                  </article>
                </div> :
                data?.delivery_option == 4 && data?.shipping_details ?
                  <div className='reviewuserinfo_'>
                    <img className='invoveuser me-3' style={{ width: "50px" }} alt='' src={data?.shipping_details?.image ? data?.shipping_details?.image : profileImg1} />
                    <article className='ivoiceuserDetail_'>
                      <h4 className='monshead14 blueText_'>{data?.shipping_details?.title}</h4>
                      {/* <p className='monshead12'>Immediately</p> */}
                    </article>
                  </div>
                  : <></>
            }
          </div>
        </div>
        {
          data?.user_details ?
            <div className="walletAddress">
              <h4 className="expectedHeading">{data?.user_details?.firstname + " " + data?.user_details?.lastname}</h4>
              <p className="walletHeading mt-3">
                {data?.user_details?.current_address?.street_address}
              </p>
              <h4 className="walletHeading mt-2">{data?.user_details?.phone_number}</h4>
            </div> : <></>
        }
        <div className="walletPurchaseTable">
          {(data?.order_details?.length > 0 && data?.returns == null) ? data?.order_details?.map((item, i) => {
            return (
              <>
                <div key={i} className="purchaseTableSub">
                  <div className="walletFlex">
                    <h4 className="profileboldHead">{i + 1}</h4>
                    <div className="orderDimension">
                      <h4 className="orderDimensionText mb-1">
                        {item?.product_name}
                      </h4>
                      <h4 className="dimensionPara m-0">
                        {/* {item?.variant ?? "-"} */}
                        {console.log(item?.variant, "dttsisassas")}
                        {/* <span className="sizeDimension ms-2">{item?.product_type === 'product' ? item?.variant : moment(item?.created_at).format('MMM DD, YYYY hh:mm a')}</span> */}
                        <span className="sizeDimension ms-2">{item?.product_type === 'product' ? (item?.variant ? JSON.parse(item?.variant ? item?.variant : "")[0]?.attribute_name : "") + "-" + (item?.variant ? JSON.parse(item?.variant ? item?.variant : '')[0]?.attribute_value_name : "") : moment(item?.created_at).format('MMM DD, YYYY hh:mm a')}</span>
                      </h4>
                    </div>
                  </div>
                  <h4 className="profileboldHead">${item?.actual_price}</h4>
                </div>
              </>
            );
          }) :
            (data?.returns?.return_details?.length > 0 && data?.returns != null) ?
              data?.returns?.return_details?.map((item, i) => {
                return (
                  <>
                    <div key={i} className="purchaseTableSub">
                      <div className="walletFlex">
                        <h4 className="profileboldHead">{i + 1}</h4>
                        <div className="orderDimension">
                          <h4 className="orderDimensionText mb-1">
                            {item?.order_details?.product_name}
                          </h4>
                          <h4 className="dimensionPara m-0">
                            {/* {item?.variant ?? "-"} */}
                            {/* <span className="sizeDimension ms-2">{item?.order_details?.product_type === 'product' ? item?.order_details?.variant : moment(item?.order_details?.created_at).format('MMM DD, YYYY hh:mm a')}</span> */}
                            {console.log(item?.order_details?.variant, "dttsisassas")}

                            <span className="sizeDimension ms-2">{item?.order_details?.product_type === 'product' ? (item?.order_details?.variant ? JSON.parse(item?.order_details?.variant)[0]?.attribute_name : "") + "-" + (item?.order_details?.variant ? JSON.parse(item?.order_details?.variant)[0]?.attribute_value_name : "") : moment(item?.updated_at).format('MMM DD, YYYY hh:mm a')}</span>
                          </h4>
                        </div>
                      </div>
                      <h4 className="profileboldHead">${item?.refunded_amount}</h4>
                    </div>
                  </>
                );
              })
              :
              data?.return_detail?.return_details?.map((item, i) => {
                return (
                  <>
                    <div key={i} className="purchaseTableSub">
                      <div className="walletFlex">
                        <h4 className="profileboldHead">{i + 1}</h4>
                        <div className="orderDimension">
                          <h4 className="orderDimensionText mb-1">
                            {item?.order_details?.product_name}
                          </h4>
                          <h4 className="dimensionPara m-0">
                            {/* {item?.variant ?? "-"} */}
                            {/* <span className="sizeDimension ms-2">{item?.order_details?.product_type === 'product' ? item?.order_details?.variant : moment(item?.order_details?.created_at).format('MMM DD, YYYY hh:mm a')}</span> */}
                            {console.log(item?.order_details?.variant, "dttsisassas")}

                            <span className="sizeDimension ms-2">{item?.order_details?.product_type === 'product' ? (item?.order_details?.variant ? JSON.parse(item?.order_details?.variant)[0]?.attribute_name : "") + "-" + (item?.order_details?.variant ? JSON.parse(item?.order_details?.variant)[0]?.attribute_value_name : "") : moment(item?.order_details?.created_at).format('MMM DD, YYYY hh:mm a')}</span>
                          </h4>
                        </div>
                      </div>
                      <h4 className="profileboldHead">${item?.order_details?.actual_price}</h4>
                    </div>
                  </>
                );
              })
          }

          {/* <div className='purchaseTableSub'>
                        <div className='walletFlex'>
                            <h4 className='profileboldHead'>1</h4>
                            <div className='orderDimension'>
                                <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                            </div>
                        </div>
                        <h4 className='profileboldHead'>$6.56</h4>
                    </div>
                    <div className='purchaseTableSub'>
                        <div className='walletFlex'>
                            <h4 className='profileboldHead'>5</h4>
                            <div className='orderDimension'>
                                <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                            </div>
                        </div>
                        <h4 className='profileboldHead'>$6.56</h4>
                    </div>
                    <div className='purchaseTableSub'>
                        <div className='walletFlex'>
                            <h4 className='profileboldHead'>1</h4>
                            <div className='orderDimension'>
                                <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                            </div>
                        </div>
                        <h4 className='profileboldHead'>$6.56</h4>
                    </div> */}
        </div>

        {
          (data?.order_details?.length > 0 && data?.returns == null)
            ?
            <div className="totalPurchaseOrder">
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Sub-Total</h4>
                <h4 className="walletHeading mb-0">${Number(data?.actual_amount)?.toFixed(2)}</h4>
              </div>
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Discount</h4>
                <h4 className="walletHeading mb-0">${data?.discount ? Number(data?.discount)?.toFixed(2) : 0}</h4>
              </div>
              {
                data?.delivery_option == 1 || data?.delivery_option == 4 ?
                  <div className="totalOrderSub">
                    <h4 className="totalOrderText mb-0">{data?.delivery_option == 4 ? 'Shipping' : 'Delivery'} Charge</h4>
                    <h4 className="walletHeading mb-0">${data?.delivery_option == 4 ? Number(data?.shipping_charge)?.toFixed(2) : Number(data?.delivery_charge)?.toFixed(2)}</h4>
                  </div>
                  : <></>}
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Tips</h4>
                <h4 className="walletHeading mb-0">${data?.tips ? Number(data?.tips)?.toFixed(2) : 0.00}</h4>
              </div>
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Tax</h4>
                <h4 className="walletHeading mb-0">${data?.tax ? Number(data?.tax)?.toFixed(2) : 0.00}</h4>
              </div>
              <div className="totalOrderSub totalOrderMain ">
                <h4 className="totalOrderText mb-0">Total</h4>
                <h4 className="walletHeading mb-0">${Number(data?.payable_amount)?.toFixed(2)}</h4>
              </div>
            </div> :
            <div className="totalPurchaseOrder">
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Sub-Total</h4>
                <h4 className="walletHeading mb-0">${Number(data?.returns?.products_refunded_amount)?.toFixed(2)}</h4>
              </div>
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Discount</h4>
                <h4 className="walletHeading mb-0">${data?.returns?.discount ? Number(data?.returns?.discount)?.toFixed(2) : 0}</h4>
              </div>
              {
                data?.delivery_option == 1 || data?.delivery_option == 4 ?
                  <div className="totalOrderSub">
                    <h4 className="totalOrderText mb-0">{data?.delivery_option == 4 ? 'Shipping' : 'Delivery'} Charge</h4>
                    <h4 className="walletHeading mb-0">${data?.delivery_option == 4 ? Number(data?.shipping_charge)?.toFixed(2) : Number(data?.delivery_charge)?.toFixed(2)}</h4>
                  </div>
                  : <></>}
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Tips</h4>
                <h4 className="walletHeading mb-0">${data?.returns?.tips ? Number(data?.returns?.tips)?.toFixed(2) : 0.00}</h4>
              </div>
              <div className="totalOrderSub">
                <h4 className="totalOrderText mb-0">Tax</h4>
                <h4 className="walletHeading mb-0">${data?.returns?.tax ? Number(data?.returns?.tax)?.toFixed(2) : 0.00}</h4>
              </div>
              <div className="totalOrderSub totalOrderMain ">
                <h4 className="totalOrderText mb-0">Total</h4>
                <h4 className="walletHeading mb-0">${Number(data?.returns?.refunded_amount)?.toFixed(2)}</h4>
              </div>
            </div>
        }


        {
          (data?.order_details?.length > 0 && data?.returns == null) ?
            <div className="paymentoptionSection">
              <h4 className="profileboldHead">
                Payment option:
                <span className="headingjobr ms-2">{data?.mode_of_payment === "jbr" ? 'JBR Wallet' : "Cash"}</span>
              </h4>
              <h4 className="subtextSmallblack_ ">
                {moment(data?.created_at).format("dddd DD MMM, YYYY")}<span className='subtextSmallblack_  ms-2'>{moment(data?.created_at).format("hh:mm a")}</span>
              </h4>
              {
                data?.delivery_option == 2 || data?.delivery_option == 3 ?
                  <h4 className="subtextSmallblack_ ">Walk-In</h4> : <></>
              }
              <h4 className="subtextSmallblack_ ">Invoice No.. # {data?.invoices?.invoice_number ? data?.invoices?.invoice_number : data?.return_detail?.invoices?.invoice_number}</h4>
              {
                data?.pos_user_id ?
                  <h4 className="subtextSmallblack_ ">POS No. #{data?.pos_user_id?.slice(0, 4)}</h4>
                  : <></>
              }
              {
                data?.user_id ?
                  <h4 className="subtextSmallblack_ ">User ID : ****{data?.user_id?.slice(data?.user_id?.length - 3, data?.user_id?.length)}</h4>
                  : <></>
              }
            </div>
            :
            <div className="paymentoptionSection">
              <h4 className="profileboldHead">
                Payment option:
                <span className="headingjobr ms-2">{data?.returns?.mode_of_payment === "jbr" ? 'JBR Wallet' : "Cash"}</span>
              </h4>
              <h4 className="subtextSmallblack_ ">
                {moment(data?.returns?.updated_at).format("dddd DD MMM, YYYY")}<span className='subtextSmallblack_  ms-2'>{moment(data?.created_at).format("hh:mm a")}</span>
              </h4>
              {
                data?.delivery_option == 2 || data?.delivery_option == 3 ?
                  <h4 className="subtextSmallblack_ ">Walk-In</h4> : <></>
              }
              <h4 className="subtextSmallblack_ ">Invoice No.. # {data?.returns?.invoices?.invoice_number ? data?.returns?.invoices?.invoice_number : data?.return_detail?.invoices?.invoice_number}</h4>
              {
                data?.pos_user_id ?
                  <h4 className="subtextSmallblack_ ">POS No. #{data?.returns?.pos_user_id?.slice(0, 4)}</h4>
                  : <></>
              }
              {
                data?.user_id ?
                  <h4 className="subtextSmallblack_ ">User ID : ****{data?.user_id?.slice(data?.user_id?.length - 3, data?.user_id?.length)}</h4>
                  : <></>
              }
            </div>}


        <div className="walletfooterModal">
          <h2 className="modalFooterText">Thank You</h2>
          <img src={data?.invoices?.barcode ? data?.invoices?.barcode : data?.return_detail?.invoices?.barcode ? data?.return_detail?.invoices?.barcode : barcode} className="img-fluid" alt="barcodeImage" />
          <img
            src={brandLogo}
            className="img-fluid brandImg_"
            alt="barcodeImage"
          />
        </div>
      </div>
    </>
  );
};

export default WalletPurhaseModal;
