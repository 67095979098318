import React, { useState } from 'react'

const UpdatePriceModal = (props) => {

  const [samePrice, setSamePrice] = useState(false)
  const [samePriceValue, setSamePriceValue] = useState("")
  const [unit, setUnit] = useState("per_item")
  const [posPrice, setPosPrice] = useState("");
  const [b2bPrice, setB2bPrice] = useState("");
  const [onlinePrice, setOnlinePrice] = useState("");
  const [availableToSell, setAvailableToSell] = useState(false)
  const [activePos, setActivePos] = useState(false);
  const [activeOnlineStore, setActiveOnlineStore] = useState(false);
  const [activeBtoc, setActiveBtoc] = useState(false);
  const [activeBtob, setActiveBtob] = useState(false);

  const handleChangeJobrPos = () => {
    setActiveOnlineStore(!activeOnlineStore);
    if (activeBtoc) {
      setActiveBtoc(false);
    }
    if (activeBtob) {
      setActiveBtob(false);
    }
  }

  console.log(props?.variantOptionList, "dddddddddddddddddddddddddddd");

  const handleSave = () => {

    let resultActive = [];

    if (activePos) {
      let price = samePrice ? samePriceValue : posPrice
      const posMargin = ((price - props?.costPrice) / props?.costPrice) * 100;

      resultActive.push(
        {
          "app_name": "pos",
          "price_type": "fixed",
          "selling_price": samePrice ? samePriceValue : posPrice,
          "margin_percentage": posMargin
        }
      );
    }
    if (activeBtob) {
      let price = samePrice ? samePriceValue : b2bPrice
      const b2bMargin = ((price - props?.costPrice) / props?.costPrice) * 100;
      resultActive.push(
        {
          "app_name": "b2b",
          "price_type": "fixed",
          "selling_price": samePrice ? samePriceValue : b2bPrice,
          "margin_percentage": b2bMargin
        }
      );
    }
    if (activeBtoc) {
      let price = samePrice ? samePriceValue : onlinePrice
      const b2cMargin = ((price - props?.costPrice) / props?.costPrice) * 100;
      resultActive.push(
        {
          "app_name": "b2c",
          "price_type": "fixed",
          "selling_price": samePrice ? samePriceValue : onlinePrice,
          "margin_percentage": b2cMargin
        }
      );
    }

    let result = props?.variantOptionList
    result = result.map(variant => {
      return {
        ...variant,
        unit: props?.selectedVariants.includes(variant.variant_name) ? unit : variant?.unit,
        is_available_for_sale: props?.selectedVariants.includes(variant.variant_name) ? availableToSell : variant?.is_available_for_sale,
        prices: props?.selectedVariants.includes(variant.variant_name) ? resultActive : variant?.prices,
      };
    });
    props?.setVariantOptionList(result)
    props?.close()
  }

  return (
    <>
      <div className='variantDetails_ '>
        <div className='updatePriceSection'>
          <div className='availableVarient mb-4'>
            <div className="form-check form-switch">
              <input type="checkbox"
                checked={availableToSell}
                onChange={() => setAvailableToSell(!availableToSell)} />
            </div>
            <h4 className='textinner_'>Available for Sell</h4>
          </div>
          <div className='storeConatiner_'>
            <div className='posCheckBx mb-2'>
              <div className="form-check form-switch">
                <input type="checkbox"
                  checked={activePos}
                  onChange={() => setActivePos(!activePos)} />
              </div>
              <h4 className='customTableHead'>JOBR POS <span className='storeText'>(Physical store) </span></h4>
            </div>
            <div className='posCheckBx'>
              <div className="form-check form-switch">
                <input type="checkbox"
                  checked={activeOnlineStore}
                  onChange={() => handleChangeJobrPos()} />
              </div>
              <h4 className='customTableHead'>JOBR Online Store</h4>
            </div>

            {activeOnlineStore ? (
              <>
                <div className='posCheckBx posCheckSub'>
                  <div className="form-check form-switch">
                    <input type="checkbox" checked={activeBtoc} onChange={() => setActiveBtoc(!activeBtoc)} />
                  </div>
                  <h4 className='customTableHead'>JOBR B2C</h4>
                </div>
                <div className='posCheckBx posCheckSub'>
                  <div className="form-check form-switch">
                    <input type="checkbox" checked={activeBtob} onChange={() => setActiveBtob(!activeBtob)} />
                  </div>
                  <h4 className='customTableHead'>JOBR B2B</h4>
                </div>
              </>
            ) : ""
            }

          </div>
          <div className="mt-4 form-group col-md-12 ">
            <input
              type="checkbox"
              id="register"
              autoComplete="new-password"
              name="defaultAddress"
              className='updateCheckBx'
              checked={samePrice}
              onChange={() => setSamePrice(!samePrice)}
            />
            <label
              htmlFor="register"
              className="ms-2 mainlightPhragraph"
            >
              Same Price for all Platforms
            </label>
            <br />
          </div>
          <div className='variantInfo mb-0'>
            <div className='varientInfoSub'>
              <h4 className='varientName'>Unit</h4>
              <select name="cars" id="cars" className='varientdata' value={unit} onChange={(e) => setUnit(e.target.value)}>
                <option value="per_item">Per Item</option>
                <option value="per_box">Per Box</option>
              </select>
            </div>
            <div className='varientInfoSub '>
              <h4 className='varientName'>Price</h4>
              <div className='onlinePrice'>
                {
                  samePrice ?
                    <input type="text" className='varientdata w-100' placeholder='Price' value={samePriceValue} onChange={e => setSamePriceValue(e.target.value)} />
                    :
                    <>
                      <div className='onlinePosSub'>
                        <div className='descriptionPara_'>POS</div>
                        <input type="text" className='varientdata' placeholder='POS price' value={posPrice} onChange={e => setPosPrice(e.target.value)} />
                      </div>
                      <div className='onlinePosSub'>
                        <div className='descriptionPara_'>Online</div>
                        <input type="text" className='varientdata' placeholder='Online price' value={onlinePrice} onChange={e => setOnlinePrice(e.target.value)} />
                      </div>
                      <div className='onlinePosSub'>
                        <div className='descriptionPara_'>B2B</div>
                        <input type="text" className='varientdata' placeholder='B2B price' value={b2bPrice} onChange={e => setB2bPrice(e.target.value)} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>

        </div>
        <div className=' modalfooterBtn priceModalSection'>
          <button className='me-4 w-100 btnDiscard' >Discard</button>
          <button className='w-100  bluebgcolor_' type="button" onClick={() => handleSave()} >
            Applied to Selected Variations
          </button>
        </div>
      </div>
    </>
  )
}

export default UpdatePriceModal