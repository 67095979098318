import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import CustomModal from '../../../components/shared/CustomModal';
import DefaultaddressModal from './defaultaddressModal';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressApiAsync, selectLoginAuth } from '../../auth/authSlice';
import { setupAddressApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

const AddaddressModal = (props) => {
    const toastId = React.useRef(null)
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const [key, setKey] = useState(Math.random());
    const pathname = window.location.pathname;
    const auth = useSelector(selectLoginAuth)
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const [addressType, setAddressType] = useState("store")
    const [address, setAddress] = useState("");
    const [formattedAddress, setFormattedAddress] = useState("")
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [countryValue, setCountryValue] = useState("");
    const [cityValue, setCityValue] = useState("");
    const [countryCode, setCountryCode] = useState("")
    const [stateCode, setStateCode] = useState("")
    const [stateValue, setStateValue] = useState("");
    const [aptValue, setAptValue] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [allowPickup, setAllowPickup] = useState(false)
    const [allowDelivery, setAllowDelivery] = useState(false)
    const [allowDropoff, setAllowDropoff] = useState(false)
    const [allowShipping, setAllowShipping] = useState(false)

    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const autoCompleteHandleChange = (address) => {
        // setAddress(address);
        setFormattedAddress(address)
        geocodeByAddress(address)
            .then((results) => {
                // fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
            })
            .catch((error) => { });
        setZipCode("");
        setCityValue("");
        setStateValue("");
        setCountryValue("");
        setAptValue("");
        setAddress("")
    };

    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
            })
            .catch((error) => { });
    };

    const fillAddressDetails = (results) => {
        // setAddress(results[0].formatted_address);
        setFormattedAddress(results[0].formatted_address)

        console.log(results, "formatted address")
        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            } else if (results[0].address_components[j].types[0] == 'street_number') {
                // setAptValue(results[0].address_components[j].long_name);
            }
            else if (results[0].address_components[j].types[0] == 'route') {
                setAddress(results[0].address_components[j].long_name)
            }
        }
    };
    const extractStreetAddress = (address) => {
        // Extract the street address from the address
        const parts = address.split(',');
        if (parts.length >= 3) {
            return `${parts[0].trim()}, ${parts[1].trim()}`;
        }
    };

    console.log(extractStreetAddress(formattedAddress), "first", formattedAddress,"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");

    const handleSave = () => {
        if (!formattedAddress) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your address")
            }
            return
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your city")
            }
            return
        }
        if (!zipCode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode")
            }
            return
        }
        if (!latitude && !longitude) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Incorrect Address")
            }
            return
        }

        let params = {
            "address_type": addressType,
            "street_address": address ? address : extractStreetAddress(formattedAddress),
            "country": countryValue,
            "state": stateValue,
            "city": cityValue,
            "zipcode": zipCode,
            "longitude": longitude,
            "latitude": latitude,
            "country_code": countryCode,
            "state_code": stateCode,
            "format_address": formattedAddress,
        }
        if (allowDelivery || allowDropoff || allowPickup || allowShipping) {
            params = {
                ...params,
                "allow_local_pickup": allowPickup ? "1" : "0",
                "allow_jobr_delivery": allowDelivery ? "1" : "0",
                "allow_local_drop_off": allowDropoff ? "1" : "0",
                "allow_shipping": allowShipping ? "1" : "0"
            }
        }

        if (aptValue) {
            params = {
                ...params,
                "apt": aptValue
            }
        }

        let data = {
            postData: params,
            tokenData: auth?.payload?.token
        }

        console.log(addressType, params, "response")

        setLoading(true)
        dispatch(setupAddressApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        props.close()
                        history.push('/locations')
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })

            })
            .catch((obj) => {
                setLoading(false)
            })

    }

    return (
        <>
            <div className='commonmodal-header mb-4'>
                <div className='addheadLeft_'>
                    <h4 className='modalHeading_'>Add New Address </h4>
                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                </div>
                <div className='addheadRight'>
                    <button className='addressdiscardBtn me-2' type="button" onClick={() => props.close()}>Discard</button>
                    <button className={`addresssaveBtn ${(formattedAddress) ? 'buttonActive' : ''}`} type="button" onClick={() => {
                        handleSave()
                    }}
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div className='addaddressModal_'>
                <Form className="addressForm_">
                    <div class="mb-4 form-group">
                        <label class="contentheadtext_ mb-3 fw-semibold">Identify As</label>
                        <select class="customform-control select" value={addressType} onChange={e => setAddressType(e.target.value)}>
                            <option value="store" >Store</option>
                            <option value="ware_house">Wear House</option>
                        </select>
                    </div>

                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">Street Address</label>
                        <div className='inputGroup'>
                            {
                                hide ?
                                    <span
                                        className="customform-control countrytags_ w-100"
                                        onClick={() => setHide(!hide)}
                                    >{displayValue}</span>
                                    :
                                    <PlacesAutocomplete
                                        className=""
                                        autoComplete="off"
                                        value={formattedAddress}
                                        onChange={autoCompleteHandleChange}
                                        onSelect={autoCompleteHandleSelect}
                                        searchOptions={{
                                            componentRestrictions: {
                                                country: ['us', 'ind'],
                                            }
                                        }}
                                    >
                                        {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading,
                                        }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Street Address',
                                                        className: 'location-search-input customform-control countrytags_',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion, index) => {
                                                        const className = suggestion.active
                                                            ? "suggestion-item--active"
                                                            : "suggestion-item";
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? {
                                                                backgroundColor: "#41b6e6",
                                                                cursor: "pointer",
                                                            }
                                                            : {
                                                                backgroundColor: "#ffffff",
                                                                cursor: "pointer",
                                                            };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                                key={index}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                            }
                        </div>
                    </div>
                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">Apt.</label>
                        <input
                            className="customform-control id_password"
                            placeholder="Apt."
                            name={generateRandomName}
                            autoComplete="new-password"
                            type="text"
                            value={aptValue}
                            onChange={(e) => setAptValue(e.target.value)}
                        />
                    </div>
                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">Country</label>
                        <input
                            className="customform-control id_password"
                            placeholder="Country"
                            name={generateRandomName}
                            autoComplete="new-password"
                            type="text"
                            value={countryValue}
                            onChange={(e) => setCountryValue(e.target.value)}
                        />
                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">State</label>
                        <input
                            className="customform-control id_password"
                            placeholder="State"
                            name={generateRandomName}
                            autoComplete="new-password"
                            type="text"
                            value={stateValue}
                            onChange={(e) => setStateValue(e.target.value)}
                        />
                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">City</label>
                        <input
                            className="customform-control id_password"
                            placeholder="City"
                            name={generateRandomName}
                            autoComplete="new-password"
                            type="text"
                            value={cityValue}
                            onChange={(e) => setCityValue(e.target.value)}
                        />

                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group">
                        <label className="deatilsHead mb-3">Zip code</label>
                        <input
                            type="number"
                            className="customform-control"
                            name={generateRandomName}
                            autoComplete="new-password"
                            placeholder="Zip code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                    </div>

                    <div class="mb-4 form-group">
                        <div className='shippingavail_'>
                            <h5 className='testshiphead'>Shipping & Pickup Availability <span className='optionalhead_'>Optional</span></h5>
                            <div className='shippCheck_ mt-3'>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll21'
                                        className='checkBox '
                                        name='custom-checkbox-selectAll'
                                        checked={allowPickup} onChange={(e) => setAllowPickup(!allowPickup)}
                                    />
                                    <label htmlFor='custom-checkbox-selectAll21' className='checkBoxTxt '>Local Pickup</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll31'
                                        className='checkBox '
                                        name='custom-checkbox-selectAll'
                                        checked={allowDelivery} onChange={(e) => setAllowDelivery(!allowDelivery)}
                                    />
                                    <label htmlFor='custom-checkbox-selectAll31' className='checkBoxTxt '>JOBR Delivery</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll41'
                                        className='checkBox '
                                        name='custom-checkbox-selectAll'
                                        checked={allowDropoff} onChange={(e) => setAllowDropoff(!allowDropoff)}
                                    />
                                    <label htmlFor='custom-checkbox-selectAll41' className='checkBoxTxt '>Local Drop-off</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll51'
                                        className='checkBox '
                                        name='custom-checkbox-selectAll'
                                        checked={allowShipping} onChange={(e) => setAllowShipping(!allowShipping)}
                                    />
                                    <label htmlFor='custom-checkbox-selectAll51' className='checkBoxTxt '>Shipping</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop={true}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "DefaultaddressModal" ? "defaultaddressModal" : ""
                }
                size={modalDetail.flag === modalDetail.flag === "DefaultaddressModal" || modalDetail.flag === "DefaultaddressModal" ? "md" : "md"}
                child={

                    modalDetail.flag === 'DefaultaddressModal' ? <DefaultaddressModal flag={pathname} close={() => handleOnCloseModal()} />
                        : ""
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>


    )
}

export default AddaddressModal