import React, { useState, useEffect } from "react";
import { dots, threeDot, modalCancel } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import MyProductDetials from "./myProductDetails";
import DataTable from "react-data-table-component";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductListAsync,
  getProductServiceListApiAsync,
  getServiceListApiAsync,
  selectProductData,
} from "./productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { deleteProductApiAsync } from "../dashboard/dashboardSlice";
import { toast } from "react-toastify";
import EditVariantModal from "./editVariantModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditVarientsModal from "./EditVarientsModal";

const ProductListView = (props) => {
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState({});
  const { seller_id } = { ...props };
  console.log(seller_id, "from Table list view");
  const [productList, setProductList] = useState([]);
  const productData = useSelector(selectProductData);
  let ProductDatas = productData?.payload?.data;
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [oldSubcatIds, setOldSubCatIds] = useState(props?.subCategoryId);
  const [oldBrandIds, setOldBrandIds] = useState(props?.brandId);
  const [callPagination, setCallPagination] = useState(false);
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const deleteHandle = (row) => {
    Swal.fire({
      title: "Do you want to delete the product?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            product_ids: [row?.id],
          },
          tokenData: auth?.payload?.token,
        };
        setDeleteLoading(true);
        dispatch(deleteProductApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getProductList();
            setDeleteLoading(false);
            toast.success(obj?.msg);
          })
          .catch((obj) => {
            setDeleteLoading(false);
          });
      }
    });
  };

  const columns1 = [
    {
      selector: (row) => {
        let selectedAddressIds = row?.supplies?.map(
          (v) => v?.seller_address_id
        );
        let serviceSelected = props?.addressData
          ?.filter((value) => selectedAddressIds?.includes(value?.id))
          ?.map((v, i) => {
            return v?.format_address;
          });
        console.log(serviceSelected, "serviceSelected");
        return (
          <>
            <div className="productViewCard mb-4 listCardPosition  ">
              <img
                src={row.image}
                alt="image"
                className="img-fluid productImage_"
              />
              <div className="productDetials">
                <div className="productViewHead">
                  <h1 className="appointHead">{row?.name} </h1>
                </div>
                <ul className="productCategory">
                  <li>
                    <p className="smallHeading ">SKU</p>
                    <p className="smallHeading">{row?.sku}</p>
                  </li>
                  <li>
                    <p className="smallHeading ">UPC</p>
                    <p className="smallHeading">{row?.barcode}</p>
                  </li>
                  {serviceSelected?.map((item, index) => (
                    <li className="locateFlex">
                      <p className="smallHeading">Location {serviceSelected?.length > 1 && index + 1}</p>
                      <span className="smallHeading text-start locateAddress">{item}</span>
                    </li>
                  ))}
                  <li>
                    <p className="smallHeading">Category</p>
                    <p className="smallHeading">{row?.category?.name}</p>
                  </li>
                  <li>
                    <p className="smallHeading">Sub-category</p>
                    <p className="smallHeading">{row?.sub_category?.name}</p>
                  </li>
                  <li>
                    <p className="smallHeading">Brand</p>
                    <p className="smallHeading">{row?.brand?.name}</p>
                  </li>
                  <li>
                    <p className="smallHeading">Weight</p>
                    <p className="smallHeading">
                      {row?.weight && row?.weight != 0 ? row?.weight + row?.weight_unit : "--"}
                    </p>
                  </li>
                  {/* <li>
                    <p className="smallHeading">Stock</p>
                    <p className="smallHeading">
                      {row?.supplies ? row?.supplies[0]?.total_quantity : ""}
                    </p>
                  </li> */}
                  {/* <li>
                    <p className="smallHeading">Available to Sell</p>
                    <p className="smallHeading">
                      {row?.supplies ? row?.supplies[0]?.rest_quantity : ""}
                    </p>
                  </li> */}
                  {/* <li>
                    <p className='smallHeading'>POS Price</p>
                    <p className='smallHeading'>{`${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "pos") ? '$' : ''}${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "pos")?.selling_price}`}</p>
                  </li>
                  <li>
                    <p className='smallHeading'>Online Price</p>
                    <p className='smallHeading'>{`${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "b2c") ? '$' : ''}${row?.supplies[0]?.supply_prices?.find(v => v?.app_name === "b2c")?.selling_price ?? "-"}`}</p>
                  </li> */}
                </ul>
              </div>
              <div className="cardDropImg">
                <div className="dropdown tableDropdown ms-3 ">
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="barMenus"
                  >
                    {/* <i className="fal fa-ellipsis-v icon_"></i> */}
                    <img
                      src={threeDot}
                      alt="threeDotimage"
                      className="img-fluid"
                    />
                  </span>
                  <div
                    className="dropdown-menu tableDropdownMenus "
                    aria-labelledby="barMenus"
                  >
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "MyProductDetials",
                        });
                        setKey(Math.random());
                        setSelectedProduct(row);
                      }}
                    >
                      View
                    </li>
                    {/* <li
                      className="dropdown-item"
                      onClick={() => {
                        setModalDetail({ show: true, flag: "editVarients" });
                        setKey(Math.random());
                        setSelectedProduct(row);
                      }}
                    >
                      Edit Supply
                    </li> */}
                    {/* <li className='dropdown-item'>Active</li> */}
                    <li
                      className="dropdown-item"
                      onClick={() =>
                        history.push({ pathname: `/editProducts`, state: row })
                      }
                    >
                      Edit
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => deleteHandle(row)}
                    >
                      Delete
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCallPagination(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
    setCallPagination(true);
  };

  const paginationOptions = {
    // rowsPerPageText: 'Rows per page:',
    // rangeSeparatorText: 'of',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'All',
  };

  const getProductList = (page) => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        type: "physical",
        app_name: "merchant",
        lat: 32.5534,
        long: 76.1258,
        category_ids: props?.categoryId ? props?.categoryId : [],
        sub_category_ids: props?.subCategoryId
          ? (props?.subCategoryId).toString()
          : [],
        brand_id: props?.brandId ? (props?.brandId).toString() : [],
        page: currentPage,
        limit: perPage,
      },
    };
    if (props?.startDate != null && props?.endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(props?.startDate).format("YYYY-MM-DD"),
        end_date: moment(props?.endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    } else {
      params = {
        tokenData: auth?.payload?.token,
        postData: {
          seller_id: seller_id
            ? seller_id
            : auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
          type: "physical",
          app_name: "merchant",
          lat: 32.5534,
          long: 76.1258,
          category_ids: props?.categoryId ? props?.categoryId : [],
          sub_category_ids: props?.subCategoryId
            ? (props?.subCategoryId).toString()
            : [],
          brand_id: props?.brandId ? (props?.brandId).toString() : [],
          page: currentPage,
          filter_deleted: true,
          limit: perPage,
        },
      };
    }
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setProductList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCallPagination(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let subcatdata = props?.subCategoryId;
    setOldSubCatIds(subcatdata);
    let branddata = props?.brandId;
    setOldBrandIds(branddata);
    const areCategoryEqual =
      props?.subCategoryId?.length === oldSubcatIds?.length &&
      oldSubcatIds?.every(
        (value, index) => value === props?.subCategoryId[index]
      );
    const areBrandEqual =
      props?.brandId?.length === oldBrandIds?.length &&
      oldBrandIds?.every((value, index) => value === props?.brandId[index]);

    if (
      props?.subCategoryId?.length > 0 &&
      props.statusCategory === true &&
      areCategoryEqual === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.subCategoryId?.length > 0 &&
      props.statusCategory === false &&
      areCategoryEqual === true
    ) {
      getProductList();
    }
    if (
      props?.endDate == null &&
      props?.subCategoryId?.length === 0 &&
      props?.brandId?.length === 0 &&
      props.statusCategory === false &&
      props.statusBrand === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.subCategoryId?.length === 0 &&
      props?.brandId?.length === 0 &&
      props.statusCategory === false &&
      props.statusBrand === false
    ) {
      getProductList();
    }
    if (
      props?.brandId?.length > 0 &&
      props.statusBrand === true &&
      areBrandEqual === false
    ) {
      getProductList();
    }
    if (
      props?.endDate != null &&
      props?.brandId?.length > 0 &&
      props.statusBrand === false &&
      areBrandEqual === true
    ) {
      getProductList();
    }
    if (callPagination === true) {
      getProductList();
    }
    if (
      (areCategoryEqual === false && props.statusCategory === true) ||
      (areBrandEqual === false && props.statusBrand === true)
    ) {
      getProductList();
    }
  }, [
    currentPage,
    perPage,
    props?.subCategoryId,
    props?.brandId,
    props?.endDate,
  ]);

  return (
    <>
      <div className="brandListmain_">
        <section className="productListView  mt-3 viewGrid">
          <div className="row">
            <DataTable
              className="columsGrid"
              columns={columns1}
              // data={props.ProductList}
              data={ProductDatas}
              progressPending={loading}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              // defaultSortFieldId={1}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationComponentOptions={paginationOptions}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </div>

          <CustomModal
            key={key}
            show={modalDetail.show}
            // backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={
              modalDetail.flag === "MyProductDetials"
                ? "myProductDetails"
                : modalDetail.flag === "editVarient"
                  ? "EditVarients"
                  : ""
            }
            size={
              modalDetail.flag === "MyProductDetials" ||
                modalDetail.flag === "editVarients"
                ? "xl"
                : "md"
            }
            child={
              modalDetail.flag === "MyProductDetials" ? (
                <MyProductDetials
                  close={() => handleOnCloseModal()}
                  selectedProductId={selectedProduct?.id}
                />
              ) : modalDetail.flag === "editVarients" ? (
                <EditVarientsModal
                  type="product"
                  selectedProduct={selectedProduct}
                  close={() => handleOnCloseModal()}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "editVarients" ? (
                <>
                  <h4 className="modalHeading_">Edit Supply</h4>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOnCloseModal}
                    className="modal_cancel"
                  >
                    <img
                      src={modalCancel}
                      className="ModalCancel"
                      alt="modalcancelImg"
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </section>
      </div>
    </>
  );
};

export default ProductListView;
