import React, { useState, useEffect } from 'react'
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createWalletProfileAsync } from '../../dashboard/dashboardSlice';
import { setupAddressApiAsync } from '../../dashboard/dashboardSlice';
import { getAddressApiAsync } from '../../auth/authSlice';
import CountryList from 'country-list-with-dial-code-and-flag';

const AddnewaddressModal = (props) => {
    const auth = useSelector(selectLoginAuth);
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const [address, setAddress] = useState("")
    const [cityValue, setCityValue] = useState("")
    const [stateValue, setStateValue] = useState("")
    const [countryValue, setCountryValue] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [zipcode, setZipCode] = useState("")
    const [apt, setApt] = useState("")
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const toastId = React.useRef(null)
    const [piLoading, setPiLoading] = useState(false)
    const dispatch = useDispatch()

    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
        }
        setAddress(address);
        geocodeByAddress(address)
            .then((results) => {
                // fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
            })
            .catch((error) => { });
    };
    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
            })
            .catch((error) => { });
    };
    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address);

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
            else if (results[0].address_components[j].types[0] == 'street_number') {
                // setApt(results[0].address_components[j].long_name);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        setPiLoading(true)

        let addressData = {
            postData: {
                "address_type": "shipping",
                "street_address": address,
                "country": countryValue,
                "state": stateValue,
                "city": cityValue,
                "zipcode": zipcode,
                "state_code": stateCode,
                "country_code": countryCode,
                "longitude": longitude,
                "latitude": latitude
            },
            tokenData: auth?.payload?.token
        }

        if (apt) {
            addressData = {
                postData: {
                    ...addressData.postData,
                    "apt": apt
                },
                tokenData: auth?.payload?.token
            }
        }

        await dispatch(setupAddressApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setPiLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setPiLoading(false)
                        props.shippingAddress();
                        props.close()
                    }
                    )
                    .catch((obj) => {
                        setPiLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setPiLoading(false)
            })
    }

    const handleAddAddress = async () => {
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        setPiLoading(true)

        let addressData = {
            postData: {
                "address_type": "shipping",
                "street_address": address,
                "country": countryValue,
                "state": stateValue,
                "city": cityValue,
                "zipcode": zipcode,
                "state_code": stateCode,
                "country_code": countryCode,
                "longitude": longitude,
                "latitude": latitude
            },
            tokenData: auth?.payload?.token
        }
        await dispatch(setupAddressApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setPiLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setPiLoading(false)
                        props.shippingAddress();
                        props.close()
                    }
                    )
                    .catch((obj) => {
                        setPiLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setPiLoading(false)
            })

    }

    return (
        <>
            <div className='businesscard_ commonBody'>
                <div></div>
                <form className=' form_horizontal'
                // onSubmit={(e) => { handleSubmit(e) }}
                >
                    <div className='row'>
                        <div className='mb-4 form-group col-md-12'>
                            {/* <select className='customform-control select'>
                            <option value="Business Type" selected>{}United States of America </option>
                            <option value="product">Product</option>
                            <option value="service">Services</option>
                        </select> */}
                            <div className='inputGroup'>
                                <input
                                    className="customform-control "
                                    placeholder="256-864-1272"
                                    name="text"
                                    type="text"
                                    // value={CountryList?.findOneByCountryCode("us").flag+" "+auth?.payload?.user?.user_profiles?.current_address?.country}
                                    value={CountryList?.findOneByDialCode(auth?.payload?.user?.user_profiles?.phone_code).flag + " " + auth?.payload?.user?.user_profiles?.current_address?.country}
                                />
                            </div>
                        </div>
                        <div className='mb-4 form-group col-md-12'>
                            <div className='inputGroup'>
                                <input
                                    className="customform-control "
                                    placeholder="256-864-1272"
                                    name="text"
                                    type="text"
                                    value={CountryList?.findOneByDialCode(auth?.payload?.user?.user_profiles?.phone_code).flag + " " + auth?.payload?.user?.user_profiles?.full_phone_number}

                                />
                            </div>
                        </div>

                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">First Name</label>
                            <input
                                type="text"
                                className="customform-control"
                                name="text"
                                placeholder="Anna"
                                value={auth?.payload?.user?.user_profiles?.firstname}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Last name</label>
                            <input
                                className="customform-control id_password"
                                placeholder="Fiorentino"
                                name="text"
                                type="text"
                                value={auth?.payload?.user?.user_profiles?.lastname}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-12'>
                            <label className="deatilsHead mb-3">Address</label>
                            <div className='inputGroup'>
                                {
                                    hide ?
                                        <span
                                            className="customform-control countrytags_ w-100"
                                            onClick={() => setHide(!hide)}
                                        >{displayValue}</span>
                                        :
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={autoCompleteHandleChange}
                                            onSelect={autoCompleteHandleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ['us', 'ind'],
                                                }
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Street Address',
                                                            className: 'location-search-input customform-control countrytags_',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion, index) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                    key={index}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                }
                            </div>
                        </div>
                        <div className='mb-4 form-group col-md-12'>
                            <label className="deatilsHead mb-3">Apt</label>
                            <input
                                className="customform-control id_password"
                                placeholder="Apartment optional"
                                name="text"
                                type="text"
                                value={apt} onChange={e => setApt(e.target.value)}
                            />
                        </div>

                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Zip Code</label>
                            <input
                                type="number"
                                className="customform-control"
                                name="number"
                                placeholder="Zip code"
                                value={zipcode} onChange={e => setZipCode(e.target.value)}

                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">City</label>
                            <input
                                className="customform-control id_password"
                                placeholder="City"
                                name="text"
                                type="text"
                                value={cityValue} onChange={e => setCityValue(e.target.value)}
                            />
                        </div>
                        {/* <div className='mb-4 form-group col-md-12'>
                        <label className="deatilsHead mb-2">Country</label>
                        <select className='customform-control select'>
                            <option value="Business Type" selected>Country </option>
                            <option value="product">Product</option>
                            <option value="service">Services</option>
                        </select>
                    </div> */}
                        <div className='mb-4 form-group col-md-12'>
                            <label className="deatilsHead mb-3">Country</label>
                            <div className='inputGroup'>
                                <input
                                    className="customform-control id_password"
                                    placeholder="Country"
                                    name="text"
                                    type="text"
                                    value={countryValue} onChange={e => setCountryValue(e.target.value)}

                                />
                            </div>
                        </div>

                    </div>

                </form>
            </div>
            <div className='row mt-4 mb-2'>
                <div className='checkboxInner_ mb-4 form-group '>
                    <input type="checkbox" id="register_business" name="register_business" value="register_business"
                    />
                    <label htmlFor="register" className='ms-2'>Save as defult shippng address</label><br />
                </div>
                <div className='addBtn_' >
                    <button className='primaryBtnOuter' type="button" onClick={() => handleAddAddress()}>
                        <span>Add</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddnewaddressModal