import React, { useState } from 'react'
import { clickImg, rightArrow } from '../../../utilities/images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { buySubscriptionApiAsync, getMerchantActiveSubscriptionAsync, getMerchantStepStatusAsync, getWalletBallanceApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const AnnualBilling = (props) => {
    const history = useHistory();
    const toastId = React.useRef(null)
    const [newSelectedId, setNewSelectedId] = useState('')
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const getMerchantStepStatus = () => {
        dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
            })
            .catch((obj) => {
            })
    }

    const handlePlanData = (amount, id) => {
        setNewSelectedId(id)
        // if (amount * 100 > props?.balance) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Insufficents Balance");
        //     }
        //     return false
        //     // history.push('/bank-details')
        // }
        let params = {
            postData: {
                "plan_id": id
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(buySubscriptionApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                getMerchantStepStatus()
                dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
                    .then(unwrapResult)
                    .then((obj) => {
                        let data = {
                            tokenData: auth?.payload?.token
                        }
                        dispatch(getWalletBallanceApiAsync(data))
                            .then(unwrapResult)
                            .then((obj) => {
                                setLoading(false)
                            })
                            .catch((obj) => {
                                setLoading(false)
                            })
                        props?.close()
                        toast.success(obj?.msg)
                        props.handleChange(obj?.payload)
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    return (
        <div className='annualBilling'>
            <div className='row'>
                {
                    props?.data?.map((val, index) => {
                        return (
                            <div className='col-md-4' key={index}>
                                <div className={`${props?.selectedPlan?.length > 0 && (props?.selectedPlan[0]?.plan_id._id === val?._id) ? "active" : ""} ${val?.name === "Basic" ? 'plansDetailsContainer basicPlan' : val?.name === "Standard" ? 'plansDetailsContainer standardPlan' : 'plansDetailsContainer premiumPlan'}`}>
                                    <h4 className='innerHeadingSky'>{val?.name}</h4>
                                    <p className='smallText'>{val?.description}</p>
                                    <div className='mb-4 mt-4'>
                                        <h4 className='planAmount'>${val?.amount}</h4>
                                        <span className='smallText'>Per Year</span>
                                    </div>
                                    <div className={`appIncludedArea`}>
                                        <h6 className='subheadText mb-2'><b>Apps Included:</b></h6>
                                        <div className='appIncludedValues'>
                                            <div className='appIncludedInputs'>
                                                <input type="radio" id={`${val?._id}JOBRB2C`} name={`${val?._id}JOBRB2C`} value="JOBR B2C" className='customRadio' checked={val?.included_apps?.includes('b2c')} disabled />
                                                <label htmlFor={`${val?._id}JOBRB2C`} className=''>JOBR B2C</label>
                                            </div>
                                            <div className='appIncludedInputs'>
                                                <input type="radio" id={`${val?._id}JOBRWallet`} name={`${val?._id}JOBRWallet`} value="JOBR Wallet" className='customRadio' checked={val?.included_apps?.includes('wallet')} disabled />
                                                <label htmlFor={`${val?._id}JOBRWallet`}>JOBR Wallet</label>
                                            </div>
                                            <div className='appIncludedInputs'>
                                                <input type="radio" id={`${val?._id}JOBRPOS`} name={`${val?._id}JOBRPOS`} value="JOBR POS" className='customRadio' checked={val?.included_apps?.includes('pos')} disabled />
                                                <label htmlFor={`${val?._id}JOBRPOS`}>JOBR POS</label>
                                            </div>
                                            <div className='appIncludedInputs'>
                                                <input type="radio" id={`${val?._id}JOBRDriver`} name={`${val?._id}JOBRDriver`} value="JOBRDriver" className='customRadio' checked={val?.included_apps?.includes('driver')} disabled />
                                                <label htmlFor={`${val?._id}JOBRDriver`}>JOBR Driver</label>
                                            </div>
                                            <div className='appIncludedInputs'>
                                                <input type="radio" id={`${val?._id}JOBRB2B`} name={`${val?._id}JOBRB2B`} value="JOBRB2B" className='customRadio' checked={val?.included_apps?.includes('b2b')} disabled />
                                                <label htmlFor={`${val?._id}JOBRB2B`}>JOBR B2B</label><br />
                                            </div>
                                            <button onClick={() => { props?.selectedPlan?.length > 0 && (props?.selectedPlan[0]?.plan_id._id === val?._id) ? void (0) : handlePlanData(val?.amount, val?._id) }} className='getStarted mt-4 mb-4' disabled={loading}>
                                                {loading && val?._id == newSelectedId && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                {props?.selectedPlan?.length > 0 && (props?.selectedPlan[0]?.plan_id._id === val?._id) ? "Active Plan" : "Get Started"}
                                                {props?.selectedPlan?.length > 0 && (props?.selectedPlan[0]?.plan_id._id === val?._id) ? <></> : <img src={rightArrow} alt='' className='ms-3' />}
                                            </button>
                                        </div>
                                        <ul className='otherMainus'>
                                            {
                                                val?.tags?.map((val, index) => {
                                                    return (
                                                        <li className='otherItems' key={index}>
                                                            <img src={clickImg} className='' alt='' />
                                                            <span className=''>{val}</span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AnnualBilling