import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import { deleteicon, renameIcon, viewIcon } from '../../../../utilities/images';
import moment from 'moment-timezone'
import { calendar } from '../../../../utilities/images';
import DatePicker from "react-datepicker";
import products from "../../../db/catalog.json"
import FaqCategoryEditModal from './faqcategoryeditModal';
import CustomModal from '../../../components/shared/CustomModal';
import { getFaqCategoryDataApiAsync, updateFaqCategoryApiAsync, deleteCategoryApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import FaqCatViewModal from './faqCatViewModal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


const ConfigCategory = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [myValue, setMyValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [faqCategoryData, setFaqCategoryData] = useState([]);
  const [selectedData, setSelectedData] = useState('');
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });


  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const handleFaqCategoryActive = (status, id) => {
    let params = {
      "postData": {
        'id': id,
        'is_active': !status
      },
      "tokenData": auth?.payload?.token
    }
    dispatch(updateFaqCategoryApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        faqCategoryDataHandle()
      })
      .catch((obj) => {
      })
  }

  const columns1 = [
    {
      name: '#',
      selector: (row,i) => i+1,
      width: "70px"
    },
    {
      name: "Category",
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.category_name}</span>
          </>
        );
      },
    },
    {
      name: 'FAQ',
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.faq_count}</span>
          </>
        );
      },
    },
    {
      name: 'Create',
      selector: row => {
        return (
          <>
            <span className='textParagh '>{moment(row?.created_at).format("DD MMM, YYYY")}</span>
          </>
        );
      },
    },
    {
      name: 'Update ',
      selector: row => {
        return (

          <>
            <span className='textParagh '>{moment(row?.updated_at).format("DD MMM, YYYY")}</span>
          </>
        );
      },
    },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center justify-content-center configswitch_'>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  checked={row?.is_active}
                  onChange={() => handleFaqCategoryActive(row?.is_active, row?.id)}
                />
              </div>
              <img src={renameIcon} className='actionImg ms-4 me-4' alt='Delete-Icon'
                onClick={() => {
                  setSelectedData(row)
                  setModalDetail({ show: true, flag: 'FaqcategoryeditModal' });
                  setKey(Math.random());
                }} />
              <img src={viewIcon} className='actionImg me-3' alt='View-Icon' onClick={() => {
                setSelectedData(row)
                setModalDetail({ show: true, flag: 'FaqcategoryViewModal' });
                setKey(Math.random());
              }} />
              <img src={deleteicon} className='actionImg me-3' alt='View-Icon' onClick={() => handleDeleteCategory(row?.id)} />
            </div>
          </>
        );
      },
      name: ''
    },

  ];
  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }

  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: 'Do you want to delete the category?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          "postData": {
            id: id,
          },
          "tokenData": auth?.payload?.token
        }
        setDeleteLoading(true)
        dispatch(deleteCategoryApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setDeleteLoading(false)
            //   childRef.getProductList();
            // childRef.current.getProductListParent();
            toast.success(obj?.msg);
            faqCategoryDataHandle()
          })
          .catch((obj) => {
            setDeleteLoading(false)
          })
      }
    })

  }

  useImperativeHandle(ref, () => ({
    getProductParent() {
      let params = {
        "postData": {
          page: currentPage,
          limit: perPage
        },
        "tokenData": auth?.payload?.token
      }
      setLoading(true)
      dispatch(getFaqCategoryDataApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setFaqCategoryData(obj?.payload?.data)
          setTotalRows(obj?.payload?.total)
          setLoading(false)
        })
        .catch((obj) => {
          setLoading(false)
        })
    }
  }));

  const faqCategoryDataHandle = () => {
    let params = {
      "postData": {
        page: currentPage,
        limit: perPage
      },
      "tokenData": auth?.payload?.token
    }
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        "start_date": moment(startDate).format('YYYY-MM-DD'),
        "end_date": moment(endDate).format('YYYY-MM-DD')
      }
      params = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    setLoading(true)
    dispatch(getFaqCategoryDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setFaqCategoryData(obj?.payload?.data)
        setTotalRows(obj?.payload?.total)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    faqCategoryDataHandle()
  }, [endDate, perPage, currentPage])

  return (
    <>
      <div className='faqHeading_ mt-5 mb-4'>
        <h1 className='subheading_ fw-500'>Categories- FAQ</h1>
        <p className='subtext_ fw-normal mt-2'>Copy
          First, a disclaimer – the entire process of writing a blog post often takes more.</p>
      </div>
      <div className='tableSectionBackground'>
        <div className='tableFilters mt-3 mb-3'>
          <div className='col-md-12'>
            <div className='row'>
              {/* <div className='col-md-12'>
            <div className="calenderIcon ">
              <img src={calendar} alt="calender" />
           <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> 
              <DatePicker
                className='cateDate'
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Date"
              />
            </div>
          </div> */}
              <div className='col-lg-6'>
                <div className='productMainCategory'>
                  {/* <div className='categroyBox'>
                    <select className="cateSelectdFilter" required>
                      <option value="" selected>
                        All Status
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                    </select>
                  </div> */}
                  {/* <div className='categroyBox'>
                    <select className="cateSelectdFilter" required>
                      <option value="" selected>
                        All Categories
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                    </select>
                  </div> */}
                </div>
              </div>
              {/* <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >Country </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div>
            <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >State </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div>
            <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >Id type </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div> */}
            </div>
          </div>
        </div>

        <div className='configFaq'>
          <DataTable
            pagination
            columns={columns1}
            data={faqCategoryData}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
          <CustomModal
            key={key}
            show={modalDetail.show}
            // backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={modalDetail.flag === "FaqcategoryeditModal" ? "FaqcategoryeditModal" : modalDetail.flag === 'FaqcategoryViewModal' ? "FaqcategoryViewModal" : ""}
            size={modalDetail.flag === "FaqcategoryeditModal" || modalDetail.flag === 'FaqcategoryViewModal' ? "lg" : "md"}
            child={modalDetail.flag === 'FaqcategoryeditModal' ? <FaqCategoryEditModal selectedData={selectedData} close={() => handleOnCloseModal()} faqCategoryDataHandle={() => faqCategoryDataHandle()} /> :
              modalDetail.flag === 'FaqcategoryViewModal' ? <FaqCatViewModal selectedData={selectedData} close={() => handleOnCloseModal()} />
                : ""
            }
            header={
              <div className='modalHeader_'>
                <div className="common_">
                  <h2 className="modalHeading">
                    {modalDetail.flag === 'FaqcategoryeditModal' ? 'Edit' : 'View'} FAQ Category
                  </h2>
                  <button className="closeButton">
                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
                </div>
              </div>
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </div>
    </>

  )
})

export default ConfigCategory
