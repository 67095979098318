import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react'
import { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectLoginAuth } from '../../auth/authSlice';
import { getAddressApiAsync } from '../../auth/authSlice';
import { setupAddressApiAsync, updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice';

const AddAddressModal = (props) => {
    const history = useHistory()
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const [address, setAddress] = useState("")
    const [formattedAddress, setFormattedAddress] = useState("")
    const [cityValue, setCityValue] = useState("")
    const [stateValue, setStateValue] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [stateCode, setStateCode] = useState("")
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [countryValue, setCountryValue] = useState("");
    const [zipcode, setZipCode] = useState("")
    const [apt, setApt] = useState("")
    const toastId = React.useRef(null)
    const [piLoading, setPiLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const [key, setKey] = useState(Math.random());

    const extractStreetAddress = (address) => {
        // Extract the street address from the address
        const parts = address.split(',');
        if (parts.length >= 3) {
          return `${parts[0].trim()}, ${parts[1].trim()}`;
        }
      };

    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
            setAddress("")
        }
        // setAddress(address);
        setFormattedAddress(address)
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
                fillAddressDetails(results);
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
            })
            .catch((error) => { });
    };
    const fillAddressDetails = (results) => {
        // setAddress(results[0].formatted_address);
        setFormattedAddress(results[0].formatted_address)

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
            else if (results[0].address_components[j].types[0] == 'street_number') {
                // setApt(results[0].address_components[j].long_name);
            }
            else if(results[0].address_components[j].types[0] == 'route'){
                setAddress(results[0].address_components[j].long_name)
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formattedAddress.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        setPiLoading(true)

        let addressData = {
            postData: {
                "id": props?.addressId,
                "street_address": address ? address :extractStreetAddress(formattedAddress),
                "country": countryValue,
                "state": stateValue,
                "city": cityValue,
                "zipcode": zipcode,
                "latitude": latitude,
                "longitude": longitude,
                "country_code": countryCode,
                "state_code": stateCode,
                "address_type": props?.addressType,
                "format_address": formattedAddress
            },
            tokenData: auth?.payload?.token
        }

        if (apt) {
            addressData = {
                postData: {
                    ...addressData.postData,
                    "apt": apt
                },
                tokenData: auth?.payload?.token
            }
        }

        dispatch(updateSellerInfoApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setPiLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setPiLoading(false)
                        props.close()
                    }
                    )
                    .catch((obj) => {
                        setPiLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setPiLoading(false)
            })
    }

    useEffect(() => {
        setAddress(props?.addressData?.street_address)
        setFormattedAddress(props?.addressData?.format_address)
        setZipCode(props?.addressData.zipcode);
        setCityValue(props?.addressData.city);
        setStateValue(props?.addressData.state);
        setStateCode(props?.addressData?.state_code)
        setCountryValue(props?.addressData.country);
        setCountryCode(props?.addressData?.country_code);
        setApt(props?.addressData.apt ? props?.addressData.apt : "");
        setLatitude(props?.addressData?.latitude)
        setLongitude(props?.addressData?.longitude)
    }, [])

    return (
        <div className='addAddress'>
            <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                <div className='row'>
                    {/* <div className='col-md-12 text-center d-flex text-end align-items-center mb-5'> */}
                        {/* <h4 className='titleTxt '>Add Address</h4> */}
                        {/* <h4 className='titleTxt '>Edit Address</h4>
                        <button className="closeButton ms-auto">
                            <i className="las la-times" onClick={props.close}></i>
                        </button> */}
                    {/* </div> */}

                </div>
                <div className='row'>
                    <div className='mb-4 form-group col-md-12'>
                        <label className="deatilsHead mb-3">Current Address</label>
                        <div className='inputGroup'>
                        {
                        hide ?
                          <span
                            className="customform-control countrytags_ w-100"
                            onClick={() => setHide(!hide)}
                          >{displayValue}</span>
                          :
                            <PlacesAutocomplete
                                value={formattedAddress}
                                onChange={autoCompleteHandleChange}
                                onSelect={autoCompleteHandleSelect}
                                searchOptions={{
                                    componentRestrictions: {
                                        country: ['us', 'ind'],
                                    }
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Street Address',
                                                className: 'location-search-input customform-control',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                        key={index}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            }
                        </div>
                    </div>
                    <div className='mb-4 form-group col-md-12'>
                        <label className="deatilsHead mb-3">Country</label>
                        <div className='inputGroup'>
                            <input
                                className="customform-control id_password"
                                placeholder="Country"
                                name="text"
                                type="text"
                                value={countryValue} onChange={e => setCountryValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='mb-4 form-group col-md-6'>
                        <label className="deatilsHead mb-3">Apt.</label>
                        <input
                            type="text"
                            className="customform-control"
                            name="text"
                            placeholder="Apt."
                            value={apt} onChange={e => setApt(e.target.value)}
                        />
                    </div>
                    <div className='mb-4 form-group col-md-6'>
                        <label className="deatilsHead mb-3">City</label>
                        <input
                            className="customform-control id_password"
                            placeholder="City"
                            name="text"
                            type="text"
                            value={cityValue} onChange={e => setCityValue(e.target.value)}
                        />
                    </div>
                    <div className='mb-4 form-group col-md-6'>
                        <label className="deatilsHead mb-3">Zip Code</label>
                        <input
                            type="number"
                            className="customform-control"
                            name="number"
                            placeholder="Zip code"
                            value={zipcode} onChange={e => setZipCode(e.target.value)}
                        />
                    </div>
                    <div className='mb-4 form-group col-md-6'>
                        <label className="deatilsHead mb-3">State</label>
                        <input
                            className="customform-control id_password"
                            placeholder="State"
                            name="text"
                            type="text"
                            value={stateValue} onChange={e => setStateValue(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mt-3 mb-2'>
                    <button className='primary_btn' disabled={piLoading}>
                        {piLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Submit</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddAddressModal