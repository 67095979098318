import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteIconImg, editIconImg, galleryImg, imgGallery1, infoImg, modalCancel, solarBoxlinear } from '../../../utilities/images'
import ProductDetails from './ProductDetails'
import ProductImageAdd from './productImageAdd'
import AddProductHeader from './EditProductHeader'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { imageUploadAsync } from '../message/messageSlice';
import { toast } from 'react-toastify';
import { formValidator } from './formValidator'
import { editProductApiAsync } from '../dashboard/dashboardSlice'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { editProductValidator } from './editProductValidator'
import Multiselect from 'multiselect-react-dropdown'
import EditVarientsModal from '../myProducts/EditVarientsModal'
import CustomModal from '../../components/shared/CustomModal'
import ProductvarientModal from './productvarientModal'
import AddAttributesModal from './addAttributesModal'
import SaleTaxModal from '../product/saleTaxModal'
import CreateOptionModal from '../product/createOptionModal'
import CreatevariationModal from './createvariationModal'
import UpdatePriceModal from './updatePriceModal'
import DeleteVariantModal from './deleteVariantModal'
import EditvariantModal from './editvariantModal'
import VariantImageUpload from '../product/VariantImageUpload'

const EditProduct = (props) => {
    const history = useHistory();
    const location = useLocation();
    const selectedProductDtails = location?.state;
    const [optionListNew, setOptionListNew] = useState("")
    const [optionList, setOptionList] = useState(selectedProductDtails?.product_options ? selectedProductDtails?.product_options : [])
    const [optionsList, setOptionsList] = useState([]);
    const [checkedOptionList, setCheckedOptionList] = useState([]);
    const [checkedValue, setCheckedValue] = useState([]);
    const [variantOptionList, setVariantOptionList] = useState(selectedProductDtails?.product_variants ? selectedProductDtails?.product_variants : [])
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [selectedOption, setSelectedOption] = useState("")
    const [selectedIndex, setSelectedIndex] = useState("")
    const [selectedVariant, setSelectedVariant] = useState("")
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    console.log(deliveryOptions,"deliveryOptions");
    const [selectedVariantIndex, setSelectedVariantIndex] = useState("")
    const [singleImage, setSingleImage] = useState();
    const [loading, setLoading] = useState(false);
    const [costPrice, setCostPrice] = useState(selectedProductDtails?.supplies[0]?.supply_variants[0]?.cost_price ? selectedProductDtails?.supplies[0]?.supply_variants[0]?.cost_price : "")
    console.log(costPrice, "costPrice");

    const [unitType, setUnitType] = useState("per_unit");
    const [allSelectChecked, setAllSelectChecked] = useState(false);
    const [selectAll, setSelectAll] = useState(false)
    const [combinations, setCombinations] = useState([]);
    const [selectedAttributesValue, setSelectedAttributesValue] = useState([]);
    const [variantPairs, setVariantPairs] = useState([])
    const [ingredients, setIngredients] = useState([]);
    const [supplyData, setSupplyData] = useState([]);
    console.log(supplyData, "supplyData");

    const [key, setKey] = useState(Math.random());

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleAttributes = (e) => {
        setIngredients(e);
    }

    const handleModalChange = (flag) => {
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const handleUnitChange = (e) => {
        setUnitType(e.target.value)
    }
    const handleSelectAllChange = (e) => {
        if (e.target.checked == false) {
            setAllSelectChecked(false);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(false)
            )
            setCheckedValue([]);
        } else {
            setAllSelectChecked(true);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(true)
            )
            const updatedCheckedState = checkedOptionList.map((item, index) =>
                index
            );
            setCheckedValue(updatedCheckedState);
        }
    }

    function replaceImage(arr, indexes, url) {
        indexes.forEach(index => {
            if (arr[index]) {
                arr[index].image = url;
            }
        });

        setOptionsList(arr);
        return arr;
    }

    const handlechangeShippingOrPickup = (optionValue) => {
        if (deliveryOptions.includes(optionValue)) {
            setDeliveryOptions(deliveryOptions.filter(option => option !== optionValue));
        } else {
            setDeliveryOptions([...deliveryOptions, optionValue]);
        }
    }

    const uploadImage = (e) => {
        if (checkedValue.length <= 0) {
            toast.error("Please select checkbox")
        }
        else {
            const imageFile = e.target.files[0];
            let formData = new FormData();
            formData.append(`file`, imageFile);

            let params = {
                file: formData,
                token: auth?.payload?.token
            }
            setLoading(true)
            dispatch(imageUploadAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSingleImage(obj?.payload[0]?.filePath);
                    const newImage = obj?.payload[0]?.filePath;
                    replaceImage(optionsList, checkedValue, newImage)
                    setLoading(false)
                }
                )
                .catch((obj) => {
                    setLoading(false)
                });
        }
    }

    const handleOptions = (attributes, data, value) => {
        console.log(attributes, data, value, 'handle options data');
        setOptionsList(attributes);
        setCombinations(data);
        setCheckedOptionList(
            new Array(attributes?.length).fill(false)
        )
        setSelectedAttributesValue(value)
    }

    const handleOptionRemove = (index) => {
        const list = [...optionList];
        list.splice(index, 1);
        setOptionList(list);

        if (list?.length > 0) {
            function generateTagPairs(optionIndex, currentPair, result) {
                if (optionIndex === list.length) {
                    result.push(currentPair);
                    return;
                }

                list[optionIndex].options.forEach(tag => {
                    generateTagPairs(
                        optionIndex + 1,
                        currentPair.length === 0 ? tag : `${currentPair}/${tag}`,
                        result
                    );
                });
            }

            const tagPairs1 = [];
            generateTagPairs(0, '', tagPairs1);
            const generateOptionCombinations = (data) => {
                if (data.length === 0) return [[]];

                const [first, ...rest] = data;
                const restCombinations = generateOptionCombinations(rest);

                const combinations = [];

                for (const option of first.options) {
                    for (const combination of restCombinations) {
                        combinations.push([{ "display_name": first.display_name, "value": option }, ...combination]);
                    }
                }

                return combinations;
            };

            const tagPairs = generateOptionCombinations(list);

            const convertedArray = tagPairs.map((item, index) => {
                return {
                    options: item,
                    variant_name: tagPairs1[index],
                    image: "",
                    prices: [],
                    upc: "",
                    weight: "",
                    unit: "",
                    cost_price: costPrice,
                    is_available_for_sale: false,
                    stocks: []
                };
            });
            setVariantOptionList(convertedArray)
        }
        else {
            setVariantOptionList([])
        }

    }

    const handleVariantRemove = (index) => {
        const list = [...variantOptionList];
        list.splice(index, 1);
        setVariantOptionList(list);
    }

    const handleCheckboxChange = (variantName) => {
        if (selectedVariants.includes(variantName)) {
            setSelectedVariants(selectedVariants.filter(item => item !== variantName));
        } else {
            setSelectedVariants([...selectedVariants, variantName]);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedVariants([]);
        } else {
            const allVariantNames = variantOptionList.map(variant => variant.variant_name);
            setSelectedVariants(allVariantNames);
        }
        setSelectAll(!selectAll);
    };

    const handleDeleteSelected = () => {
        const filteredVariants = variantOptionList.filter(variant => !selectedVariants.includes(variant.variant_name));
        setVariantOptionList(filteredVariants);
        setSelectedVariants([]);
    };

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    // const [unitType, setUnitType] = useState(selectedProductDtails?.supplies ? selectedProductDtails?.supplies[0].quantity_unit : "per_unit");
    console.log(selectedProductDtails, "selectedproduct details");

    const backRoute = localStorage.getItem("backButtonRoute")
    const [productId, setProductId] = useState(selectedProductDtails?.id)
    const [productDetails, setProductDetails] = useState({
        "productName": selectedProductDtails?.name,
        "service_id": selectedProductDtails?.service_id,
        "category_id": selectedProductDtails?.category_id,
        "sub_category_id": selectedProductDtails?.sub_category_id,
        "brand_id": selectedProductDtails?.brand_id
    })
    // const [costPrice, setcostPrice] = useState(selectedProductDtails?.price ? selectedProductDtails?.price : "")
    const [productDescription, setProductDescription] = useState(selectedProductDtails?.description)
    const [imageDetails, setImageDetails] = useState({
        image: selectedProductDtails?.image,
        images: selectedProductDtails?.product_images?.map(v => v?.url)
    })
    const allImages = selectedProductDtails?.product_images ?? []
    const [weightUnit, setWeightUnit] = useState(selectedProductDtails?.weight_unit ? selectedProductDtails?.weight_unit : "kg");
    const [dimensions, setDimensions] = useState({
        weight: selectedProductDtails?.weight,
        length: selectedProductDtails?.length,
        breadth: selectedProductDtails?.breadth,
        height: selectedProductDtails?.height
    });
    const [barCode, setBarCode] = useState(selectedProductDtails?.barcode ? selectedProductDtails?.barcode : "");
    const [sku, setSku] = useState(selectedProductDtails?.sku ? selectedProductDtails?.sku : "");
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [successLoader, setSuccessLoader] = useState(false)
    const [allAddress, setAllAddress] = useState([]);
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const handleWeightUnit = (e) => {
        setWeightUnit(e.target.value)
    }

    const handleProductDetails = (e, flag) => {
        if (flag == "description") {
            setProductDescription(e)
        } else {
            setProductDetails(e)
        }
    }

    const supplyDetails = (data) => {
        setSupplyData(data)
    }

    const handleImageDetails = (e) => {
        setImageDetails(e)
    }

    const handleBarcodeChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setBarCode(event.target.value);
        }
    };
    const handleSkuChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setSku(event.target.value);
        }
    };

    // const handleUnitChange = (e) => {
    //     setUnitType(e.target.value)
    // }

    const handledimensions = (e) => {
        const { name, value } = e.target;
        let data = { ...dimensions, [name]: value }
        setDimensions(data);
    }

    const handleSave = () => {
        let modifiedVariant
        var imagesToDelete = allImages.filter((item) => !imageDetails.images.includes(item.url));
        imagesToDelete = imagesToDelete.map((item) => item.id);
        let modifiedOptionList = optionList && optionList.map(obj => {
            let newObj = { ...obj };
            delete newObj['deleted_at'];
            return newObj;
        });
        let modifiedVariantOptionList = variantOptionList && variantOptionList.map(obj => {
            let newObj = { ...obj };
            delete newObj['deleted_at'];
            return newObj;
        });

        const newsupplyData = supplyData.map(item => {
            const { activePos, activeB2c, activeB2b, ...rest } = item;
            return rest;
        });
        console.log(newsupplyData, "newsupplyData");
        let product_attribute = {
            "id": productId,
            "name": productDetails?.productName,
            "category_id": (productDetails?.category_id)?.toString(),
            "sub_category_id": (productDetails?.sub_category_id)?.toString(),
            "brand_id": (productDetails?.brand_id).toString(),
            "service_id": productDetails?.service_id,
            "description": productDescription,
            "type": "physical",
            "barcode": barCode,
            "image": imageDetails?.image,
            "images": imageDetails?.images,
            "cost_price": costPrice,
            "weight_unit": "lb",
            // "quantity_unit": unitType,
            // "dimensions": dimensions,   
        }
        
        if (deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) {
            product_attribute = { ...product_attribute, 
             "weight": dimensions?.weight,
            "length": dimensions?.length,
            "height": dimensions?.height,
            "breadth": dimensions?.breadth }
        }
        if (deliveryOptions.length > 0 && !deliveryOptions?.includes("shipping") && dimensions?.weight) {
            product_attribute = { ...product_attribute, 
             "weight": dimensions?.weight,}
        }
        if (deliveryOptions.length > 0 && !deliveryOptions?.includes("shipping") && dimensions?.length) {
            product_attribute = { ...product_attribute, 
            "length": dimensions?.length}
        }
        if (deliveryOptions.length > 0 && !deliveryOptions?.includes("shipping") && dimensions?.height) {
            product_attribute = { ...product_attribute, 
            "height": dimensions?.height}
        }
        if (deliveryOptions.length > 0 && !deliveryOptions?.includes("shipping") && dimensions?.breadth) {
            product_attribute = { ...product_attribute, 
            "breadth": dimensions?.breadth}
        }
        if (deliveryOptions?.length > 0) {
            modifiedVariant = modifiedVariantOptionList && modifiedVariantOptionList.map(obj => {
                let newObj = { ...obj };
                newObj['delivery_options'] = deliveryOptions
                return newObj;
            });
        }
        if (variantOptionList?.length > 0) {
            product_attribute = { ...product_attribute, "product_options": modifiedOptionList, "product_variants": modifiedVariant }
        }
        if (variantOptionList?.length <= 0 || !variantOptionList) {
            product_attribute = { ...product_attribute, "supplies": newsupplyData }
        }
        // if (allAddressIds?.length > 0) {
        //     product_attribute = { ...product_attribute, "seller_address_ids": allAddressIds, }
        // }
        if (imagesToDelete.length > 0) {
            product_attribute.deleted_image_ids = imagesToDelete
        }
        if (sku) {
            product_attribute = { ...product_attribute, "sku": sku }
        }

        console.log(product_attribute, "edited data");
        let result = editProductValidator(product_attribute, dimensions, deliveryOptions)

        if (result) {

            setSuccessLoader(true)
            let params = {
                "postData": product_attribute,
                "tokenData": auth?.payload?.token
            }

            console.log(params, "edited data");
            dispatch(editProductApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSuccessLoader(false)
                    toast.success(obj?.msg)
                    history.push(`/productList`)
                }
                )
                .catch((obj) => {
                    setSuccessLoader(false)
                });
        }
    }

    useEffect(() => {
        // Function to find supply_variant_prices for a given product_variant_id
        function findPrices(product_variant_id) {
            const priceObject = selectedProductDtails?.supplies[0]?.supply_variants?.find(item => item.product_variant_id === product_variant_id);
            return priceObject?.supply_variant_prices?.length > 0 ? priceObject?.supply_variant_prices?.map(({ selling_price, margin_percentage, price_type, app_name }) => ({ selling_price, margin_percentage, price_type, app_name })) : [];
        }

        function findStock(product_variant_id) {
            const priceObject = selectedProductDtails?.supplies[0]?.supply_variants?.find(item => item.product_variant_id === product_variant_id);
          
          console.log(priceObject,"price objectsssssssssssssss");
            return [{
                "seller_address_id": priceObject?.supply?.seller_address_id,
                "stock_action": priceObject?.stock_action,
                "qty": priceObject?.rest_quantity,
                "total_stock": priceObject?.total_quantity,
                "is_available_for_sale": priceObject?.is_available_for_sale
            }]
        }

        // Clone variantOptionList and add prices property to each element
        const updatedVariantOptionList = variantOptionList.map(item => ({
            ...item,
            prices: findPrices(item.id),
            stocks: findStock(item.id),
            cost_price: selectedProductDtails?.supplies[0]?.supply_variants[0]?.cost_price
        }));
        // Update variantOptionList with the updated clone
        setVariantOptionList(updatedVariantOptionList);


        const deliveryOptions = selectedProductDtails?.supplies[0]?.supply_variants[0]?.delivery_options
            ?.split(",")
            ?.map((option) => {
                switch (option.trim()) {
                    case "3":
                        return "pickup";
                    case "1":
                        return "delivery";
                    case "4":
                        return "shipping";
                    default:
                        return null; // Handle invalid options
                }
            })?.filter(option => option !== null);

        setDeliveryOptions(deliveryOptions?.length > 0 ? deliveryOptions : [])
    }, [])

    // useEffect(() => {
    //     let modifiedVariant = selectedProductDtails?.product_variants ? selectedProductDtails?.product_variants?.map(obj => {
    //         let newObj = { ...obj };
    //         newObj['cost_price'] = selectedProductDtails?.supplies[0]?.supply_variants[0]?.cost_price;
    //         return newObj;
    //     }):"";
    //     setVariantOptionList(modifiedVariant)
    // }, [])


    console.log(variantOptionList, "lissssssssssssssssssssssssssssssss");

    return (
        <div className='addProductManually'>
            {/* Start FixedTopHeader */}
            <AddProductHeader flag={backRoute} save={(e) => handleSave(e)} successLoader={successLoader} />
            {/* End FixedTopHeader */}
            <div className="productRightWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 '>
                            {/* Start Uploaded ProductImg Area */}

                            {/* <ProductImageAdd imageData={(e) => handleImageDetails(e)} /> */}

                            <ProductImageAdd imageData={(e) => handleImageDetails(e)} updateImageDetails={setImageDetails} oldImages={imageDetails} />
                            {/* End Uploaded ProductImg Area */}

                            {/* Start ProductDetails Area */}
                            <div className='mt-4'>
                                <div className='row'>
                                    {/* Start ProductDetails Area */}
                                    <div className='col-md-7'>
                                        <ProductDetails productDetails={(e, flag) => handleProductDetails(e, flag)} />
                                        {/* Start CostPrice Area */}
                                        <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group  col-md-6 mb-4'>
                                                    <label className="subheaderHeading mb-2">Cost Price
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control"
                                                            placeholder="Cost Price"
                                                            name="text"
                                                            type="number"
                                                            value={costPrice}
                                                            onChange={(e) => setCostPrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='form-group col-md-6 mb-4'>
                                                    <label className='subheaderHeading mb-2' htmlFor='amount'>Unit</label>
                                                    <select autoComplete="new-password" className='customform-control select' value={unitType} onChange={(e) => handleUnitChange(e)}>
                                                        <option value="per_unit">per unit</option>
                                                        <option value="per_box">per box</option>
                                                        <option value="per_bag">per bag</option>
                                                        <option value="per_pound">per pound</option>
                                                        <option value="per_feet">per feet</option>
                                                        <option value="per_gallon">per gallon</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        {/* End CostPrice Area */}

                                    </div>
                                    {/* End ProductDetails Area */}
                                    <div className='col-md-5'>
                                        {/* Start ShippingPickup Area */}
                                        <div className='outerArea'>
                                            <h3 className='subheaderHeading mb-4'>Shipping or Pickup</h3>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="pickup"
                                                            value="pickup"
                                                            checked={deliveryOptions?.includes("pickup")}
                                                            onChange={() => { handlechangeShippingOrPickup("pickup") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Pickup</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="delivery"
                                                            value="delivery"
                                                            checked={deliveryOptions?.includes("delivery")}
                                                            onChange={() => { handlechangeShippingOrPickup("delivery") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Delivery</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="shipping"
                                                            value="shipping"
                                                            checked={deliveryOptions?.includes("shipping")}
                                                            onChange={() => { handlechangeShippingOrPickup("shipping") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Shipping</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>

                                            <form className='editFormControl mt-4'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">Product weight
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='row align-items-center '>
                                                                <div className='col-xl-12 col-lg-12 col-md-12 pe-0'>
                                                                    <div className='inputGroup'>
                                                                        <input className="customform-control weightPlace" placeholder="lb" name="weight" type="number" value={dimensions?.weight} onChange={(e) => { handledimensions(e) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="deatilsHead mb-2 fw-normal">Product Dimensions
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <ul className="dimensionsGroup mb-3">
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Length</i></label>
                                                            <input type="number" className="form-control" placeholder="length" name="length" aria-describedby="button-addon1" value={dimensions?.length} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Breadth</i></label>
                                                            <input type="number" className="form-control" placeholder="breadth" name="breadth" aria-describedby="button-addon1" value={dimensions?.breadth} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Height</i></label>
                                                            <input type="number" className="form-control" placeholder="height" name="height" aria-describedby="button-addon1" value={dimensions?.height} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <span className='linkText'>in</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </form>
                                        </div>
                                        {/* End ShippingPickup Area */}

                                        <div className='outerArea mt-3'>
                                            <form className='editFormControl'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">Barcode (ISBN, UPC, GTIN, etc.)
                                                    </label>
                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='inputGroup'>
                                                                <input className="customform-control " maxLength={14} onKeyDown={blockInvalidChar} placeholder="barcode" name="barcode" type="number" value={barCode} onChange={handleBarcodeChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='outerArea mt-3'>
                                            <form className='editFormControl'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">SKU (Stock Keeping Unit)
                                                    </label>
                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='inputGroup'>
                                                                <input className="customform-control " placeholder="sku" name="upc" type="text" value={sku} onChange={handleSkuChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {(variantOptionList <= 0 || !variantOptionList) && <EditVarientsModal type="product" selectedProduct={selectedProductDtails} supplyDetails={(data) => { supplyDetails(data) }} costPrice={costPrice} />}
                                </div>
                            </div>
                            {/* End ProductDetails Area */}

                            {/*----- Start Options Area -----*/}
                            <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                <h2 className='headingBlue mb-4'>
                                    Options
                                    <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                </h2>

                                {
                                    optionList?.length > 0 ?
                                        <div className='selectedOptionsbox_'>
                                            <div className='table-responsive'>
                                                <table className='customTable table'>
                                                    <tbody className='customBody'>
                                                        {optionList?.map((val, index) => {
                                                            return (
                                                                <>
                                                                    <tr className='customRows'>
                                                                        <td className='custTableData'>
                                                                            <span className='subtext_'>{val?.option_name}</span>
                                                                        </td>
                                                                        <td className='custTableData'>
                                                                            <span className='subtext_'>
                                                                                {
                                                                                    val?.options?.map((value, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    value
                                                                                                }{
                                                                                                    (index == ((val?.options?.length) - 1)) ? '' : '/'
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td className='custTableData'>
                                                                            <span className="editimage_" onClick={() => { setModalDetail({ show: true, flag: "ProductvarientModalEdit" }); setKey(Math.random()); setSelectedOption(val); setSelectedIndex(index) }}><i class="fas fa-pen"></i></span>
                                                                            <span className="crossIcon" onClick={() => handleOptionRemove(index)}><i className="las la-times" aria-hidden="true"></i></span>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : <div className='mb-3'>
                                            No option found
                                        </div>
                                }

                                <button className='addOptions' onClick={() => { setModalDetail({ show: true, flag: "ProductvarientModal" }); setKey(Math.random()); }}>
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" className='me-2' xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.25021 0C9.43145 7.6429e-06 9.60655 0.0656426 9.74314 0.184767C9.87973 0.303891 9.96856 0.468446 9.99321 0.648L10.0002 0.75L10.0012 8H17.2542C17.4442 8.00006 17.6272 8.07224 17.766 8.20197C17.9049 8.3317 17.9893 8.5093 18.0022 8.69888C18.0152 8.88846 17.9557 9.07589 17.8358 9.2233C17.7159 9.37071 17.5445 9.4671 17.3562 9.493L17.2542 9.5H10.0012L10.0032 16.75C10.0029 16.9399 9.93054 17.1227 9.80073 17.2613C9.67092 17.4 9.49334 17.4842 9.30383 17.4971C9.11433 17.5099 8.92702 17.4503 8.77972 17.3304C8.63242 17.2105 8.5361 17.0392 8.51021 16.851L8.50321 16.749L8.50121 9.5H1.25221C1.06219 9.49994 0.879272 9.42776 0.740421 9.29803C0.60157 9.1683 0.517139 8.9907 0.504187 8.80112C0.491235 8.61154 0.550728 8.42411 0.670645 8.2767C0.790563 8.12929 0.961963 8.0329 1.15021 8.007L1.25221 8H8.50221L8.50021 0.75C8.50021 0.551088 8.57923 0.360322 8.71988 0.21967C8.86054 0.0790175 9.0513 0 9.25021 0Z" fill="#275AFF" />
                                    </svg>
                                    Add Options
                                </button>
                                <div className='mainVariants_'>
                                    <h4 className='modalHeading mb-3'>Variants</h4>
                                    <div className='innervarient'>
                                        <div className='varientHeadFlex'>
                                            {variantOptionList?.length > 0 && <input type="checkbox" id="option1" name="option1" checked={selectAll}
                                                onChange={handleSelectAll} className='me-2' />}
                                            <label for="option1"> <strong>{variantOptionList?.length} variations</strong></label><br />
                                            {selectedVariants?.length > 0 && <div className='varientBtns'>
                                                <button type='submit' className='varientBtnBlue btnDelete' onClick={() => { setModalDetail({ show: true, flag: "DeleteVariantModal" }); setKey(Math.random()); }} >Delete</button>
                                                <button type='button' className='varientBtnBlue btnPrice' onClick={() => {
                                                    if (costPrice) {
                                                        setModalDetail({ show: true, flag: "PriceVariantModal" }); setKey(Math.random());
                                                    }
                                                    else { toast.error("Please enter cost price first") }
                                                }}>Update Price</button>
                                                <button type='button' onClick={() => { setModalDetail({ show: true, flag: "variantImageUploadModal" }); setKey(Math.random()); }} className='varientBtnBlue'>Upload Image</button>
                                            </div>}
                                        </div>
                                        <div className='productvariantmain_'>

                                            {
                                                variantOptionList?.length > 0 ?
                                                    variantOptionList?.map((val, index) => {
                                                        return (
                                                            <div className='variationtablebox_ table-responsive' key={index}>
                                                                <table className='table variantTables mt-2 mb-0 addVarientTable'>
                                                                    <tbody>
                                                                        <tr className='variantTable' >
                                                                            <td className='variantItems align-top'>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className="rolecheckbox_">
                                                                                        <input
                                                                                            className="me-2"
                                                                                            type="checkbox"
                                                                                            id="rolecheck"
                                                                                            name="rolecheck"
                                                                                            checked={selectedVariants.includes(val.variant_name)}
                                                                                            onChange={() => handleCheckboxChange(val.variant_name)}
                                                                                        />
                                                                                        <label className="contentheadtext_" htmlFor="view1"></label>
                                                                                    </div>
                                                                                    <div className='d-flex'>
                                                                                        <div className='imgFileBox me-2'>
                                                                                            {/* <input
                                                                                            type="file"
                                                                                            name="file"
                                                                                            id="file"
                                                                                            accept="image/*"
                                                                                            className="file-input__input"
                                                                                        /> */}
                                                                                            <label className="" htmlFor="file-input">
                                                                                                <span className='galleryCircle me-2'>
                                                                                                    <img src={val?.image ? val?.image : galleryImg} alt='profile' className='' />

                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <p className='subtextDark_' >{val?.variant_name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className='variantItems align-top'>
                                                                                <table width="100%">
                                                                                    <tr>
                                                                                        <td> <p className='textinner_'>{val?.prices?.find(val => val?.app_name == "b2c") ? val?.prices?.find(val => val?.app_name == "b2c")?.selling_price : 'B2C Price'}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td> <p className='textinner_'>{val?.prices?.find(val => val?.app_name == "pos") ? val?.prices?.find(val => val?.app_name == "pos")?.selling_price : 'POS price'}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td> <p className='textinner_'>{val?.prices?.find(val => val?.app_name == "b2b") ? val?.prices?.find(val => val?.app_name == "b2b")?.selling_price : 'B2B Price'}</p></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className='variantItems align-top'>
                                                                                <p className='textinner_'>{val?.upc ? val?.upc : 'UPC'}</p>
                                                                            </td>
                                                                            <td className='variantItems align-top'>
                                                                                <div className=' d-flex align-items-center justify-content-between'>
                                                                                    <p className='textinner_'>{val?.weight ? val?.weight : 'Weight'}</p>
                                                                                    <div className="dropdown tableDropdown  dropend">
                                                                                        <span
                                                                                            className="dropdown-toggle "
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                            id="barMenus"
                                                                                        >
                                                                                            <i className="fal fa-ellipsis-v icon_"></i>
                                                                                        </span>
                                                                                        <div
                                                                                            className="dropdown-menu tableDropdownMenus categoryDrop "
                                                                                            aria-labelledby="barMenus" >
                                                                                            <li
                                                                                                className="dropdown-item px-0 "
                                                                                                onClick={() => { setModalDetail({ show: true, flag: "EditvariantModal" }); setKey(Math.random()); setSelectedVariantIndex(index); setSelectedVariant(val) }}>
                                                                                                <img src={editIconImg} alt='deleteAlert' className='img-fluid me-2' />
                                                                                                Edit
                                                                                            </li>
                                                                                            <li
                                                                                                className="dropdown-item px-0"
                                                                                                onClick={() => { setModalDetail({ show: true, flag: "EditvariantModalStock" }); setKey(Math.random()); setSelectedVariantIndex(index); setSelectedVariant(val) }}>
                                                                                                <img src={solarBoxlinear} alt='deleteAlert' className='img-fluid me-2' />
                                                                                                Stock Management
                                                                                            </li>
                                                                                            <li
                                                                                                className="dropdown-item px-0"
                                                                                                onClick={() => handleVariantRemove(index)}>
                                                                                                <img src={DeleteIconImg} alt='deleteAlert' className='img-fluid me-2' />
                                                                                                Delete
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )
                                                    })
                                                    : "No variant found"
                                            }

                                        </div>
                                    </div>
                                </div>

                                {optionsList.length > 0 ? <>
                                    <div className='row mt-4'>
                                        <div className='col-md-12'>
                                            <h4 className='deatilsHead mb-2 fw-normal'>Varients</h4>
                                        </div>
                                    </div>
                                    <div className='row align-items-center'>
                                        <div className='col-md-6'>
                                            <input
                                                type="checkbox"
                                                className='checkBox'
                                                id="view1"
                                                checked={allSelectChecked}
                                                onChange={(e) => { handleSelectAllChange(e) }}
                                            />
                                            <label htmlFor="view1" className='storeText'>{optionsList?.length} variations</label>
                                        </div>
                                        <div className='col-md-6 text-end'>
                                            <div className='justify-content-end variantBtn'>
                                                <div className='imgFileBox me-3'>
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        accept="image/*"
                                                        className="file-input__input"
                                                        onChange={(e) => { uploadImage(e) }}
                                                        required=""
                                                    />
                                                    <label className="varientImgBtn" htmlFor="file-input">
                                                        <img src={imgGallery1} alt="" className='me-2 img-fluid imgGalleryBtn' />
                                                        Image Upload
                                                    </label>
                                                </div>
                                                <button className='varientImgBtn' onClick={() => { setModalDetail({ show: true, flag: "AddOptions" }); setKey(Math.random()); }}>Manage</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='table-responsive'>
                                    </div>
                                </>
                                    : <></>}

                            </div>
                            {/*----- End Options Area -----*/}
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids=
                {
                    modalDetail.flag === "ProductvarientModal" ? "productvarientModal" :
                        modalDetail.flag === "AddAttributesModal" ? "addAttributesModal" : modalDetail.flag === "saleTax" ? "saleModalTax" :
                            modalDetail.flag === "CreatevariationModal" ? "createvariationModal" : (modalDetail.flag === "EditvariantModal" || modalDetail.flag === "EditvariantModalStock") ? "editvariantModal" : modalDetail.flag === "PriceVariantModal" ? "priceVariantModal" : modalDetail.flag === "DeleteVariantModal" ? "deleteVariantModal" : ""
                }
                size={modalDetail.flag === "AddOptions" ? "xl" :
                    modalDetail.flag === "EditvariantModal" ? "xl" :
                        modalDetail.flag === "variantImageUploadModal" ? "lg" : "xl"
                }
                child=
                {
                    modalDetail.flag === 'ProductvarientModal' ? <ProductvarientModal costPrice={costPrice} optionList={optionList} close={() => handleOnCloseModal()} options={(attributes, data, value) => handleOptions(attributes, data, value)} combinations={combinations} selectedAttributesValue={selectedAttributesValue} handleModalChange={(e) => handleModalChange(e)} setVariantPairs={setVariantPairs} setOptionList={setOptionListNew} />
                        : modalDetail.flag === 'ProductvarientModalEdit' ? <ProductvarientModal optionList={selectedOption} selectedIndex={selectedIndex} close={() => handleOnCloseModal()} options={(attributes, data, value) => handleOptions(attributes, data, value)} combinations={combinations} selectedAttributesValue={selectedAttributesValue} handleModalChange={(e) => handleModalChange(e)} setVariantPairs={setVariantPairs} setOptionList={setOptionList} optionListData={optionList} isEdit={true} setVariantOptionList={setVariantOptionList} />
                            : modalDetail.flag === 'AddAttributesModal' ? <AddAttributesModal close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                                : modalDetail.flag === 'saleTax' ? <SaleTaxModal close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                                    : modalDetail.flag === 'createVariant' ? <CreateOptionModal close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} />
                                        : modalDetail.flag === 'CreatevariationModal' ? <CreatevariationModal  optionListNew={optionListNew} setOptionList={setOptionList} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} variantPairs={variantPairs} setVariantOptionList={setVariantOptionList} />
                                            :
                                            modalDetail.flag === 'PriceVariantModal' ? <UpdatePriceModal costPrice={costPrice} selectedVariants={selectedVariants} variantOptionList={variantOptionList} setVariantOptionList={setVariantOptionList} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} />
                                                :
                                                modalDetail.flag === 'DeleteVariantModal' ? <DeleteVariantModal handleDeleteSelected={() => handleDeleteSelected()} close={() => handleOnCloseModal()} />
                                                    :
                                                    modalDetail.flag === 'EditvariantModal' ? <EditvariantModal costPrice={costPrice} variantOptionList={variantOptionList} selectedVariant={selectedVariant} selectedVariantIndex={selectedVariantIndex} setVariantOptionList={setVariantOptionList} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} />
                                                        :
                                                        modalDetail.flag === 'EditvariantModalStock' ? <EditvariantModal costPrice={costPrice} isStock={true} variantOptionList={variantOptionList} selectedVariant={selectedVariant} selectedVariantIndex={selectedVariantIndex} setVariantOptionList={setVariantOptionList} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} />
                                                            :
                                                            modalDetail.flag === 'variantImageUploadModal' ? <VariantImageUpload selectedVariants={selectedVariants} variantOptionList={variantOptionList} setVariantOptionList={setVariantOptionList} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} />
                                                                : <></>
                }
                header={
                    <>
                        {modalDetail.flag === 'ProductvarientModal' || modalDetail.flag === 'ProductvarientModalEdit'
                            ?
                            <>
                            </>
                            : modalDetail.flag === 'saleTax' ? <>
                                <h4 className='modalHeading_'>Sales tax</h4>
                                <p onClick={handleOnCloseModal} className='modal_cancel'>
                                    <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                </p>
                            </>
                                :
                                modalDetail.flag === 'CreatevariationModal' ? <>

                                </> :
                                    modalDetail.flag === 'EditvariantModal' ? <>
                                        <h4 className='modalHeading_'>Edit Variants</h4>
                                        <p style={{ cursor: "pointer" }} onClick={handleOnCloseModal} className='modal_cancel'>
                                            <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                        </p>
                                    </> :
                                        modalDetail.flag === 'DeleteVariantModal' ? <>

                                        </> :
                                            modalDetail.flag === 'PriceVariantModal' ? <>
                                                <h4 className='modalHeading_'>Update Price</h4>
                                                <p style={{ cursor: "pointer" }} onClick={handleOnCloseModal} className='modal_cancel'>
                                                    <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                                </p>
                                            </> :
                                                modalDetail.flag === 'variantImageUploadModal' ? <>
                                                    <h4 className='modalHeading_'>Image Upload</h4>
                                                    <p style={{ cursor: "pointer" }} onClick={handleOnCloseModal} className='modal_cancel'>
                                                        <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                                    </p>
                                                </> :
                                                    <>
                                                        <h4 className='modalHeading_'>Add custom attribute</h4>
                                                        <p style={{ cursor: "pointer" }} onClick={handleOnCloseModal} className='modal_cancel'>
                                                            <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                                        </p>
                                                    </>

                        }

                    </>

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default EditProduct