import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bell,
  add_plus,
  profileImg1,
  search_light,
  purchaseImg,
  invoiceHeader,
  userHeader,
  serviceHead,
  staffHead,
  driverHead,
  locateHead,
  modalCancel,
  posArrow,
} from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import {
  getMerchantStepStatusAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
} from "./dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "../../components/shared/CustomModal";
import FindProductModal from "../scalePlan/findProductModal";
import AddaddressModal from "../systemConfig/locations/addaddressModal";
import DefaultaddressModal from "../systemConfig/locations/defaultaddressModal";
import PlansModal from "../scalePlan/plansModal";
import { getUserSettingsApiAsync } from "../systemConfig/systemConfigSlice";
import AddCustomerModal from "../userList/consumer/AddCustomerModal";

function DashboardNavbar(props) {
  const scriptAddedRef = useRef(false);
  const pathname = window.location.pathname;
  const [showBanner, setShowBanner] = useState(true);
  const history = useHistory();
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [key, setKey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const merchantStatus = useSelector(selectMerchantStatus);
  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;
  const openActions = () => {
    document.body.setAttribute("style", "overflow:hidden");
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const pickPlanButtonHandle = () => {
    if (
      merchantStatus?.payload?.wallet_status === false &&
      merchantStatus?.payload?.subscription_status === false
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Create Wallet First");
      }
      history.push("/plans");
    }
    if (
      merchantStatus?.payload?.wallet_status === true &&
      merchantStatus?.payload?.subscription_status === false
    ) {
      setModalDetail({ show: true, flag: "PickPlan" });
      setKey(Math.random());
    }
  };

  const details = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getMerchantStepStatus = () => {
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        obj?.payload?.wallet_status === true
          ? setLoading(true)
          : setLoading(false);
        if (obj?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
        obj?.payload?.wallet_status === true
          ? setLoading(true)
          : setLoading(false);
        if (obj?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
      })
      .catch((obj) => {});
  };

  useEffect(() => {
    getMerchantStepStatus();
  }, []);

  useEffect(() => {
    if (!scriptAddedRef.current) {
      const getLanguagesData = () => {
        let data = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            app_name: "pos",
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getUserSettingsApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            // setLanguagesData(obj?.payload?.languages);
            const languageCode = obj?.payload?.languages
              ?.filter((item) => item?.status === 1)
              ?.map((item) => item?.lang_code)
              ?.filter((val) => val != null);
            // Use Set to remove duplicates
            const formattedLanguageCode = Array.from(new Set(languageCode));
            // setLanguageCode(formattedLanguageCode);
            const includedLanguagesString = formattedLanguageCode.join(",");
            if (formattedLanguageCode.length > 0) {
              const googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                  {
                    pageLanguage: "en",
                    autoDisplay: false,
                    includedLanguages: includedLanguagesString,
                    layout:
                      window.google.translate.TranslateElement.InlineLayout
                        .SIMPLE,
                    multilanguagePage: true,
                  },
                  "google_translate_element"
                );
              };

              const addScript = document.createElement("script");
              addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
              );
              document.body.appendChild(addScript);
              scriptAddedRef.current = true;
              window.googleTranslateElementInit = googleTranslateElementInit;
            }
          })
          .catch((obj) => {
            setLoading(false);
          });
      };
      getLanguagesData();
    }
  }, []);

  const handleGoToNotificationPage = () => {
    history.push("/notifications");
  };

  const handleGoToWebPos = () => {
    window.open("https://webpos.jobr.com/", "_blank");
  };

  return (
    <>
      {/* <div className="languageselect_"><div id="google_translate_element"></div></div> */}
      {!showBanner ||
      merchantStatus?.payload?.subscription_status === true ||
      pathname === "/create-wallet" ||
      pathname === "/wallet" ||
      pathname === "/bank-details" ||
      pathname === "/request-kyc" ||
      pathname === "/IDVerification" ||
      pathname === "/addBankAccount" ||
      pathname === "/businessRegistration" ||
      pathname === "/requestKYB" ||
      pathname === "/business-id-verification" ||
      pathname === "/planSubscription" ? (
        <></>
      ) : (
        <div className="pickPlanContainer mb-4">
          <div className="row align-items-center">
            <div className="col-md-9">
              <p className="pickPlanDes mb-0">
                Build your dream business at your own pace. Enjoy 3 months of
                JOBR for $1/month when you sign up for a monthly Basic or
                Starter plan.
              </p>
            </div>
            <div className="col-md-3 text-end">
              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="pickBtn me-4"
                  onClick={() => pickPlanButtonHandle()}
                >
                  Pick a Plan
                </button>
                <i
                  className="las la-times"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowBanner(false)}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      <nav className="mainHeader">
        <div className="row">
          <span
            onClick={() => {
              // props.openNav();
              openActions();
            }}
            className="col-1 col-sm-1 col-md-1 openbtn2 d-none"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </span>
          <div className="headcontentNav">
            <h2 className="headerHeading_">
              {" "}
              {props?.backShow && (
                <span
                  className="headArrow"
                  style={{ cursor: "pointer" }}
                  onClick={
                    props?.flag === "goBack"
                      ? () => history.goBack()
                      : props?.flag === "customer"
                      ? () => {
                          history.push(props?.backShow, {
                            customerType: "all_customers",
                          });
                        }
                      : () => history.push(props?.backShow)
                  }
                >
                  <i class="fa-solid fa-chevron-left"></i>
                </span>
              )}{" "}
              {props?.title}{" "}
            </h2>
            <ul className="rightNav">
              <li className="rightNavItems">
                {pathname === "/create-wallet" ||
                pathname === "/bank-details" ||
                pathname === "/request-kyc" ||
                pathname === "/IDVerification" ||
                pathname === "/addBankAccount" ||
                pathname === "/businessRegistration" ||
                pathname === "/requestKYB" ||
                pathname === "/business-id-verification" ||
                pathname === "/productPlugin" ||
                pathname === "/withdrawal" ||
                pathname === "/instantbankTransfer" ||
                pathname?.split("/")[1] === `supportticketDetail` ||
                pathname === `/products` ||
                pathname?.split("/")[1] === `orderTrack` ||
                pathname?.split("/")[1] === `orderReview` ||
                pathname === `/productUploadFile` ||
                pathname === `/purchaseReport` ? (
                  <></>
                ) : (
                  // <div className="input-group search_group">
                  //   <img
                  //     src={search_light}
                  //     alt=""
                  //     className="img-fluid searchIcon"
                  //   />
                  //   <input
                  //     type="text"
                  //     className="form-control"
                  //     placeholder="Search here"
                  //     aria-label="Recipient's username"
                  //     aria-describedby="basic-addon2"
                  //   />
                  // </div>
                  <></>
                )}
              </li>

              <li className="rightNavItems">
                <button
                  className="webPosBtn"
                  type="button"
                  onClick={() => handleGoToWebPos()}
                >
                  <img
                    src={posArrow}
                    alt="posArrow image"
                    className="img-fluid"
                  />
                  WebPos
                </button>
              </li>

              {pathname === "/plans" ||
              pathname === "/create-wallet" ||
              pathname === "/products" ||
              pathname === "/bank-details" ||
              pathname === "/request-kyc" ||
              pathname === "/IDVerification" ||
              pathname === "/addBankAccount" ||
              pathname === "/businessRegistration" ||
              pathname === "/requestKYB" ||
              pathname === "/business-id-verification" ||
              pathname === "/locations" ||
              pathname === "/productPlugin" ||
              pathname === "/withdrawal" ||
              pathname === "/Gross-Profits" ||
              pathname === "/Total-Revenue" ||
              pathname === "/Total-Costs" ||
              pathname === "/Total-POS-Orders" ||
              pathname === "/Total-Delivery-Orders" ||
              pathname === "/Total-Shipping-Orders" ||
              pathname === "/customer" ||
              pathname === "/instantbankTransfer" ||
              pathname === `/supportTicket` ||
              pathname?.split("/")[1] === `supportticketDetail` ||
              pathname?.split("/")[1] === `orderTrack` ||
              pathname?.split("/")[1] === `orderReview` ||
              pathname === `/productUploadFile` ||
              pathname === `/purchaseReport` ? (
                <></>
              ) : (
                // <li className="rightNavItems ">
                //   <Link to="#" className="addNew_ takepaymnt_btn">
                //     <span> Take Payment </span>
                //   </Link>
                // </li>
                <></>
              )}

              {/* <li className="rightNavItems dropdown tableDropdown ">
                <Link
                  to="#"
                  className="addNew_ dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <span> Create New </span>{" "}
                  <img src={add_plus} className="icons_plus" alt="ChatImg" />
                </Link>
                <div
                  className="dropdown-menu tableDropdownMenus "
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/campaign")}
                  >
                    {" "}
                    <img
                      src={add_plus}
                      className="img-fluid me-2"
                      alt="ChatImg"
                    />
                    Campaign
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/myPurchases")}
                  >
                    {" "}
                    <img
                      src={purchaseImg}
                      className="img-fluid  me-2"
                      alt="ChatImg"
                    />
                    Purchase{" "}
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/invoice")}
                  >
                    <img
                      src={invoiceHeader}
                      className="img-fluid  me-2"
                      alt="ChatImg"
                    />
                    Invoice
                  </li>
                </div>
              </li> */}
              {/* <li className='rightNavItems d-none'>
                <Link to='#'>
                  <img src={chat} className='icons_' alt='ChatImg' />
                </Link>
              </li> */}
              {pathname === "/products" ||
              pathname === "/productPlugin" ||
              pathname === "/withdrawal" ||
              pathname === "/Gross-Profits" ||
              pathname === "/Total-Revenue" ||
              pathname === "/Total-Costs" ||
              pathname === "/Total-POS-Orders" ||
              pathname === "/Total-Delivery-Orders" ||
              pathname === "/Total-Shipping-Orders" ||
              pathname === "/customer" ||
              pathname?.split("/")[1] === `orderTrack` ||
              pathname?.split("/")[1] === `orderReview` ||
              pathname === "/instantbankTransfer" ||
              pathname === `/supportTicket` ||
              pathname?.split("/")[1] === `supportticketDetail` ||
              pathname === `/productUploadFile` ||
              pathname === `/purchaseReport` ? (
                <></>
              ) : (
                <li className="rightNavItems dropdown tableDropdown ">
                  <Link
                    to="#"
                    className="addNew_ dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="barMenus"
                  >
                    <span>Add New </span>{" "}
                    <img src={add_plus} className="icons_plus" alt="ChatImg" />
                  </Link>
                  <div
                    className="dropdown-menu tableDropdownMenus "
                    aria-labelledby="barMenus"
                  >
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        setModalDetail({ show: true, flag: "FindProduct" });
                        setKey(Math.random());
                      }}
                    >
                      {" "}
                      <img
                        src={add_plus}
                        className="img-fluid me-2"
                        alt="ChatImg"
                      />
                      Product
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        setModalDetail({ show: true, flag: "AddNewCustomer" });
                        setKey(Math.random());
                      }}
                    >
                      {" "}
                      <img
                        src={userHeader}
                        className="img-fluid  me-2"
                        alt="ChatImg"
                      />
                      Customer{" "}
                    </li>
                    {sellerType == "manufacturer" ||
                    sellerType == "whole_seller" ? (
                      ""
                    ) : (
                      <li
                        className="dropdown-item"
                        onClick={() => history.push("/add-service")}
                      >
                        <img
                          src={serviceHead}
                          className="img-fluid  me-2"
                          alt="ChatImg"
                        />
                        Service
                      </li>
                    )}
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        setModalDetail({ show: true, flag: "AddaddressModal" });
                        setKey(Math.random());
                      }}
                    >
                      {" "}
                      <img
                        src={locateHead}
                        className="img-fluid me-2"
                        alt="ChatImg"
                      />
                      Address
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => history.push("/shippingPickup")}
                    >
                      {" "}
                      <img
                        src={driverHead}
                        className="img-fluid  me-2"
                        alt="ChatImg"
                      />
                      Driver{" "}
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => history.push("/createUser")}
                    >
                      <img
                        src={staffHead}
                        className="img-fluid  me-2"
                        alt="ChatImg"
                      />
                      Staff
                    </li>
                  </div>
                </li>
              )}
              <li className="rightNavItems  d-none">
                <Link to="#" className="reviewBtn_">
                  <span>Orders to Review</span>
                </Link>
              </li>
              {pathname?.split("/")[1] === `supportticketDetail` ||
              pathname === `/purchaseReport` ? (
                <></>
              ) : (
                // <li className="rightNavItems">
                //   <Link to="#">
                //     <img src={bell} className="icons_" alt="BellImg" />
                //   </Link>
                // </li>
                <></>
              )}
              {pathname?.split("/")[1] === `supportticketDetail` ||
              pathname === `/purchaseReport` ? (
                <></>
              ) : (
                <li className="rightNavItems">
                  <div className="dropdown adminDropdown">
                    <Link
                      to="#"
                      id="dropdownAdminButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="nameUser">{`${auth?.payload?.user?.user_profiles?.firstname} ${auth?.payload?.user?.user_profiles?.lastname}`}</span>
                      <span className="userProfile">
                        <img
                          src={
                            auth?.payload?.user?.user_profiles?.profile_photo
                              ? auth?.payload?.user?.user_profiles
                                  ?.profile_photo
                              : profileImg1
                          }
                          className="userImg me-2"
                          alt="UserImage"
                        />
                        <i className="fal fa-chevron-down"></i>
                      </span>
                    </Link>
                    <ul
                      className="dropdown-menu dropdownAdminMenus"
                      aria-labelledby="dropdownAdminButton"
                    >
                      <li className="dropdown-item">
                        <Link to="/myProfile" className="dropdown-link">
                          Profile
                        </Link>
                      </li>
                      {/* <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link'>Edit</Link>
                  </li>
                  <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link'>Setting</Link>
                  </li> */}
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop={true}
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "PickPlan"
            ? "pickPlan"
            : modalDetail.flag === "FindProduct"
            ? "findProduct"
            : modalDetail.flag === "MakeOffer"
            ? "createOffer"
            : modalDetail.flag === "AddaddressModal"
            ? "addaddressModal"
            : modalDetail.flag === "DefaultaddressModal"
            ? "defaultaddressModal"
            : modalDetail.flag === "AddNewCustomer"
            ? "addNewCustomer"
            : ""
        }
        size={
          modalDetail.flag === "FindProduct" ||
          modalDetail.flag === "MakeOffer" ||
          modalDetail.flag === "PickPlan"
            ? "xl"
            : modalDetail.flag === "AddaddressModal" ||
              modalDetail.flag === "AddaddressModal"
            ? "md"
            : "md"
        }
        child={
          modalDetail.flag === "PickPlan" ? (
            <PlansModal
              details={(e) => details(e)}
              walletBalance={walletBalance}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "FindProduct" ? (
            <FindProductModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddaddressModal" ? (
            <AddaddressModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "DefaultaddressModal" ? (
            <DefaultaddressModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddNewCustomer" ? (
            <AddCustomerModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "MakeOffer" ? (
            <div className="modalHeader_">
              <div className="common_">
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          ) : modalDetail.flag === "FindProduct" ? (
            <>
              <h4 className="modalHeading_">Add Products</h4>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : modalDetail.flag === "DefaultaddressModal" ? (
            <>
              <div className="addheadLeft_">
                <h4 className="modalHeading_">Default Address </h4>
              </div>
              <div className="addheadRight">
                <button className="addressdiscardBtn me-2" type="submit">
                  Discard
                </button>
                <button className="addresssaveBtn" type="submit">
                  Save
                </button>
              </div>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
}

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// const mapDispatchToProps = dispatch => ({
//   openNav: () => dispatch(openNav()),

// });

export default DashboardNavbar;
