import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAddressApiAsync,
  selectAddressList,
  selectLoginAuth,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { addIcon, infoImg } from "../../../utilities/images";

const EditVarientsModal = (props) => {
    console.log(props,"propsprops");

  const location = useLocation();
  const selectedServiceDetails = location?.state;
  const addressList = useSelector(selectAddressList);
  const [address, setAddress] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedProductDtails = location?.state;
  const [allAddress, setAllAddress] = useState([]);
  const [prices, setPrices] = useState([]);
  const [priceType, setPriceType] = useState("");
  // const [dimensions, setDimensions] = useState({
  //   weight: selectedProductDtails?.weight,
  //   length: selectedProductDtails?.length,
  //   breadth: selectedProductDtails?.breadth,
  //   height: selectedProductDtails?.height,
  // });
  const [sellingChange, setSellingChange] = useState(Math.random());
  const [marginChange, setMarginChange] = useState(Math.random());
  const [posChange, setPosChange] = useState(Math.random());
  const [b2cChange, setB2cChange] = useState(Math.random());
  const [indexChange, setIndexChange] = useState(0);
  const [formData, setFormData] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [type] = useState("physical");
  const [unitType, setUnitType] = useState(
    selectedProductDtails?.quantity_unit
      ? selectedProductDtails?.quantity_unit
      : "per_unit"
  );

  const [activeData, setActiveData] = useState("");
  const [costPrice, setcostPrice] = useState(
    props?.costPrice ? props?.costPrice : ""
  );
  const [sellingPrice, setSellingPrice] = useState("")
  
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleChanged = (value, index, flag) => {
    const data = formData?.map((supply, indexx) => {
      if (index === indexx) {
        if (flag === "Opening") {
          return { ...supply, rest_quantity: value };
        } else {
          return { ...supply, sold_quantity: value };
        }
      }
      return supply;
    });
    setFormData(data);
  };

  const handleChange = (event, index, appName, fieldName) => {
    const { value } = event.target;
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      const priceToUpdateIndex = newFormData[index].prices.findIndex(
        (price) => price.app_name === appName
      );

      if (priceToUpdateIndex !== -1) {
        if (fieldName === "selling_price") {
          newFormData[index].prices[priceToUpdateIndex][fieldName] =
            value === "" ? "" : Number(value);

          // Calculate margin_percentage if selling price is not empty
          if (value !== "" && props?.costPrice) {
            newFormData[index].prices[priceToUpdateIndex]["margin_percentage"] =
              (
                ((Number(value) - Number(props?.costPrice)) /
                  Number(props?.costPrice)) *
                100
              ).toFixed(2);
          } else {
            // If selling price is empty, set margin_percentage to empty as well
            newFormData[index].prices[priceToUpdateIndex]["margin_percentage"] =
              "";
          }
        } else if (fieldName === "margin_percentage") {
          newFormData[index].prices[priceToUpdateIndex][fieldName] =
            value === "" ? "" : Number(value);

          // Calculate selling_price if margin_percentage is not empty
          if (value !== "" && props?.costPrice) {
            newFormData[index].prices[priceToUpdateIndex]["selling_price"] = (
              parseFloat(props?.costPrice) +
              parseFloat(props?.costPrice) * (parseFloat(value) / 100)
            ).toFixed(2);
          } else {
            // If margin_percentage is empty, set selling_price to empty as well
            newFormData[index].prices[priceToUpdateIndex]["selling_price"] = "";
          }
        }
      }
      return newFormData;
    });
  };

  // const handleCustomPriceChange = (
  //   event,
  //   index,
  //   appName,
  //   fieldName,
  //   itemIndex
  // ) => {

  //   setSellingPrice(event.target.value ? event.target.value:"")
  //   const { value } = event.target;
  //   setFormData((prevFormData) => {
  //     const newFormData = [...prevFormData];
  //     const priceToUpdate = newFormData[index].prices.find(
  //       (price) =>
  //         price.app_name === appName && price.price_type === "quantity_base"
  //     );
  //     if (priceToUpdate && priceToUpdate.bundle_price[itemIndex]) {
  //       // Update the field value
  //       priceToUpdate.bundle_price[itemIndex][fieldName] = value;

  //       // Check if fieldName is "selling_price"
  //       if (fieldName === "selling_price") {
  //         // Check if cost_price is available and valid
  //         const costPrices = costPrice;
  //         if (!isNaN(costPrices) && costPrices !== 0) {
  //           // Calculate margin based on selling price
  //           const sellingPrice = parseFloat(value);
  //           const marginPercentage =
  //             ((sellingPrice - costPrices) / costPrices) * 100;

  //           // Update margin_percentage
  //           priceToUpdate.bundle_price[itemIndex].margin_percentage =
  //             marginPercentage.toFixed(2);
  //         } else {
  //           // If cost_price is not available or invalid, set margin_percentage to blank
  //           priceToUpdate.bundle_price[itemIndex].margin_percentage = "";
  //         }
  //       }
  //     }
  //     return newFormData;
  //   });
  // };


  const handleCustomPriceChange = (
    event,
    index,
    appName,
    fieldName,
    itemIndex
) => {
    const { value } = event.target;
    setFormData((prevFormData) => {
        const newFormData = [...prevFormData];
        const priceToUpdate = newFormData[index].prices.find(
            (price) =>
                price.app_name === appName && price.price_type === "quantity_base"
        );
        if (priceToUpdate && priceToUpdate.bundle_price[itemIndex]) {
            // Update the field value
            priceToUpdate.bundle_price[itemIndex][fieldName] = value;

            // Check if fieldName is "margin_percentage"
            if (fieldName === "margin_percentage") {
                // Check if cost_price is available and valid
                const costPrices = costPrice;
                if (!isNaN(costPrices) && costPrices !== 0) {
                    // Calculate selling price based on margin percentage
                    const marginPercentage = parseFloat(value);
                    const sellingPrice = costPrices / (1 - (marginPercentage / 100));

                    // Update selling_price
                    priceToUpdate.bundle_price[itemIndex].selling_price =
                        sellingPrice.toFixed(2);
                } else {
                    // If cost_price is not available or invalid, set selling_price to blank
                    priceToUpdate.bundle_price[itemIndex].selling_price = "";
                }
            } else if (fieldName === "selling_price") {
                // Calculate margin percentage based on selling price
                const sellingPrice = parseFloat(value);
                if (!isNaN(sellingPrice) && props.costPrice !== 0) {
                    const marginPercentage =
                        ((sellingPrice - props.costPrice) / props.costPrice) * 100;

                    // Update margin_percentage
                    priceToUpdate.bundle_price[itemIndex].margin_percentage =
                        marginPercentage.toFixed(2);
                } else {
                    // If selling_price is not available or invalid, set margin_percentage to blank
                    priceToUpdate.bundle_price[itemIndex].margin_percentage = "";
                }
            }
        }
        return newFormData;
    });
};
  const handleCheckboxChange = (event, index, fieldName, appName) => {
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      console.log(newFormData, "newFormData");
      newFormData[index][fieldName] = event;
      if (event) {
        newFormData[index].prices = [
          ...newFormData[index]?.prices,
          {
            app_name: appName,
            price_type: "fixed",
            selling_price: "",
            margin_percentage: "",
          },
        ];
      } else {
        newFormData[index].prices = newFormData[index].prices.filter(
          (price) => price.app_name != appName
        );
      }
      return newFormData;
    });
  };

  // const handleUnitChange = (e) => {
  //   setUnitType(e.target.value);
  // };

  // const handledimensions = (e) => {
  //   const { name, value } = e.target;
  //   let data = { ...dimensions, [name]: value };
  //   setDimensions(data);
  // };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };

  const handleActiveDataChange = (flag) => {
    setActiveData(flag); // Update activeData with the provided flag
    let updatedData;
    if (flag === "fixedPrice") {
      updatedData = formData?.map((value) => {
        return {
          ...value,
          prices: value.prices?.map((item) => {
            if (item?.app_name === "b2b") {
              const { bundle_price, ...updatedItem } = item;
              return {
                ...updatedItem,
                price_type: "fixed",
                selling_price: "",
                margin_percentage: "",
              };
            }
            return item;
          }),
        };
      });
    }
    if (flag === "customPrice") {
      updatedData = formData?.map((value) => {
        return {
          ...value,
          prices: value.prices?.map((item) => {
            if (item?.app_name === "b2b") {
              const { margin_percentage, selling_price, ...updatedItem } = item;

              return {
                ...updatedItem,
                price_type: "quantity_base",
                bundle_price: [
                  {
                    min_qty: "",
                    max_qty: "",
                    selling_price: "",
                    margin_percentage: "",
                  },
                ],
              };
            }
            return item;
          }),
        };
      });
    }
    setFormData(updatedData);
  };

  const handleAddClick = (index) => {
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      const quantityBasePrice = newFormData[index].prices.find(
        (val) => val?.price_type === "quantity_base"
      );
      if (quantityBasePrice) {
        const newBundlePrice = {
          min_qty: "",
          max_qty: "",
          selling_price: "",
          margin_percentage: "",
        };
        quantityBasePrice.bundle_price = [
          ...quantityBasePrice.bundle_price,
          newBundlePrice,
        ];
      }
      return newFormData;
    });
  };

  const handleRemoveClick = (indexToRemove) => {
    setFormData((prevFormData) => {
      const newFormData = prevFormData.map((data) => ({
        ...data,
        prices: data.prices.map((price) => ({
          ...price,
          bundle_price:
            price.price_type === "quantity_base"
              ? price.bundle_price.filter(
                  (item, index) => index !== indexToRemove
                )
              : price.bundle_price,
        })),
      }));
      return newFormData;
    });
  };

  useEffect(() => {
    const paramsData = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(paramsData))
      .then(unwrapResult)
      .then((obj1) => {
        setLoading(false);
        setAddress(obj1?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedServiceDetails) {
      let bundlePrice = [];
      let quantityBaseData = []; // Separate state to store bundle_price data
      let newResult = selectedServiceDetails?.supplies?.map((item, index) => {
        const deliveryOptions = item?.supply_variants[0].delivery_options
          .split(",")
          .map((option) => {
            switch (option.trim()) {
              case "1":
                return "pickup";
              case "2":
                return "delivery";
              case "3":
                return "shipping";
              default:
                return ""; // Handle invalid options
            }
          });

        setDeliveryOptions(deliveryOptions);
        return {
          seller_address_id: item?.seller_address_id,
          // quantity_reorder_point: "",
          rest_quantity: item?.supply_variants[0]?.rest_quantity,
          sold_quantity: item?.supply_variants[0]?.sold_quantity,
          // delivery_options: deliveryOptions,
          activePos: item?.supply_variants[0]?.supply_variant_prices?.find(
            (val) => val?.app_name == "pos"
          )
            ? true
            : false,
          activeB2c: item?.supply_variants[0]?.supply_variant_prices?.find(
            (val) => val?.app_name == "b2c"
          )
            ? true
            : false,
          activeB2b: item?.supply_variants[0]?.supply_variant_prices?.find(
            (val) => val?.app_name == "b2b"
          )
            ? true
            : false,
          prices: item?.supply_variants[0]?.supply_variant_prices
            ?.map((item1, index) => {
              if (item1?.price_type === "fixed") {
                setActiveData("fixedPrice");
                return {
                  app_name: item1?.app_name,
                  price_type: "fixed",
                  selling_price: item1?.selling_price,
                  margin_percentage: item1?.margin_percentage,
                };
              }
              if (item1.price_type === "quantity_base") {
                setActiveData("customPrice");
                bundlePrice.push({
                  app_name: item1?.app_name,
                  price_type: "quantity_base",
                  bundle_price: [
                    {
                      min_qty: item1?.min_qty,
                      max_qty: item1?.max_qty,
                      selling_price: item1?.selling_price,
                      margin_percentage: item1?.margin_percentage,
                    },
                  ],
                });
                // Add bundle_price data to separate state
                quantityBaseData.push({
                  min_qty: item1?.min_qty,
                  max_qty: item1?.max_qty,
                  selling_price: item1?.selling_price,
                  margin_percentage: item1?.margin_percentage,
                });
                return null; // Returning null for quantity_base prices
              }

              return null; // Returning null for prices that don't match fixed or quantity_base
            })
            .filter((price) => price !== null), // Filter out null values
        };
      });

      newResult?.forEach((result) => {
        if (result.prices) {
          result.prices = result.prices.concat(bundlePrice);
        } else {
          result.prices = bundlePrice;
        }
      });
      setPrices(newResult);
      setFormData(newResult);
    }
  }, [selectedServiceDetails]);

  // Effect to recalculate margins when cost price changes
    useEffect(() => {
      if (props?.costPrice !== undefined && props?.costPrice !== null && props?.costPrice !== '') {
          setFormData(prevFormData => {
              const updatedFormData = prevFormData.map(item => {
                  item.prices.forEach(price => {
                      if (price.selling_price !== "") {
                          price.margin_percentage = (
                              ((Number(price.selling_price) - Number(props?.costPrice)) / Number(props?.costPrice)) *
                              100
                          ).toFixed(2);
                      }
                  });
                  return item;
              });
              return updatedFormData;
          });
      } else {
          // If costPrice is empty or undefined, remove margin_percentage
          setFormData(prevFormData => {
              const updatedFormData = prevFormData.map(item => {
                  item.prices.forEach(price => {
                      price.margin_percentage = "";
                  });
                  return item;
              });
              return updatedFormData;
          });
      }
  }, [props?.costPrice]);

  useEffect(() => {
    setFormData(prevFormData => {
      if (props?.costPrice !== undefined && props?.costPrice !== null && props?.costPrice !== '') {
          return prevFormData.map((value) => {
              return {
                  ...value,
                  prices: value.prices?.map((item) => {
                      const { margin_percentage, ...updatedArray } = item;
                      if (updatedArray?.app_name === "b2b" && updatedArray?.price_type === "quantity_base") {
                          const updatedBundlePrice = updatedArray.bundle_price.map((bundle) => {
                              const sellingPrice = parseFloat(bundle.selling_price);
                              if (sellingPrice !== 0 && !isNaN(sellingPrice)) {
                                  const margin = ((sellingPrice - props.costPrice) / sellingPrice) * 100;
                                  return {
                                      ...bundle,
                                      margin_percentage: margin.toFixed(2),
                                  };
                              } else {
                                  // Handle case where selling price is 0 or NaN
                                  return {
                                      ...bundle,
                                      margin_percentage: "",
                                  };
                              }
                          });
                          return {
                              ...updatedArray,
                              bundle_price: updatedBundlePrice,
                          };
                      }
                      return item;
                  }),
              };
          });
      } else {
          return prevFormData?.map((value) => {
              return {
                  ...value,
                  prices: value.prices?.map((item) => {
                      const { margin_percentage, ...updatedArray } = item;
                      console.log(item, "itemitemitem");
                      if (
                          updatedArray?.app_name === "b2b" &&
                          updatedArray?.price_type === "quantity_base"
                      ) {
                          const updatedBundlePrice = updatedArray.bundle_price.map(
                              (bundle) => {
                                  // Reset margin percentage to empty string
                                  return {
                                      ...bundle,
                                      margin_percentage: "",
                                  };
                              }
                          );
                          return {
                              ...updatedArray,
                              bundle_price: updatedBundlePrice,
                          };
                      }
                      return item;
                  }),
              };
          });
      }
  });
  }, [props?.costPrice, activeData]);


  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    props.supplyDetails(formData);
  }, [formData]);

  return (
    <>
      <div className="addProductManually mt-5">
        <div className="productRightWrapper">
          <div className="pt-3 pb-3 outerArea ">
            {/* <div className="col-md-5"> */}
            <label className="labelTxt mb-3" htmlFor="amount">
              <b>Supply</b>
            </label>
            {prices?.length > 0 ? (
              <>
                {formData?.map((supply, index) => (
                  <>
                    <div key={index} className="row">
                      <h3>
                        Address:{" "}
                        {
                          addressList?.payload?.find(
                            (data) => data?.id == supply.seller_address_id
                          )?.format_address
                        }
                      </h3>
                      <div className="col-md-6">
                        <div className="outerArea mb-3">
                          <h3 className="subheaderHeading mb-4">
                            Service availability
                          </h3>
                          <ul className="productToggleMenus">
                            <li className="productToggleItems">
                              <div className="form-check form-switch me-2 mb-0">
                                <input
                                  type="checkbox"
                                  checked={supply?.activePos}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      !supply?.activePos,
                                      index,
                                      "activePos",
                                      "pos"
                                    )
                                  }
                                />
                              </div>
                              <h3 className="labelTxt">
                                <b>JOBR POS</b> <i>(Physical store)</i>
                              </h3>
                            </li>

                            <li className="productToggleItems">
                              <div className="form-check form-switch me-2 mb-0">
                                <input
                                  type="checkbox"
                                  checked={supply?.activeB2c}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      !supply?.activeB2c,
                                      index,
                                      "activeB2c",
                                      "b2c"
                                    )
                                  }
                                />
                              </div>
                              <h3 className="labelTxt">
                                <b>JOBR B2C</b>
                              </h3>
                            </li>
                            <li className="productToggleItems">
                              <div className="form-check form-switch me-2 mb-0">
                                <input
                                  type="checkbox"
                                  checked={supply?.activeB2b}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      !supply?.activeB2b,
                                      index,
                                      "activeB2b",
                                      "b2b"
                                    )
                                  }
                                />
                              </div>
                              <h3 className="labelTxt">
                                <b>JOBR B2B</b>
                              </h3>
                            </li>
                          </ul>
                        </div>

                        {supply?.activePos && (
                          <div className="outerArea mt-3">
                            <h2 className="headingBlue mb-4">
                              JOBR POS{" "}
                              <span className="linkTxt">
                                <i>(Physical store)</i>
                              </span>
                              <img
                                src={infoImg}
                                alt="InfoImg"
                                className="infoIcon ms-2"
                              />
                            </h2>
                            <form className="inventory row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Selling Price for Customer
                                </label>
                                <div className="inputGroup">
                                  <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="125"
                                    name="selling_price"
                                    type="number"
                                    value={
                                      supply?.prices?.find(
                                        (val) => val?.app_name == "pos"
                                      )?.selling_price
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        index,
                                        "pos",
                                        "selling_price"
                                      );
                                      setSellingChange(Math.random());
                                      setIndexChange(index);
                                      setPosChange(Math.random());
                                    }}
                                  />
                                  <span className="dollrsign_">$</span>
                                </div>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Profit Margin
                                </label>
                                <div className="inputGroup">
                                  <input
                                    // disabled
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="10"
                                    name="margin_percentage"
                                    type="number"
                                    value={
                                      supply?.prices?.find(
                                        (val) => val?.app_name == "pos"
                                      )?.margin_percentage
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        index,
                                        "pos",
                                        "margin_percentage"
                                      );
                                      setMarginChange(Math.random());
                                      setIndexChange(index);
                                      setPosChange(Math.random());
                                    }}
                                  />
                                  <span className="percentagesign_">%</span>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}

                        {supply?.activeB2c && (
                          <div className="outerArea mt-3">
                            <h2 className="headingBlue mb-4">
                              JOBR B2C{" "}
                              <span className="linkTxt">
                                {/* <i>("")</i> */}
                              </span>
                              <img
                                src={infoImg}
                                alt="InfoImg"
                                className="infoIcon ms-2"
                              />
                            </h2>
                            <form className="inventory row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Selling Price for Customer
                                </label>
                                <div className="inputGroup">
                                  <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="125"
                                    name="selling_price"
                                    type="number"
                                    value={
                                      supply?.prices?.find(
                                        (val) => val?.app_name == "b2c"
                                      )?.selling_price
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        index,
                                        "b2c",
                                        "selling_price"
                                      );
                                      setSellingChange(Math.random());
                                      setIndexChange(index);
                                      setB2cChange(Math.random());
                                    }}
                                  />
                                  <span className="dollrsign_">$</span>
                                </div>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Profit Margin
                                </label>
                                <div className="inputGroup">
                                  <input
                                    // disabled
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="10"
                                    name="margin_percentage"
                                    type="number"
                                    value={
                                      supply?.prices?.find(
                                        (val) => val?.app_name == "b2c"
                                      )?.margin_percentage
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        index,
                                        "b2c",
                                        "margin_percentage"
                                      );
                                      setMarginChange(Math.random());
                                      setIndexChange(index);
                                      setB2cChange(Math.random());
                                    }}
                                  />
                                  <span className="percentagesign_">%</span>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}

                        {supply?.activeB2b ? (
                          <div className="outerArea mt-3">
                            <h2 className="headingBlue mb-4">
                              JOBR B2B{" "}
                              <span className="linkTxt">
                                <i>(Online Store)</i>
                              </span>
                              <img
                                src={infoImg}
                                alt="InfoImg"
                                className="infoIcon ms-2"
                              />
                            </h2>
                            <div className="priceContainer">
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <div
                                    className={`priceContainerArea ${
                                      activeData === "fixedPrice"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      handleActiveDataChange(
                                        "fixedPrice",
                                        index,
                                      )
                                    }
                                  >
                                    <h4 className="subheadText fw-bold mb-1">
                                      Fixed Price
                                    </h4>
                                    <p className="subtext_">
                                      Choose specific areas that you deliver to.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div
                                    className={`priceContainerArea ${
                                      activeData === "customPrice"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      handleActiveDataChange(
                                        "customPrice",
                                        index,
                                      )
                                    }
                                  >
                                    <h4 className="subheadText fw-bold mb-1">
                                      Price based on Quantity
                                    </h4>
                                    <p className="subtext_">
                                      Set a distance around your location
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {activeData == "fixedPrice" ? (
                                <>
                                  {supply?.prices?.find(
                                    (val) => val?.price_type == "fixed"
                                  ) ? (
                                    <>
                                      <div className="outerArea mt-3">
                                        <h2 className="headingBlue mb-4">
                                          JOBR B2B{" "}
                                          <span className="linkTxt"></span>
                                          <img
                                            src={infoImg}
                                            alt="InfoImg"
                                            className="infoIcon ms-2"
                                          />
                                        </h2>
                                        <form className="inventory row">
                                          <div className="form-group mb-3 col-md-6">
                                            <label className="deatilsHead mb-2 fw-normal">
                                              Selling Price for Customer
                                            </label>
                                            <div className="inputGroup">
                                              <input
                                                min="1"
                                                onKeyDown={blockInvalidChar}
                                                className="customform-control"
                                                placeholder="125"
                                                name="selling_price"
                                                type="number"
                                                value={
                                                  supply?.prices?.find(
                                                    (val) =>
                                                      val?.app_name == "b2b"
                                                  )?.selling_price
                                                }
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "b2b",
                                                    "selling_price"
                                                  );
                                                  setSellingChange(
                                                    Math.random()
                                                  );
                                                  setIndexChange(index);
                                                  setB2cChange(Math.random());
                                                }}
                                              />
                                              <span className="dollrsign_">
                                                $
                                              </span>
                                            </div>
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label className="deatilsHead mb-2 fw-normal">
                                              Profit Margin
                                            </label>
                                            <div className="inputGroup">
                                              <input
                                                min="1"
                                                onKeyDown={blockInvalidChar}
                                                className="customform-control"
                                                placeholder="10"
                                                name="margin_percentage"
                                                type="number"
                                                value={
                                                  supply?.prices?.find(
                                                    (val) =>
                                                      val?.app_name == "b2b"
                                                  )?.margin_percentage
                                                }
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "b2b",
                                                    "margin_percentage"
                                                  );
                                                  setMarginChange(
                                                    Math.random()
                                                  );
                                                  setIndexChange(index);
                                                  setB2cChange(Math.random());
                                                }}
                                              />
                                              <span className="percentagesign_">
                                                %
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : activeData == "customPrice" ? (
                                <div className="customPrice">
                                  <form>
                                    {supply?.prices
                                      ?.find(
                                        (val) =>
                                          val?.price_type == "quantity_base"
                                      )
                                      ?.bundle_price?.map((item, i) => {
                                        return (
                                          <div
                                            className="outerArea mb-4"
                                            key={i}
                                          >
                                            {i ? (
                                              <span
                                                className="removeSelect"
                                                onClick={() =>
                                                  handleRemoveClick(i)
                                                }
                                              >
                                                <i className="fas fa-times"></i>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <div className="row mb-4">
                                              <label className="deatilsHead mb-2 fw-normal">
                                                Selling Quantity
                                              </label>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="0"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Min QTY"
                                                    name="min_qty"
                                                    type="number"
                                                    value={item?.min_qty}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "min_qty",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="0"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Max QTY"
                                                    name="max_qty"
                                                    type="number"
                                                    value={item?.max_qty}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "max_qty",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <label className="deatilsHead mb-2 fw-normal">
                                                Selling Price
                                              </label>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="1"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Selling Price"
                                                    name="selling_price"
                                                    type="number"
                                                    value={item?.selling_price}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "selling_price",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="1"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="margin_percentage"
                                                    name="margin_percentage"
                                                    type="number"
                                                    value={
                                                      item?.margin_percentage
                                                    }
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "margin_percentage",
                                                        i
                                                      )
                                                    }
                                                  />
                                                  <span className="percentagesign_">
                                                    %
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}

                                    <div className="row mt-5">
                                      <div
                                        className="col-md-12"
                                        onClick={() => handleAddClick(index)}
                                      >
                                        <img src={addIcon} className="me-2" />
                                        <span className="textinner_ fw-500">
                                          Add another
                                        </span>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {supply?.activeB2b ? (
                          <>
                            {supply?.prices?.find(
                              (val) => val?.price_type == "quantity_base"
                            ) ? (
                              <>
                                <div className="customPrice">
                                  <form>
                                    {supply?.prices
                                      ?.find(
                                        (val) =>
                                          val?.price_type == "quantity_base"
                                      )
                                      .bundle_price?.map((item, i) => {
                                        return (
                                          <div
                                            className="outerArea mb-4"
                                            key={i}
                                          >
                                            <h2 className="headingBlue mb-4">
                                              JOBR B2B{" "}
                                              <span className="linkTxt">
                                                <i>(Custom Price)</i>
                                              </span>
                                              <img
                                                src={infoImg}
                                                alt="InfoImg"
                                                className="infoIcon ms-2"
                                              />
                                            </h2>
                                            {i ? (
                                              <span
                                                className="removeSelect"
                                                onClick={() =>
                                                  handleRemoveClick(i)
                                                }
                                              >
                                                <i className="fas fa-times"></i>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <div className="row mb-4">
                                              <label className="deatilsHead mb-2 fw-normal">
                                                Selling Quantity
                                              </label>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="0"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Min QTY"
                                                    name="min_qty"
                                                    type="number"
                                                    value={item?.min_qty}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "min_qty",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="0"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Max QTY"
                                                    name="max_qty"
                                                    type="number"
                                                    value={item?.max_qty}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "max_qty",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <label className="deatilsHead mb-2 fw-normal">
                                                Selling Price
                                              </label>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                    min="1"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="Selling Price"
                                                    name="selling_price"
                                                    type="number"
                                                    value={item?.selling_price}
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "selling_price",
                                                        i
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group col-md-6">
                                                <div className="inputGroup">
                                                  <input
                                                
                                                    min="1"
                                                    onKeyDown={blockInvalidChar}
                                                    className="customform-control"
                                                    placeholder="margin_percentage"
                                                    name="margin_percentage"
                                                    type="number"
                                                    value={
                                                      item?.margin_percentage
                                                    }
                                                    onChange={(e) =>
                                                      handleCustomPriceChange(
                                                        e,
                                                        index,
                                                        "b2b",
                                                        "margin_percentage",
                                                        i
                                                      )
                                                    }
                                                  />
                                                  <span className="percentagesign_">
                                                    %
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}

                                    <div className="row mt-5">
                                      <div
                                        className="col-md-12"
                                        onClick={() => handleAddClick(index)}
                                      >
                                     
                                        <img src={addIcon} className="me-2" />
                                        <span className="textinner_ fw-500">
                                          Add another
                                        </span>
                                     
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="outerArea mt-3">
                                  <h2 className="headingBlue mb-4">
                                    JOBR B2B{" "}
                                    <span className="linkTxt">
                                   
                                    </span>
                                    <img
                                      src={infoImg}
                                      alt="InfoImg"
                                      className="infoIcon ms-2"
                                    />
                                  </h2>
                                  <form className="inventory row">
                                    <div className="form-group mb-3 col-md-6">
                                      <label className="deatilsHead mb-2 fw-normal">
                                        Selling Price for Customer
                                      </label>
                                      <div className="inputGroup">
                                        <input
                                          min="1"
                                          onKeyDown={blockInvalidChar}
                                          className="customform-control"
                                          placeholder="125"
                                          name="selling_price"
                                          type="number"
                                          value={
                                            supply?.prices?.find(
                                              (val) => val?.app_name == "b2b"
                                            )?.selling_price
                                          }
                                          onChange={(e) => {
                                            handleChange(
                                              e,
                                              index,
                                              "b2b",
                                              "selling_price"
                                            );
                                            setSellingChange(Math.random());
                                            setIndexChange(index);
                                            setB2cChange(Math.random());
                                          }}
                                        />
                                        <span className="dollrsign_">$</span>
                                      </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label className="deatilsHead mb-2 fw-normal">
                                        Profit Margin
                                      </label>
                                      <div className="inputGroup">
                                        <input
                                     
                                          min="1"
                                          onKeyDown={blockInvalidChar}
                                          className="customform-control"
                                          placeholder="10"
                                          name="margin_percentage"
                                          type="number"
                                          value={
                                            supply?.prices?.find(
                                              (val) => val?.app_name == "b2b"
                                            )?.margin_percentage
                                          }
                                          onChange={(e) => {
                                            handleChange(
                                              e,
                                              index,
                                              "b2b",
                                              "margin_percentage"
                                            );
                                            setMarginChange(Math.random());
                                            setIndexChange(index);
                                            setB2cChange(Math.random());
                                          }}
                                        />
                                        <span className="percentagesign_">
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div className="col-md-6">
                        <div className="col-12                                                                                                                                                                                                            ">
                          {/* End ProductAvailability Area */}
                          {/* Start Inventory Area */}
                          <div className="outerArea mb-3 h-100">
                            <h3 className="subheaderHeading mb-4">Inventory</h3>
                            <form className="inventory">
                              <div className="form-group mb-3">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Inventory-Opening
                                  <img
                                    src={infoImg}
                                    alt="InfoImg"
                                    className="infoIcon ms-3"
                                  />
                                </label>
                                <div className="inputGroup">
                                  <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control border"
                                    placeholder=" Quantity "
                                    type="number"
                                    value={supply?.rest_quantity}
                                    onChange={(e) => {
                                      handleChanged(
                                        e.target.value,
                                        index,
                                        "Opening"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="deatilsHead mb-2 fw-normal">
                                  Inventory-Reorder Point
                                  <img
                                    src={infoImg}
                                    alt="InfoImg"
                                    className="infoIcon ms-3"
                                  />
                                </label>
                                <div className="inputGroup">
                                  <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control border"
                                    placeholder=" Quantity reorder point"
                                    type="number"
                                    value={supply?.sold_quantity}
                                    onChange={(e) =>
                                      handleChanged(
                                        e.target.value,
                                        index,
                                        "Reorder"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </form>
                          </div>

                          {/* End Inventory Area */}
                          {/* Start Attributes Area */}
                        </div>

                        {/* <div className="col-12                                                                                                                                                                                                            ">
                          <div className="outerArea h-100">
                            <h3 className="subheaderHeading mb-4">
                              Shipping or Pickup
                            </h3>
                            <ul className="productToggleMenus">
                              <li className="productToggleItems">
                                <div className="form-check form-switch me-2 mb-0">
                                  <input
                                    type="checkbox"
                                    id="pickup"
                                    value="pickup"
                                    onChange={(e) => {
                                      handlechangeShippingOrPickup(
                                        "pickup",
                                        index
                                      );
                                    }}
                                    checked={deliveryOptions?.includes(
                                      "pickup"
                                    )}
                                  />
                                </div>
                                <h3 className="labelTxt">
                                  <b>Pickup</b>
                                </h3>
                              </li>
                              <li className="productToggleItems">
                                <div className="form-check form-switch me-2 mb-0">
                                  <input
                                    type="checkbox"
                                    id="delivery"
                                    value="delivery"
                                    onChange={(e) => {
                                      handlechangeShippingOrPickup(
                                        "delivery",
                                        index
                                      );
                                    }}
                                    checked={deliveryOptions?.includes(
                                      "delivery"
                                    )}
                                  />
                                </div>
                                <h3 className="labelTxt">
                                  <b>Delivery</b>
                                </h3>
                              </li>
                              <li className="productToggleItems">
                                <div className="form-check form-switch me-2 mb-0">
                                  <input
                                    type="checkbox"
                                    id="shipping"
                                    value="shipping"
                                    onChange={() => {
                                      handlechangeShippingOrPickup(
                                        "shipping",
                                        index
                                      );
                                    }}
                                    checked={deliveryOptions?.includes(
                                      "shipping"
                                    )}
                                  />
                                </div>
                                <h3 className="labelTxt">
                                  <b>Shipping</b>
                                </h3>
                              </li>
                            </ul>
                            <p className="descriptionPara_ mt-4">
                              Enable this option if the product needs to be
                              physically delivered to customers either via
                              shipping or by self-pickup. If this product is a
                              service or a downloadable item that doesn’t
                              require delivery, keep this option disabled.
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="col-12">
                          {deliveryOptions?.length > 0 && (
                            <div className="outerArea">
                              <form className="editFormControl">
                                <div className="form-group mb-3 ">
                                  <label className="deatilsHead mb-2 fw-normal">
                                    Product weight
                                    <img
                                      src={infoImg}
                                      alt="InfoImg"
                                      className="infoIcon ms-3"
                                    />
                                  </label>

                                  <div className="productWeight_">
                                    <div className="col-lg-12">
                                      <div className="row align-items-center ">
                                        <div className="col-xl-12 col-lg-12 col-md-12 pe-0">
                                          <div className="inputGroup">
                                            <input
                                              className="customform-control weightPlace"
                                              placeholder="lb"
                                              name="weight"
                                              type="number"
                                              value={dimensions?.weight}
                                              onChange={(e) => {
                                                handledimensions(e);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <label className="deatilsHead mb-2 fw-normal">
                                    Product Dimensions
                                    <img
                                      src={infoImg}
                                      alt="InfoImg"
                                      className="infoIcon ms-3"
                                    />
                                  </label>
                                  <ul className="dimensionsGroup mb-3">
                                    <li className="dimensionsGroupItems">
                                      <label className="textinner_ mb-1">
                                        <i>Length</i>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="length"
                                        name="length"
                                        aria-describedby="button-addon1"
                                        value={dimensions?.length}
                                        onChange={(e) => {
                                          handledimensions(e);
                                        }}
                                      />
                                    </li>
                                    <li className="dimensionsGroupItems">
                                      <label className="textinner_ mb-1">
                                        <i>Breadth</i>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="breadth"
                                        name="breadth"
                                        aria-describedby="button-addon1"
                                        value={dimensions?.breadth}
                                        onChange={(e) => {
                                          handledimensions(e);
                                        }}
                                      />
                                    </li>
                                    <li className="dimensionsGroupItems">
                                      <label className="textinner_ mb-1">
                                        <i>Height</i>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="height"
                                        name="height"
                                        aria-describedby="button-addon1"
                                        value={dimensions?.height}
                                        onChange={(e) => {
                                          handledimensions(e);
                                        }}
                                      />
                                    </li>
                                    <li className="dimensionsGroupItems">
                                      <span className="linkText">in</span>
                                    </li>
                                  </ul>
                                </div>
                              </form>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditVarientsModal;
