import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  backArrow,
  brandLogo,
  check,
  shops,
  stepHalf,
} from "../../utilities/images";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import OtpTimer from "otp-timer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPosUserAsync,
  selectLoginAuth,
  selectSignUpAuth,
} from "../containers/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import PhoneInput from "react-phone-input-2";
import {
  updateUserEmailApi,
  updateUserPhoneApi,
  verifyUpdateUserEmailApi,
  verifyUpdateUserPhonelApi,
} from "../containers/auth/authApi";
const EditEmailPhoneModal = (props) => {
  const modalDetail = props.modalDetail;
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const signupAuth = useSelector(selectSignUpAuth);
  const auth = useSelector(selectLoginAuth);
  const tokenData = auth?.payload?.token
    ? auth?.payload?.token
    : signupAuth?.payload?.token;

  const history = useHistory();
  const pathName = window.location.pathname;
  const [key] = useState(Math.random());
  const [otp, setOtp] = useState("");
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [otpLoading, setOtpLoading] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [email, setEmail] = useState(modalDetail?.value ?? "");
  const [phoneNo, setPhoneNo] = useState(modalDetail?.value ?? "");
  const [phoneCode, SetPhoneCode] = useState(modalDetail?.phone_code ?? "");
  const [loading, setLoading] = useState(false);
  const [sendOtploading, setSendOtpLoading] = useState(false);
  const [isShowPin, setShowPin] = useState(false);

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };
  const onComplete = (code) => {
    setOtp(code);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const generateOtpForEmail = async () => {
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter email");
      }
      return false;
    } else {
      setSendOtpLoading(true);
      const data = {
        email: email,
      };
      const res = await dispatch(updateUserEmailApi(data, tokenData));
      if (res) {
        setSendOtpLoading(false);
        if (res?.data?.status_code == 200) {
          setShowPin(true);
        }
      }
    }
  };

  const verifyOtpForEmail = async () => {
    if (!otp) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter otp");
      }
      return false;
    } else if (otp < 4) {
      toastId.current = toast.error("Please enter all field");
    } else {
      setLoading(true);
      const data = {
        email: email,
        otp: otp,
      };
      const res = await dispatch(verifyUpdateUserEmailApi(data, tokenData));
      if (res) {
        setLoading(false);
        if (res?.data?.status_code == 200) {
          props?.getUserData();
          props?.close();
        }
      }
    }
  };

  const generateOtpForPhone = async () => {
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter email");
      }
      return false;
    } else {
      setSendOtpLoading(true);
      const data = {
        phone_code: phoneCode.startsWith("+") ? phoneCode : "+" + phoneCode,
        phone_no: phoneNo,
      };
      console.log("Sdasd", data);
      const res = await dispatch(updateUserPhoneApi(data, tokenData));
      if (res) {
        setSendOtpLoading(false);
        if (res?.data?.status_code == 200) {
          setShowPin(true);
        }
      }
    }
  };

  const verifyOtpForPhone = async () => {
    if (!otp) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter otp");
      }
      return false;
    } else if (otp < 4) {
      toastId.current = toast.error("Please enter all field");
    } else {
      setLoading(true);
      const data = {
        phone_code: phoneCode.startsWith("+") ? phoneCode : "+" + phoneCode,
        phone_no: phoneNo,
        otp: otp,
      };
      const res = await dispatch(verifyUpdateUserPhonelApi(data, tokenData));
      if (res) {
        setLoading(false);
        if (res?.data?.status_code == 200) {
          props?.getUserData();
          props?.close();
        }
      }
    }
  };
  const onCloseModal = () => {
    setOtp("");
    props?.close();
  };
  return (
    <>
      <div className="editEmailrVerify_">
        {isShowPin && (
          <div>
            <h4 className="modalHeading_New">
              <span
                onClick={() => {
                  setOtp("");
                  setShowPin(false);
                }}
              >
                <i className="las la-angle-left me-3 backArrowImg_"></i>{" "}
              </span>
              <label className="form-label">
                {modalDetail?.type == "Email"
                  ? email
                  : "+" + phoneCode + phoneNo}
              </label>
            </h4>
          </div>
        )}
        <span className="crossIconNew" onClick={() => onCloseModal()}>
          <i className="las la-times" aria-hidden="true"></i>
        </span>

        {!isShowPin ? (
          <form
            autoComplete="nope"
            className="form_horizontal"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="mb-4 form-group">
              {modalDetail?.type == "Phone" ? (
                <div className="mb-4 otp__ ">
                  <div className="phone-numbpart">
                    <div className="country-plugin">
                      <label className="form-label">Phone Number</label>
                      <div id="result">
                        <PhoneInput
                          country="us"
                          value={modalDetail?.phone_code + modalDetail?.value}
                          enableSearch={true}
                          name={generateRandomName()}
                          placeholder="Phone no."
                          onChange={(value, data, event, formattedValue) =>
                            onChangePhoneNumber(
                              value,
                              data,
                              event,
                              formattedValue
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mb-4 form-group col-md-12">
                  <label className="deatilsHead mb-3">Email Address</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="email"
                      className="customform-control"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12 mt-5">
              <button
                className={"loginBtnSign w-100 buttonActive"}
                type="submit"
                onClick={() => {
                  modalDetail?.type == "Email"
                    ? generateOtpForEmail()
                    : generateOtpForPhone();
                }}
              >
                {sendOtploading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Send OTP</span>
              </button>
            </div>
          </form>
        ) : (
          <div className="verify-part">
            <div className="verify-box text-center">
              {/* <label className="form-label">Enter OTP</label> */}
              <div className="pin-box d-flex justify-content-center">
                <OTPInput
                  numInputs={5}
                  className="input_digits_"
                  value={otp}
                  data-cy="pin-field"
                  name={generateRandomName()}
                  autoComplete="new-password"
                  isInputNum={true}
                  isInputSecure={true}
                  onChange={onComplete}
                />
              </div>
              <div className="col-md-12 mt-5">
                <button
                  className={"loginBtnSign w-100 buttonActive"}
                  type="submit"
                  onClick={() =>
                    modalDetail?.type == "Email"
                      ? verifyOtpForEmail()
                      : verifyOtpForPhone()
                  }
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditEmailPhoneModal;
