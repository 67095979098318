import React, { useState,useEffect,useRef  } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const CropImageModal = (props) => {
  const containerRef = useRef(null)
  const [displayWidth, setDisplayWidth] = useState(0);
  const [displayHeight, setDisplayHeight] = useState(0);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  });
  // const [zoom, setZoom] = useState(1); // Initial zoom level

  const reactCropRef = useRef(null);

  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  // const [croppedImageDataUrl, setCroppedImageDataURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showsaveButton, setShowSaveButton] = useState(false);

  const onCropComplete = (crop) => {

    console.log(crop, "crop dimesnsionsssssssssss")
    setCrop(crop);
    makeClientCrop(crop);
  };
  const makeClientCrop = async (crop) => {
    if (crop && crop.width && crop.height) {
      try {
        const fileBlobArray = props?.fileBlob;
        if (Array.isArray(fileBlobArray) && fileBlobArray.length > 0) {
          const fileBlob = fileBlobArray[props?.indexId];

          console.log(fileBlob, fileBlobArray, props?.indexId,"fileblobbbbbbbbbbbbbbbb");
          const croppedImage = await getCroppedImg(
            fileBlob,
            crop,
            "cropped-image.jpg",
            displayWidth,
            displayHeight
          );
          setCroppedImageBlob(croppedImage);
        } else {
          throw new Error("File blob array is empty or invalid");
        }
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    } else {
      console.warn("Invalid crop object:", crop);
      setCroppedImageBlob(null);
    }
  };
 
  const getCroppedImg = (fileBlob, crop, fileName) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");

        // Use the same width and height for canvas to maintain the aspect ratio
        canvas.width = Math.round(512);
        canvas.height = Math.round(512);
  
        const ctx = canvas.getContext("2d");
        const scaleX = image.naturalWidth / displayWidth;
        const scaleY = image.naturalHeight / displayHeight;
  
        // Calculate the scaled crop coordinates and dimensions
        const scaledCropX = Math.floor((crop.x ) * scaleX);
        const scaledCropY = Math.floor(crop.y * scaleY);
        const scaledCropWidth = crop.width * scaleX;
        const scaledCropHeight = crop.height * scaleY;
  
        // Draw the cropped area onto the canvas
        ctx.drawImage(
          image,
          scaledCropX,
          scaledCropY,
          scaledCropWidth,
          scaledCropHeight,
          0,
          0,
          512,
          512
        );

        // Convert canvas to blob

        canvas.toBlob(
          (blob) => {
            blob.name = fileName;
            resolve(blob);
          },
          "image/jpeg",
          0.9
        );
      };

      if (fileBlob instanceof Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        reader.onload = (event) => {
          image.src = event.target.result;
        };
        setShowSaveButton(true);
      } else {
        reject(new Error("Invalid file blob"));
      }
    });
  };


  // const handleCropImage = () => {
  //   if (croppedImageBlob) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(croppedImageBlob);
  //     reader.onload = () => {
  //       const imageData = reader.result;
  //       setCroppedImageDataURL(imageData);
  //     };
  //   } else {
  //     console.error("No cropped image available.");
  //   }
  // };

  const uploadImage = () => {
    let formData = new FormData();
    formData.append(`file`, croppedImageBlob);

    let params = {
      file: formData,
      token: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        props?.setCroppedImage(obj?.payload[0]?.filePath, croppedImageBlob);
        setLoading(false);
        props.close();
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  // const handleZoomIn = () => {
  //   setZoom((prevZoom) => prevZoom + 0.1);
  // };

  // const handleZoomOut = () => {
  //   setZoom((prevZoom) => prevZoom - 0.1);
  // };

  const handleImageLoaded = (image) => {
    if (containerRef.current) {
     setDisplayWidth(containerRef.current.clientWidth);
    setDisplayHeight(containerRef.current.clientHeight);
    }
  };

  return (
    <>

      {/* Add your zoom in and zoom out buttons */}
      {/* <button onClick={handleZoomIn} type="button">Zoom In</button>
      <button onClick={handleZoomOut} type="button">Zoom Out</button> */}
      <ReactCrop
        src={props?.selectedImg?.filePath}
        crop={crop}
        onComplete={(crop) => onCropComplete(crop)}
        onChange={(newCrop) => setCrop(newCrop)}
        // ruleOfThirds
        aspect={1}
      >
        <div className="d-flex justify-content-center align-items-center">
          {/* <img src={props?.selectedImg?.filePath} alt="" /> */}
          <img src={props?.selectedImg?.filePath} onLoad={handleImageLoaded} ref={containerRef} alt="image" className='corpImage_'  />
          {/* <img src={croppedImageDataUrl} alt="" /> */}
        </div>
      </ReactCrop>

      <div className="saveCropImgBtn">
        {showsaveButton && (
          <button className="submitBtn" type="button" onClick={uploadImage}>
            Save
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}{" "}
          </button>
        )}
      </div>

    </>
  );
};

export default CropImageModal;
