import React, { useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserSettingAsync } from '../systemConfigSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

const PoliciesModal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth);
    const [editorContent, setEditorContent] = useState(props?.selectedPolicies?.content ? props?.selectedPolicies?.content : '');
    const date = moment(new Date()).format('MM-DD-YYYY');


    // const handleSubmit = () => {
    //     props.policiesData[props.indexId]["intro"] = editorContent;
    //     let params = {
    //         tokenData: auth?.payload?.token,
    //         postData: {
    //             "app_name": "pos",
    //             "policies": props.policiesData,
    //         },

    //     };
    //     setLoading(true)
    //     dispatch(updateUserSettingAsync(params))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setLoading(false)
    //             toast.success(obj?.msg);
    //             props.getpoliciesData();
    //             props.close();
    //         }
    //         )
    //         .catch((obj) => {
    //             setLoading(false)
    //         })
    // }

    return (
        <div className='commonPadding_'>
            {/* <b>{props?.selectedPolicies?.title}</b> */}
            <CKEditor
                initData={editorContent}
                config={{
                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', "image"],
                }}
                name='editor'
                readOnly={false}
                onChange={(event, editor) => {
                    const data = event.editor.getData()
                    setEditorContent(data);
                }}
                type="classic"
            />
            {/* <button className='btn btn-secondary ' onClick={() => { props.close() }}>cancel</button>
            <button className='btn btn-primary ms-2' type="submit" onClick={() => handleSubmit()}>
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>Save</span>
            </button> */}

        </div>
    )
}

export default PoliciesModal