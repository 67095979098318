import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { manage, clickImg, visa } from '../../../../utilities/images'
import PlansModal from '../../scalePlan/plansModal';
import CustomModal from '../../../components/shared/CustomModal';
import { getMerchantActiveSubscriptionAsync, getMerchantStepStatusAsync, selectMerchantStatus } from '../../dashboard/dashboardSlice';
import { useEffect } from 'react';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const ViewPlanModal = (props) => {
    const [showBanner, setShowBanner] = useState(true)
    const dispatch = useDispatch()
    const [key, setKey] = useState(Math.random());
    const history = useHistory('')
    const [loading, setLoading] = useState(props?.loading)
    const [selectedPlan, setSelectedPlan] = useState(props?.selectedPlan)
    const auth = useSelector(selectLoginAuth)
    const merchantStatus = useSelector(selectMerchantStatus)
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const details = (data) => {
        setSelectedPlan(data)
    }

    // const getMerchantActiveSubscription = () => {
    //     setLoading(true)
    //     dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setSelectedPlan(obj?.payload)
    //             setLoading(false)
    //         })
    //         .catch((obj) => {
    //             setLoading(false)
    //         })
    // }


    const getMerchantStepStatus = () => {
        setLoading(true)
        dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }


    useEffect(() => {
        // getMerchantActiveSubscription()
        getMerchantStepStatus()
    }, [])
    return (
        <>
            <div className='viewPlanSub'>
                {
                    loading === true ?
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                        :
                        <>
                            {
                                selectedPlan?.length > 0 ?
                                    <div className='configPlanCard'>
                                        <h1 className='validText'>Your Plan</h1>
                                        <div className='dFlex'>
                                            <p className='innerHeadingSky mb-2'>{selectedPlan[0]?.plan_id?.name}</p>
                                            <button className='saveBtn' onClick={() => {
                                                setModalDetail({ show: true, flag: "PickPlan" });
                                                setKey(Math.random());
                                            }}>Change Plan</button>
                                        </div>
                                        <p className='descriptionPara_ fw-normal'>Everything you need to create a store, ship products, and process payments</p>
                                        <div className='yourPlan'>
                                            <h1 className='subtextDark_ fw-bold mb-2'>Your Plan includes app</h1>
                                            <form action="#">
                                                {
                                                    selectedPlan[0]?.plan_id?.included_apps?.length > 0 ?
                                                        selectedPlan[0]?.plan_id?.included_apps?.map((val, ind) => {
                                                            return (
                                                                <div key={ind} className="customRadio_">
                                                                    <input type="radio" id="test1" checked />
                                                                    <label for="test1">{val}</label>
                                                                </div>
                                                            )
                                                        }) : <></>
                                                }
                                            </form>
                                        </div>
                                        <div className='yourPlan'>
                                            <h1 className='subtextDark_ fw-bold mb-2'>Plan Features</h1>
                                            <ul className='otherMainus'>
                                                {
                                                    selectedPlan[0]?.plan_id?.tags?.length > 0 ?
                                                        selectedPlan[0]?.plan_id?.tags?.map((val, ind) => {
                                                            return (
                                                                <li key={ind} className='otherItems'>
                                                                    <img src={clickImg} className='' alt='' />
                                                                    <span className=''>{val}</span>
                                                                </li>
                                                            )
                                                        }) : <div>No Plan Feature Found</div>
                                                }
                                            </ul>
                                        </div>
                                        <div className='nextBilling'>
                                            <h1 className='subtextDark_ fw-bold mb-2'>Next billing date</h1>
                                            {/* <p className='subtext_ fw-normal'> for$USD</p> */}
                                            <p className='subtext_ fw-normal'>{moment(selectedPlan[0]?.expiry_date).format("MMM DD, YYYY")} for ${selectedPlan[0]?.amount}USD</p>
                                        </div>
                                        <div className='nextBilling'>
                                            <h1 className='subtextDark_ fw-bold mb-2'>Payment method</h1>
                                            <p className='subtext_ fw-normal'>
                                                {/* <img src={visa} className="visaImg me-2" /> */}
                                                JBR Coin</p>
                                        </div>
                                    </div>
                                    : <div>No Active Plan Found</div>
                            }
                        </>
                }
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "PickPlan" ? "pickPlan" :
                        modalDetail.flag === "FindProduct" ? "findProduct" : ""
                }
                size={
                    modalDetail.flag === "PickPlan" ? "xl" :
                        modalDetail.flag === "FindProduct" ? "lg" : "md"
                }
                child={
                    modalDetail.flag === 'PickPlan' ? <PlansModal handleSelectedPlan={(data) => details(data)} close={() => handleOnCloseModal()} /> :
                        <></>
                }
                header={
                    <>
                        {modalDetail.flag === 'PickPlan'
                            ?
                            <>
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                        <h3 className='heading_ mb-2'>Plan that fits your scale</h3>
                                        <p className='mb-0'>Simple, Transparent pricing that grows with you</p>
                                        <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                            <i className="las la-times" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <h3 className='subheading_ mb-2'>Based on your Store Profile setup</h3>
                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </>
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default ViewPlanModal