import React, { useEffect, useState } from 'react'
import { infoicon_white } from '../../../utilities/images'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { toast } from 'react-toastify'

const ProductvarientModal = (props) => {
  console.log(props,"props");
  const [optionList, setOptionList] = useState([{ option_name: "", display_name: "", options: [] }]);
  console.log(optionList,"optionList");
  const handleRemoveClick = index => {
    let list = [...optionList];
    list.splice(index, 1);
    setOptionList(list);
  };




  console.log(optionList, "listttttttt");

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let list = [...optionList];
    list[index][name] = value;
    setOptionList(list);
    // props.customPriceBtob(list);
  };

  const handleTagsChange = (data, i) => {
    // let list = [...optionList];
    // list[i]["options"] = data;
    // setOptionList(list);

    setOptionList((prevOptionList) => {
      let newOptionList = [...prevOptionList];
      newOptionList[i]["options"] = data;
      return newOptionList;
    });
  }

  const handleAddClick = () => {
    setOptionList([...optionList, { option_name: "", display_name: "", options: [] }]);
  };


  console.log(optionList, "option listttttttttttttttt");

  const isValid = optionList.every(item => {
    if (!item.option_name.trim() || !item.display_name.trim() || item.options.length === 0) {
        return false;
    }

    return true;
});

  const handleSubmit = () => {
    if(!isValid){
      toast.error("All fields are required to fill.")
      return false
    }
      let optionListNew = optionList
      if(props?.isEdit){
        let data = props?.optionListData
        let findIndex = data.findIndex(val => val?.id === optionList[0]?.id)
        data[findIndex] = optionList[0]
        optionListNew = data
        console.log(findIndex,"ddddsaaaa");
      }

      console.log(optionListNew,"ddddsaaaa");
      function generateTagPairs(optionIndex, currentPair, result) {
        if (optionIndex === optionListNew.length) {
          result.push(currentPair);
          return;
        }

        optionListNew[optionIndex]?.options?.forEach(tag => {
          generateTagPairs(
            optionIndex + 1,
            currentPair.length === 0 ? tag : `${currentPair}/${tag}`,
            result
          );
        });
      }

      const tagPairs1 = [];
      generateTagPairs(0, '', tagPairs1);
      const generateOptionCombinations = (data) => {
        if (data.length === 0) return [[]];

        const [first, ...rest] = data;
        const restCombinations = generateOptionCombinations(rest);

        const combinations = [];

        for (const option of first.options) {
          for (const combination of restCombinations) {
            combinations.push([{ "display_name": first.display_name, "value": option }, ...combination]);
          }
        }

        return combinations;
      };

      const tagPairs = generateOptionCombinations(optionListNew);

      const convertedArray = tagPairs.map((item, index) => {
        return {
          options: item,
          variant_name: tagPairs1[index],
          image: "",
          prices: [],
          upc: "",
          weight: "",
          unit: "",
          cost_price: props?.costPrice,
          is_available_for_sale: false,
          stocks: []
        };
      });

      console.log(tagPairs, "pairsssssssssssssssssssssss");
      props?.setVariantPairs(convertedArray)
      props?.setOptionList(optionListNew)
      console.log(tagPairs, "pairssssssssssssss");
      props?.handleModalChange("CreatevariationModal")
    
  }

  console.log(optionList,"dssssssssssssssssssssssssss");

  useEffect(() => {
    if (props?.isEdit) {
      setOptionList([props?.optionList])
    }
    else {
      setOptionList(props?.optionList?.length > 0 ? props?.optionList : [{ option_name: "", display_name: "", options: [] }])
    }
  }, [])

  console.log(optionList, "list of options");

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='leftheadvarient mt-3'>
          {/* <span className="crossIcon me-3" onClick={() => props?.close()}>
          <i className="las la-times" aria-hidden="true"></i>
        </span> */}
          <h3 className='subheading_ mb-2'>{props?.isEdit ? "Edit" : "Add"} Options</h3>
        </div>
        <div className='rightheadvarient'>
          <button className='discard_button' type='button' onClick={() => props?.close()}>Discard</button>
          <button className='next_button saveactive' type='button'
            onClick={() => handleSubmit()}
          >{"Next"}</button>
        </div>
      </div>


      <div className='productvarientModal_'>
        <p className='headerHeading_'>Add a custom set of options to an item to create variations. For example, a size option set can create variations small, medium, and large.</p>

        {
          optionList?.map((val, index) => {
            console.log(val,"testissue");
            return (
              <>
                <div className='varientOptions_'>
                  {index ?
                    <span className='removeSelectVariant' onClick={() => handleRemoveClick(index)} ><i className="fas fa-times"></i></span>
                    : ""}
                  <div className='optionset_'>
                    <span className='label-text'>Option Set Name <img className='infoicon' src={infoicon_white} /></span>
                    <input type='text' name="option_name" value={val?.option_name} onChange={e => handleInputChange(e, index)} placeholder='Option set name' className='custom-input' />
                  </div>
                  <div className='optionset_ mt-2'>
                    <span className='label-text'>Display Name <img className='infoicon' src={infoicon_white} /></span>
                    <input type='text' name="display_name" value={val?.display_name} onChange={e => handleInputChange(e, index)} placeholder='Display Name' className='custom-input' />
                  </div>

                  <div className='optionName_'>
                    <span className='label-text'>{val?.display_name ? `${val?.display_name} Options` : "Options Name"}</span>
                    {/* <input type='text' placeholder='Blue, Black, etc' className='custom-input' /> */}
                    <TagsInput
                      name="options"
                      value={val?.options}
                      onChange={e => handleTagsChange(e, index)} />
                  </div>
                  <p className='writetext_ mt-4'>Write option name and use <strong className='enterbox_'> Enter </strong></p>
                </div>
              </>
            )
          })
        }

        {<div className='addoptionmain_ mt-4'>
          <button className='addOptions ' onClick={handleAddClick} >
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" className='me-2' xmlns="http://www.w3.org/2000/svg">
              <path d="M9.25021 0C9.43145 7.6429e-06 9.60655 0.0656426 9.74314 0.184767C9.87973 0.303891 9.96856 0.468446 9.99321 0.648L10.0002 0.75L10.0012 8H17.2542C17.4442 8.00006 17.6272 8.07224 17.766 8.20197C17.9049 8.3317 17.9893 8.5093 18.0022 8.69888C18.0152 8.88846 17.9557 9.07589 17.8358 9.2233C17.7159 9.37071 17.5445 9.4671 17.3562 9.493L17.2542 9.5H10.0012L10.0032 16.75C10.0029 16.9399 9.93054 17.1227 9.80073 17.2613C9.67092 17.4 9.49334 17.4842 9.30383 17.4971C9.11433 17.5099 8.92702 17.4503 8.77972 17.3304C8.63242 17.2105 8.5361 17.0392 8.51021 16.851L8.50321 16.749L8.50121 9.5H1.25221C1.06219 9.49994 0.879272 9.42776 0.740421 9.29803C0.60157 9.1683 0.517139 8.9907 0.504187 8.80112C0.491235 8.61154 0.550728 8.42411 0.670645 8.2767C0.790563 8.12929 0.961963 8.0329 1.15021 8.007L1.25221 8H8.50221L8.50021 0.75C8.50021 0.551088 8.57923 0.360322 8.71988 0.21967C8.86054 0.0790175 9.0513 0 9.25021 0Z" fill="#275AFF" />
            </svg>
            Add Options
          </button>
        </div>}
      </div>
    </>

  )
}

export default ProductvarientModal