import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAddressList } from '../auth/authSlice'

const VariantmanageStocks = (props) => {

    const [manageStock, setStockManaged] = useState([])
    const addressList = useSelector(selectAddressList)

    console.log(addressList, "addressListaddressList");
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...manageStock];
        list[index][name] = value;
        setStockManaged(list)
    };

    const handleCheckedChange = (value, index) => {
        const list = [...manageStock];
        list[index].is_available_for_sale = value;
        setStockManaged(list)
    }

    const handleSave = () => {
        let result = props?.variantOptionList
        result[props?.selectedVariantIndex].stocks = manageStock
        props?.setVariantOptionList(result)
        props?.close()
    }

    console.log(addressList, props?.selectedVariant, "managed dtaaaaaaaaaaaaaaaa");

    useEffect(() => {
        if (addressList?.payload && (props?.selectedVariant?.stocks?.length <= 0 || props?.selectedVariant?.stocks == undefined)) {
            const transformedData = addressList?.payload?.map(item => ({
                seller_address_id: item?.id,
                stock_action: "stock_received",
                qty: "",
                total_stock: "",
                is_available_for_sale: false
            }));
            setStockManaged(transformedData)
        }
        else {
            setStockManaged(props?.selectedVariant?.stocks)
        }
    }, [])

    console.log(props?.selectedVariant?.stocks, "sssssssssssssssssssssssss");
    return (
        <div className='variantmanageStock_'>
            <div className='variantavailability_'>
                <h4 className='stockHead_'>Locations and stock</h4>
                {manageStock?.length > 0 &&
                    manageStock?.map((val, index) => {
                        return (
                            <div className='varientstochmange_ mb-3' key={index}>
                                <div className='availableVarient'>
                                    <div className="form-check form-switch">
                                        <input type="checkbox"
                                            checked={val?.is_available_for_sale}
                                            onChange={(e) => handleCheckedChange(!val?.is_available_for_sale, index)} />
                                    </div>
                                    <h4 className='textinner_'>Available for {addressList?.payload?.find(data => data?.id == val?.seller_address_id)?.format_address}</h4>
                                </div>
                                <div className='variantInfo '>
                                    <div className='varientInfoSub'>
                                        <h4 className='varientName'>Stock Action</h4>
                                        <select name="stock_action" id="cars" className='stockSelect' value={val?.stock_action} onChange={(e) => handleInputChange(e, index)}>
                                            <option value="stock_received">Stock received</option>
                                            <option value="inventory_recount">Inventory re-count</option>
                                            <option value="damage">Damage</option>
                                            <option value="theft">Theft</option>
                                            <option value="loss">Loss</option>
                                            <option value="restock_return">Restock return</option>
                                        </select>
                                    </div>
                                    <div className='varientInfoSub stockSectionData'>
                                        <h4 className='varientName'>{
                                            val?.stock_action == "stock_received" ? "Stock Received" :
                                                val?.stock_action == "inventory_recount" ? "Inventory Recount" :
                                                    val?.stock_action == "damage" ? "Damage" :
                                                        val?.stock_action == "theft" ? "Theft" :
                                                            val?.stock_action == "loss" ? "Loss" :
                                                                val?.stock_action == "restock_return" ? "Restock Return" :
                                                                    "-"}</h4>
                                        <input type="text" className='varientdata' name="qty" value={val?.qty} placeholder='qty' onChange={(e) => handleInputChange(e, index)} />
                                    </div>
                                    <div className='varientInfoSub stockSectionTotal'>
                                        <h4 className='varientName'>Total Stock</h4>
                                        <input type="text" name="total_stock" className='varientdata' placeholder='total stock' value={val?.total_stock} onChange={(e) => handleInputChange(e, index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className=' modalfooterBtn'>
                <button className='me-4 w-100 btnDiscard' onClick={() => props?.close()}>Discard</button>
                <button className='w-100 btnSave' type="button" onClick={() => handleSave()}>
                    Save
                </button>
            </div>
        </div>
    )
}

export default VariantmanageStocks